import { IDAO } from 'api/communitiesApi';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    borderRadius: '64px',
    padding: '16px 16px 16px 66px',
    marginBottom: '16px',
    color: '#fff',
    fontSize: '16px',
  },
  imageContainer: {
    position: 'absolute',
    left: -1,
    top: 0,
    height: '49px',
    width: '49px',
    borderRadius: '48px',
    border: '4px solid #1B212D',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default function DAOStreak({ dao }: { dao?: IDAO }): JSX.Element {
  const styles = useStyles();
  return (
    <div
      className={styles.container}
      style={{
        background: `linear-gradient(90deg, ${
          dao?.brandColor || '#0D182D'
        } -9.69%, #1B212D 116.03%)`,
      }}
    >
      {dao?.name}
      <div
        className={styles.imageContainer}
        style={{ background: dao?.brandColor || '#0D182D' }}
      >
        <img src={dao?.logo || ''} alt="dao" style={{ width: '80%' }}></img>
      </div>
    </div>
  );
}
