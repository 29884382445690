import discordIcon from 'assets/discord.svg';
import editIcon from 'assets/edit.svg';
import notion from 'assets/notion.svg';
import radio from 'assets/radio.svg';
import radioActive from 'assets/radioActive.svg';
import MainLayout from 'layouts/MainLayout';
import { useState } from 'react';
import { createUseStyles } from 'react-jss';
import useGlobalState from 'state';

const useStyles = createUseStyles({
  container: { display: 'flex', alignItems: 'flex-start' },
  left: { width: '168px', marginRight: '67px', flexShrink: 0 },
  right: { flexShrink: 0, width: '100%', maxWidth: '544px' },
  imageContainer: { position: 'relative', cursor: 'pointer' },
  image: {
    width: '120px',
    height: '120px',
    borderRadius: '120px',
  },
  edit: { position: 'absolute', bottom: 0, right: '32px' },
  title: {
    fontSize: '20px',
    lineHeight: '20px',
    color: '#F9F9F9',
  },
  inputTitle: {
    fontSize: '14px',
    color: '#F9F9F9',
    lineHeight: '14px',
    marginBottom: '8px',
  },
  input: {
    width: '100%',
    borderRadius: '8px',
    background: '#202837',
    border: '1px solid #303B5B',
    padding: '18px 16px',
    color: '#F9F9F9',
    fontSize: '16px',
    lineHeight: '16px',
  },
  divider: {
    width: '100%',
    height: '1px',
    backgroundColor: '#303B5B',
    marginTop: '40px',
    marginBottom: '40px',
    maxWidth: '777px',
  },
  card: {
    width: '100%',
    backgroundColor: '#202837',
    padding: '24px',
    borderRadius: '8px',
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  appLogoContainer: { display: 'flex', alignItems: 'center' },
  appLogo: {
    width: '40px',
    height: '40px',
    marginRight: '8px',
    filter: 'brightness(100%)',
  },
  appName: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '16px',
    color: '#FFFFFF',
  },
  cardStatus: {
    backgroundColor: '#2B8C34',
    borderRadius: '4px',
    padding: '6px 10px',
    fontSize: '14px',
    lineHeight: '14px',
    color: '#F9F9F9',
  },
  cardDetailsContainer: { marginTop: '16px' },
  cardDetails: { color: '#A4A6AB', fontSize: '14px', lineHeight: '18.9px' },
  cardDivider: {
    margin: '16px 0',
    width: '100%',
    height: '1px',
    backgroundColor: '#303B5B',
  },
  cardCTAContainer: { display: 'flex', justifyContent: 'flex-end' },
  cardCTA: {
    padding: '10px',
    cursor: 'pointer',
    border: '0.5px solid #F9F9F9',
    borderRadius: '100px',
    color: '#F9F9F9',
    fontSize: '12px',
    lineHeight: '12px',
  },
  visibilityTitle: { fontSize: '16px', marginBottom: '8px', color: '#F9F9F9' },
  visibilityDescription: { color: '#A4A6AB', fontSize: '14px' },
  visibilityContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    cursor: 'pointer',
  },
  buttonsContainer: {
    marginTop: '40px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    border: '0.5px solid #F9F9F9',
    borderRadius: '100px',
    padding: '18px 24px',
    cursor: 'pointer',
    color: '#F9F9F9',
    fontSize: '16px',
    lineHeight: '16px',
  },
});

export default function SettingsView(): JSX.Element {
  const styles = useStyles();
  const { loggedInUser } = useGlobalState();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [link, setLink] = useState('');
  const [discord, setDiscord] = useState('');
  const [twitter, setTwitter] = useState('');
  const [reddit, setReddit] = useState('');
  const [visibilityPublic, setVisibilityPublic] = useState(false);

  return (
    <MainLayout title="Settings">
      {!loggedInUser ? (
        <div style={{ color: '#fff' }}>
          Connect your wallet to view your settings.
        </div>
      ) : (
        <div>
          <div className={styles.container}>
            <div className={styles.left}>
              <div className={styles.imageContainer}>
                <img
                  className={styles.image}
                  src={loggedInUser.dao.logo}
                  alt="dao"
                />
                <img src={editIcon} alt="edit" className={styles.edit} />
              </div>
            </div>
            <div className={styles.right}></div>
          </div>
          <div className={styles.container} style={{ marginTop: '56px' }}>
            <div className={styles.left}>
              <div className={styles.title}>General info</div>
            </div>
            <div className={styles.right}>
              <div className={styles.inputTitle}>Community Name</div>
              <input
                className={styles.input}
                value={name}
                onChange={e => setName(e.target.value)}
              />
              <div className={styles.inputTitle} style={{ marginTop: '24px' }}>
                Short Description
              </div>
              <textarea
                rows={5}
                className={styles.input}
                value={description}
                onChange={e => setDescription(e.target.value)}
              />
              <div className={styles.inputTitle} style={{ marginTop: '24px' }}>
                Link to Walkthrough
              </div>
              <input
                className={styles.input}
                value={link}
                onChange={e => setLink(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.divider} />
          <div className={styles.container}>
            <div className={styles.left}>
              <div className={styles.title}>Social links</div>
            </div>
            <div className={styles.right}>
              <div className={styles.inputTitle}>Discord</div>
              <input
                className={styles.input}
                value={discord}
                onChange={e => setDiscord(e.target.value)}
              />
              <div className={styles.inputTitle} style={{ marginTop: '24px' }}>
                Twitter
              </div>
              <input
                className={styles.input}
                value={twitter}
                onChange={e => setTwitter(e.target.value)}
              />
              <div className={styles.inputTitle} style={{ marginTop: '24px' }}>
                Reddit
              </div>
              <input
                className={styles.input}
                value={reddit}
                onChange={e => setReddit(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.divider} />
          <div className={styles.container}>
            <div className={styles.left}>
              <div className={styles.title}>Connected Apps</div>
            </div>
            <div className={styles.right}>
              <div className={styles.card}>
                <div className={styles.cardHeader}>
                  <div className={styles.appLogoContainer}>
                    <img src={notion} alt="notion" className={styles.appLogo} />
                    <div className={styles.appName}>Notion</div>
                  </div>
                  <div className={styles.cardStatus}>Connected</div>
                </div>
                <div className={styles.cardDetailsContainer}>
                  <div className={styles.cardDetails}>
                    Notion is an all-in-one workspace for note-taking and
                    project management
                    <div style={{ marginTop: '16px', fontSize: '12px' }}>
                      Last Sync: <span style={{ color: '#fff' }}>N/A</span>
                    </div>
                  </div>
                </div>
                <div className={styles.cardDivider} />
                <div className={styles.cardCTAContainer}>
                  <div className={styles.cardCTA}>Sync with Notion</div>
                  <div
                    className={styles.cardCTA}
                    style={{
                      marginLeft: '24px',
                      backgroundColor: '#F9F9F9',
                      color: '#1B212D',
                    }}
                  >
                    Open Notion
                  </div>
                </div>
              </div>
              <div className={styles.card} style={{ marginTop: '24px' }}>
                <div className={styles.cardHeader}>
                  <div className={styles.appLogoContainer}>
                    <img
                      src={discordIcon}
                      alt="discord"
                      className={styles.appLogo}
                    />
                    <div className={styles.appName}>Discord</div>
                  </div>
                  <div className={styles.cardStatus}>Connected</div>
                </div>
                <div className={styles.cardDetailsContainer}>
                  <div className={styles.cardDetails}>
                    Discord is a free voice, video, and text chat app that lets
                    you manage and communicate with your community
                    <div style={{ marginTop: '16px', fontSize: '12px' }}>
                      Connected on: <span style={{ color: '#fff' }}>N/A</span>
                    </div>
                  </div>
                </div>
                <div className={styles.cardDivider} />
                <div className={styles.cardCTAContainer}>
                  <div
                    className={styles.cardCTA}
                    style={{
                      marginLeft: '24px',
                      backgroundColor: '#F9F9F9',
                      color: '#1B212D',
                    }}
                  >
                    Open Discord
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.divider} />
          <div className={styles.container}>
            <div className={styles.left}>
              <div className={styles.title}>Visibility</div>
            </div>
            <div className={styles.right}>
              <div
                className={styles.visibilityContainer}
                onClick={() => setVisibilityPublic(true)}
              >
                <img
                  src={visibilityPublic ? radioActive : radio}
                  alt="radio"
                  style={{ marginRight: '16px' }}
                />
                <div>
                  <div className={styles.visibilityTitle}>Public mode</div>
                  <div className={styles.visibilityDescription}>
                    Anyone can join through Share
                  </div>
                </div>
              </div>
              <div
                style={{ marginTop: '24px' }}
                className={styles.visibilityContainer}
                onClick={() => setVisibilityPublic(false)}
              >
                <img
                  src={!visibilityPublic ? radioActive : radio}
                  alt="radio"
                  style={{ marginRight: '16px' }}
                />
                <div>
                  <div className={styles.visibilityTitle}>Private mode</div>
                  <div className={styles.visibilityDescription}>
                    Only people with an invite link can join
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.container}>
            <div className={styles.left}></div>
            <div className={styles.right}>
              <div className={styles.buttonsContainer}>
                <div className={styles.button}>Cancel</div>
                <div
                  className={styles.button}
                  style={{
                    marginLeft: '16px',
                    color: '#1B212D',
                    backgroundColor: '#F9F9F9',
                  }}
                >
                  Save
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </MainLayout>
  );
}
