import { IUserData } from 'api/usersApi';
//import bell from 'assets/bell.svg';
import contributorsIcon from 'assets/contributors.svg';
import contributorsFilledIcon from 'assets/contributorsFilled.svg';
import copyIcon from 'assets/copy.svg';
import dashboardIcon from 'assets/dashboard.svg';
import dashboardFilledIcon from 'assets/dashboardFilled.svg';
import discoverIcon from 'assets/discover.svg';
import discoverFilledIcon from 'assets/discoverFilled.svg';
import loadingIcon from 'assets/loading.svg';
import logo from 'assets/logo.svg';
import logoutIcon from 'assets/logoutGray.svg';
import manageIcon from 'assets/manage.svg';
import manageFilledIcon from 'assets/manageFilled.svg';
import opportunitiesIcon from 'assets/opportunities.svg';
import opportunitiesFilledIcon from 'assets/opportunitiesFilled.svg';
import profileIcon from 'assets/profileGray.svg';
import settingsIcon from 'assets/settings.svg';
import settingsFilledIcon from 'assets/settingsFilled.svg';
import tasksIcon from 'assets/tasks.svg';
import tasksFilledIcon from 'assets/tasksFilled.svg';
import PreOnboardingModal from 'components/PreOnboardingModal';
import { useWallet } from 'contexts/WalletContext';
import makeBlockie from 'ethereum-blockies-base64';
import {
  CommunityLocation,
  ContributorsLocation,
  DAOOpportunitiesLocation,
  DashboardLocation,
  DiscoverLocation,
  ManageLocation,
  OpportunitiesLocation,
  ProfileLocation,
  SettingsLocation,
  TasksLocation,
} from 'Locations';
import { ReactNode, useRef, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import { createUseStyles } from 'react-jss';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import useGlobalState from 'state';
import useOutsideAlerter, { formatAddress } from 'utils/utils';

export const isDAO = (user: IUserData): boolean => {
  return user?.roles?.find((role: string) => role === 'DAOAdmin') !== undefined;
};

const useStyles = createUseStyles({
  container: {
    minHeight: '100vh',
    backgroundColor: '#1B212D',
  },
  header: {
    display: 'flex',
    padding: '16px 40px 0px 40px',
    position: 'fixed',
    width: '100vw',
    backgroundColor: '#1B212D',
    zIndex: 1,
  },
  logo: {
    paddingBottom: '16px',
    borderBottom: '0.5px solid #fff',
    marginRight: '40px',
    width: '193px',
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    cursor: 'pointer',
  },
  title: {
    paddingBottom: '16px',
    borderBottom: '0.5px solid #fff',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  connect: {
    cursor: 'pointer',
    borderRadius: '100px',
    border: '1px solid #474E5A',
    padding: '8px 11px',
    color: '#fff',
    marginLeft: '24px',
    minHeight: '48px',
    minWidth: '156px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    position: 'relative',
  },
  profilePic: {
    borderRadius: '100%',
    marginRight: '14px',
    width: '32px',
    height: '32px',
  },
  wrapper: {
    display: 'flex',
    width: '100vw',
    padding: '120px 40px 64px 40px',
  },
  sidebar: {
    position: 'fixed',
    left: '40px',
    top: '120px',
    flexShrink: 0,
    width: '194px',
  },
  contentContainer: {
    width: '100%',
    marginLeft: '234px',
  },
  sidebarItem: {
    cursor: 'pointer',
    fontSize: '16px',
    lineHeight: '16px',
    color: '#A4A6AB',
    marginBottom: '40px',
    display: 'flex',
    alignItems: 'center',
    userSelect: 'none',
  },
  dropdown: {
    position: 'absolute',
    top: '60px',
    right: 0,
    borderRadius: '8px',
    backgroundColor: '#202837',
    border: '1px solid #303B5B',
    width: '219px',
  },
  dropdownItem: {
    padding: '16px',
    display: 'flex',
    alignItems: 'center',
    color: '#BBBCC0',
    fontSize: '16px',
    '&:hover': { color: '#F9F9F9' },
  },
  sidebarIcon: { width: '24px', height: '24px', marginRight: '18px' },
  loading: { animation: '$rotate 1.5s infinite' },
  divider: {
    backgroundColor: '#303B5B',
    height: '1px',
    width: '100%',
    margin: '8px 0px',
  },
  dropdownIcon: { marginRight: '8px', width: '20px' },
  '@keyframes rotate': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
});

export default function MainLayout({
  children,
  title,
  loading,
}: {
  children: ReactNode;
  title?: string;
  loading?: boolean;
}): JSX.Element {
  const styles = useStyles();
  const { address, isConnected, isConnecting, disconnect, connectWallet } =
    useWallet();
  const { loggedInUser } = useGlobalState();
  const loc = useLocation();
  const [dropdown, setDropdown] = useState(false);
  const dropdownRef = useRef(null);

  useOutsideAlerter(dropdownRef, () => setDropdown(false));

  const DAOuser = isDAO(loggedInUser);

  const sidebarItems = [
    {
      name: 'Dashboard',
      location: DashboardLocation,
      icon: dashboardIcon,
      iconActive: dashboardFilledIcon,
    },
    DAOuser
      ? {
          name: 'Manage',
          location: ManageLocation,
          icon: manageIcon,
          iconActive: manageFilledIcon,
        }
      : {
          name: 'Opportunities',
          location: isDAO(loggedInUser)
            ? DAOOpportunitiesLocation
            : OpportunitiesLocation,
          icon: opportunitiesIcon,
          iconActive: opportunitiesFilledIcon,
        },
    DAOuser
      ? {
          name: 'Contributors',
          location: ContributorsLocation,
          icon: contributorsIcon,
          iconActive: contributorsFilledIcon,
        }
      : {
          name: 'My tasks',
          location: TasksLocation,
          icon: tasksIcon,
          iconActive: tasksFilledIcon,
        },
  ];

  const secondarySidebarItems = [
    DAOuser
      ? {
          name: 'Settings',
          location: SettingsLocation,
          icon: settingsIcon,
          iconActive: settingsFilledIcon,
        }
      : {
          name: 'Discover',
          location: DiscoverLocation,
          icon: discoverIcon,
          iconActive: discoverFilledIcon,
        },
  ];

  return (
    <div className={styles.container}>
      <PreOnboardingModal
        open={loggedInUser && !loggedInUser.username}
        onClose={() => null}
      />
      <div className={styles.header}>
        <Link to={DashboardLocation} className={styles.logo}>
          <img src={logo} alt="share" />
        </Link>
        <div className={styles.title}>
          <div style={{ color: 'white', fontSize: '32px', lineHeight: '32px' }}>
            {title || ''}
          </div>
          <div
            style={{
              marginLeft: '40px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {/* <div style={{ cursor: 'pointer' }}>
              <img src={bell} alt="notfications" />
          </div>*/}
            <div
              className={styles.connect}
              onClick={() =>
                isConnected
                  ? setDropdown(true)
                  : !isConnected && connectWallet()
              }
            >
              {isConnected && dropdown && (
                <div className={styles.dropdown} ref={dropdownRef}>
                  <Link
                    to={ProfileLocation(address || '')}
                    className={styles.dropdownItem}
                    style={{ marginTop: '8px' }}
                  >
                    <img
                      src={profileIcon}
                      alt="profile"
                      className={styles.dropdownIcon}
                    />
                    Profile
                  </Link>
                  <CopyToClipboard
                    text={address || ''}
                    onCopy={() => toast('Address copied to clipboard')}
                  >
                    <div className={styles.dropdownItem}>
                      <img
                        src={copyIcon}
                        alt="copy"
                        className={styles.dropdownIcon}
                        style={{
                          filter:
                            'invert(94%) sepia(4%) saturate(225%) hue-rotate(191deg) brightness(83%) contrast(83%)',
                        }}
                      />
                      Copy Address
                    </div>
                  </CopyToClipboard>
                  <div className={styles.divider} />
                  <div
                    className={styles.dropdownItem}
                    onClick={() => {
                      setDropdown(false);
                      disconnect();
                    }}
                    style={{ marginBottom: '8px' }}
                  >
                    <img
                      src={logoutIcon}
                      alt="logout"
                      className={styles.dropdownIcon}
                    />
                    Log Out
                  </div>
                </div>
              )}
              {isConnected && (
                <img
                  src={makeBlockie(address || '')}
                  alt="blockie"
                  className={styles.profilePic}
                />
              )}
              {isConnecting
                ? 'Connecting'
                : !isConnected
                ? 'Connect'
                : loggedInUser?.username || formatAddress(address, null)}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.sidebar}>
          {!isConnecting && (
            <div>
              {sidebarItems.map((item, i) => (
                <Link to={item.location} key={`${item.name}-${i}`}>
                  <div
                    style={{
                      color:
                        loc.pathname.search('/opportunities/') !== -1 &&
                        item.location === OpportunitiesLocation
                          ? '#fff'
                          : loc.pathname === item.location
                          ? '#fff'
                          : '#A4A6AB',
                    }}
                    className={styles.sidebarItem}
                  >
                    <div className={styles.sidebarIcon}>
                      <img
                        src={
                          loc.pathname.search('/opportunities/') !== -1 &&
                          item.location === OpportunitiesLocation
                            ? item.iconActive
                            : loc.pathname === item.location
                            ? item.iconActive
                            : item.icon
                        }
                        alt="icon"
                        style={{
                          filter:
                            loc.pathname.search('/opportunities/') !== -1 &&
                            item.location === OpportunitiesLocation
                              ? 'brightness(0) invert(100%)'
                              : loc.pathname === item.location
                              ? 'brightness(0) invert(100%)'
                              : 'none',
                        }}
                      />
                    </div>
                    {item.name}
                  </div>
                </Link>
              ))}
              <div
                style={{
                  borderTop: '1px solid #303B5B',
                  height: '1px',
                  width: '100%',
                  marginBottom: '40px',
                }}
              />
              {secondarySidebarItems.map((item, i) => (
                <Link to={item.location} key={`${item.name}-${i}`}>
                  <div
                    style={{
                      color:
                        loc.pathname === item.location ? '#fff' : '#A4A6AB',
                    }}
                    className={styles.sidebarItem}
                  >
                    <div className={styles.sidebarIcon}>
                      <img
                        src={
                          loc.pathname === item.location
                            ? item.iconActive
                            : item.icon
                        }
                        alt="icon"
                        style={{
                          filter:
                            loc.pathname === item.location
                              ? 'brightness(0) invert(100%)'
                              : 'none',
                        }}
                      />
                    </div>
                    {item.name}
                  </div>
                </Link>
              ))}
              {loggedInUser?.dao?.id && (
                <Link
                  to={CommunityLocation(loggedInUser?.dao?.id)}
                  className={styles.sidebarItem}
                >
                  <img
                    className={styles.sidebarIcon}
                    src={loggedInUser?.dao?.logo}
                    alt={loggedInUser?.dao?.name}
                  />
                  <div style={{ filter: 'brightness(0) invert(80%)' }}>
                    {loggedInUser?.dao?.name}
                  </div>
                </Link>
              )}
            </div>
          )}
        </div>
        <div className={styles.contentContainer}>
          {loading || isConnecting ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '64px',
              }}
            >
              <img src={loadingIcon} alt="loading" className={styles.loading} />
            </div>
          ) : (
            children
          )}
        </div>
      </div>
    </div>
  );
}
