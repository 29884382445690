import { updateUser } from 'api/usersApi';
import onboardingBackground2 from 'assets/onboardingBackground2.png';
import shareLogo from 'assets/onboardingLogo.png';
import signUpIllustration from 'assets/signUpIllustration.png';
import OnboardingAvailability from 'components/Onboarding/OnboardingAvailability';
import OnboardingCommunicationPreference from 'components/Onboarding/OnboardingCommunicationPreference';
import OnboardingConnectWallet from 'components/Onboarding/OnboardingConnectWallet';
import OnboardingPersonalInformation from 'components/Onboarding/OnboardingPersonalInformation';
import OnboardingSkills from 'components/Onboarding/OnboardingSkills';
import OnboardingSocialLinks from 'components/Onboarding/OnboardingSocialLinks';
import { useWallet } from 'contexts/WalletContext';
import { DashboardLocation } from 'Locations';
import { useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Link, useLocation } from 'react-router-dom';
import useGlobalState from 'state';
import { validateEmail } from 'utils/utils';

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const useStyles = createUseStyles({
  container: { display: 'flex', height: '100vh' },
  illustration: {
    width: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: '100%',
  },
  illustrationContent: { padding: '60px 48px' },
  content: {
    width: '50vw',
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  logo: { width: '130px' },
  illustrationStepContainer: { marginTop: '180px' },
  stepSubtitle: {
    fontSize: '16px',
    color: '#f9f9f9',
    letterSpacing: '-0.01em',
  },
  stepTitle: {
    maxWidth: '300px',
    fontSize: '60px',
    color: '#f9f9f9',
    fontFamily: 'eiko',
    fontWeight: 100,
    marginTop: '24px',
  },
  connectedContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 60px',
  },
  connectedDivider: {
    width: '100%',
    height: '1px',
    backgroundColor: '#303B5B',
  },
  connectedWallet: {
    marginLeft: '24px',
    flexShrink: 0,
    padding: '6px 10px',
    fontSize: '14px',
    color: '#f9f9f9',
    borderRadius: '4px',
    backgroundColor: '#2B8C34',
  },
  header: { marginTop: '63px', marginBottom: '8px', minHeight: '26px' },
  footerDivider: {
    width: '100%',
    position: 'relative',
    height: '1px',
    backgroundColor: 'rgba(249,249,249,0.3)',
  },
  footerCTAContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 60px 20px 60px',
  },
  footerProgress: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '2px',
    backgroundColor: '#f9f9f9',
    transition: 'all 0.4s ease-in-out',
  },
  footerCTA: {
    borderRadius: '100px',
    backgroundColor: '#f9f9f9',
    padding: '18px 24px',
    cursor: 'pointer',
    fontSize: '16px',
    color: '#1B212D',
    userSelect: 'none',
    border: 'none',
    '&:disabled': { opacity: 0.3, cursor: 'not-allowed' },
  },
  childrenContainer: {
    padding: '0px 60px',
    color: '#f9f9f9',
    maxHeight: '100%',
    overflowY: 'scroll',
  },
  back: { cursor: 'pointer', fontSize: '16px', color: '#f9f9f9' },
  finalContainer: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  center: { display: 'flex', justifyContent: 'center' },
  daoImage: { width: '92px', height: '92px', borderRadius: '92px' },
  finalTitle: {
    fontFamily: 'eiko',
    fontWeight: 100,
    fontSize: '70px',
    marginTop: '32px',
    color: '#f9f9f9',
  },
  finalSubtitle: {
    fontSize: '20px',
    marginTop: '32px',
    color: '#f9f9f9',
    lineHeight: '135%',
  },
  finalCTA: {
    marginTop: '64px',
    borderRadius: '100px',
    padding: '18px 24px',
    cursor: 'pointer',
    backgroundColor: '#f9f9f9',
    color: '#1B212D',
  },
});

export interface Skill {
  kind: string;
  experience: string;
}

export interface Tag {
  id: string;
  text: string;
}

export interface Availability {
  label: string;
  value: string;
}

export interface DaoOption {
  label: string;
  value: string;
}

export default function SignUpView(): JSX.Element {
  const styles = useStyles();
  const { loggedInUser, setLoggedInUser } = useGlobalState();
  const [selectedStep, setSelectedStep] = useState(0);
  const [discordAuthed, setDiscordAuthed] = useState<boolean>(false);
  const [discord, setDiscord] = useState<null | string>(null);
  const [twitter, setTwitter] = useState<null | string>(null);
  const [github, setGithub] = useState<null | string>(null);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [bio, setBio] = useState('');
  const [skills, setSkills] = useState<Skill[]>([
    { kind: 'Development', experience: 'Mid' },
  ]);
  const [tags, setTags] = useState<Tag[]>([]);
  const [availability, setAvailability] = useState<Availability | null>(null);
  const [daoOption, setDaoOption] = useState<DaoOption | null>(null);
  const [loading, setLoading] = useState(false);

  const { isConnected } = useWallet();

  const query = useQuery();

  const steps = [
    {
      name: 'Connect Wallet',
      component: <OnboardingConnectWallet daoId={query?.get('dao') || null} />,
    },
    {
      name: 'Communication Preference',
      component: (
        <OnboardingCommunicationPreference
          discord={discord}
          setDiscord={val => {
            setDiscordAuthed(true);
            setDiscord(val);
          }}
          email={email}
          setEmail={setEmail}
        />
      ),
    },
    {
      name: 'Personal Information',
      component: (
        <OnboardingPersonalInformation
          name={name}
          setName={setName}
          bio={bio}
          setBio={setBio}
        />
      ),
    },
    {
      name: 'Social Presence',
      component: (
        <OnboardingSocialLinks
          discord={discord || ''}
          setDiscord={setDiscord}
          discordAuthed={discordAuthed}
          twitter={twitter || ''}
          setTwitter={setTwitter}
          github={github || ''}
          setGithub={setGithub}
        />
      ),
    },
    {
      name: 'Work Experience',
      component: (
        <OnboardingSkills
          tags={tags}
          skills={skills}
          setTags={setTags}
          setSkills={setSkills}
        />
      ),
    },
    {
      name: 'Work Capacity',
      component: (
        <OnboardingAvailability
          availability={availability}
          setAvailability={setAvailability}
          daoOption={daoOption}
          setDaoOption={setDaoOption}
        />
      ),
    },
  ];

  const isFinalStep = selectedStep === steps.length;

  const isValidEmail = validateEmail(email);

  const discordRegex = new RegExp('^.{3,32}#[0-9]{4}$');

  const validStep =
    (selectedStep === 0 && isConnected) ||
    (selectedStep === 1 && discord) ||
    (selectedStep === 1 && isValidEmail) ||
    (selectedStep === 2 && name && selectedStep === 2 && bio) ||
    (selectedStep === 3 && discord && discordRegex.test(discord)) ||
    (selectedStep === 4 && skills.length) ||
    (selectedStep === 5 && daoOption && availability);

  const onSubmit = async () => {
    if (loggedInUser) {
      setLoading(true);
      const data = {
        discord,
        email,
        username: name,
        bio,
        skills,
        tags: tags.map(tag => tag.text),
        availability: availability?.value,
        daoOption,
        twitter,
        github,
      };
      const user = await updateUser({ id: loggedInUser.id, data });
      const userData = await user.json();
      setLoggedInUser({ ...loggedInUser, ...userData });
      setSelectedStep(steps.length);
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <div
        style={{
          backgroundImage: `url(${
            !isFinalStep ? signUpIllustration : onboardingBackground2
          })`,
        }}
        className={styles.illustration}
      >
        {isFinalStep ? (
          <div className={styles.finalContainer}>
            <div>
              {/*<div className={styles.center}>
                <img src={dao?.logo} alt="avatar" className={styles.daoImage} />
        </div>*/}
              <div className={styles.finalTitle}>Congratulations!</div>
              <div className={styles.finalSubtitle}>
                You&apos;re now part of the Share community and <br />
                ready to start building
              </div>
              <div className={styles.center}>
                <Link to={DashboardLocation}>
                  <div className={styles.finalCTA}>Take me to my Dashboard</div>
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.illustrationContent}>
            <img src={shareLogo} alt="share" className={styles.logo} />
            <div className={styles.illustrationStepContainer}>
              <div className={styles.stepSubtitle}>
                Step {selectedStep + 1} of {steps.length}
              </div>
              <div className={styles.stepTitle}>{steps[selectedStep].name}</div>
            </div>
          </div>
        )}
      </div>
      {!isFinalStep && (
        <div className={styles.content}>
          <div className={styles.header}>
            {isConnected && (
              <div className={styles.connectedContainer}>
                <div className={styles.connectedDivider} />
                <div className={styles.connectedWallet}>Wallet Connected</div>
              </div>
            )}
          </div>
          <div className={styles.childrenContainer}>
            {steps[selectedStep].component}
          </div>
          <div>
            <div className={styles.footerDivider}>
              <div
                className={styles.footerProgress}
                style={{
                  width: `${((selectedStep + 1) / steps.length) * 100}%`,
                }}
              />
            </div>
            <div className={styles.footerCTAContainer}>
              <div>
                {selectedStep > 0 && (
                  <div
                    className={styles.back}
                    onClick={() => setSelectedStep(selectedStep - 1)}
                  >
                    Back
                  </div>
                )}
              </div>
              <button
                disabled={!validStep || loading}
                className={styles.footerCTA}
                onClick={() =>
                  selectedStep === steps.length - 1
                    ? onSubmit()
                    : setSelectedStep(selectedStep + 1)
                }
              >
                {loading
                  ? 'Submitting...'
                  : selectedStep === steps.length - 1
                  ? 'Submit'
                  : 'Next'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
