import Hotjar from '@hotjar/browser';
import { WalletProvider } from 'contexts/WalletContext';
import ReactGA from 'react-ga';
import { Toaster } from 'react-hot-toast';
import { Router } from 'react-router-dom';

import Routes from './Routes';
import history from './utils/history';

if (process.env.REACT_APP_GA) {
  ReactGA.initialize(process.env.REACT_APP_GA);
  ReactGA.pageview(window.location.pathname + window.location.search);
}

history.listen(location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

if (process.env.REACT_APP_HOTJAR_SITE_ID) {
  const hotjarVersion = 6;
  Hotjar.init(+process.env.REACT_APP_HOTJAR_SITE_ID, hotjarVersion);
}

function App(): JSX.Element {
  return (
    <WalletProvider>
      <Toaster
        toastOptions={{
          style: {
            fontSize: '14px',
            backgroundColor: '#303B5B',
            boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)',
            borderRadius: '8px',
            padding: '16px',
            color: '#f9f9f9',
          },
        }}
      />
      <Router history={history}>
        <Routes />
      </Router>
    </WalletProvider>
  );
}

export default App;
