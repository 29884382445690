import { History } from 'history';
import React, { useEffect } from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  useHistory,
} from 'react-router-dom';
import { useQuery } from 'utils/utils';
import CommunitiesView from 'views/Communities';
import CommunityView from 'views/Community';
import ContributorsView from 'views/Contributors';
import CreateOpportunityView from 'views/CreateOpportunity';
import DashboardView from 'views/Dashboard';
import DiscoverView from 'views/Discover';
import HomeView from 'views/Home';
import ManageView from 'views/Manage';
import OnboardingView from 'views/Onboarding';
import OpportunitiesView from 'views/Opportunities';
import OpportunityView from 'views/Opportunity';
import ProfileView from 'views/Profile';
import SettingsView from 'views/Settings';
import SignUpView from 'views/SignUp';
import SquadsView from 'views/Squads';
import TasksView from 'views/Tasks';

import {
  CommunitiesLocation,
  CommunityLocationTemplate,
  ContributorsLocation,
  CreateOpportunityLocation,
  DashboardLocation,
  DiscordAuthLocation,
  DiscoverLocation,
  ManageLocation,
  OnboardingLocationTemplate,
  OpportunitiesLocation,
  OpportunityLocationTemplate,
  ProfileLocationTemplate,
  RootLocation,
  SettingsLocation,
  SignUpLocation,
  SquadsLocation,
  TasksLocation,
} from './Locations';
import ScrollToTop from './utils/ScrollToTop';

interface CustomRouteProps {
  path: string;
  redirect?: string;
  withAuth?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.FC<RouteComponentProps<any>>;
  history: History;
}

const CustomRoute = (props: CustomRouteProps) => {
  const { path, redirect, withAuth, component, history, ...leftProps } = props;

  if (redirect) {
    return <Redirect from={path} to={redirect} {...leftProps} />;
  }

  //return <Route path={path} component={error.error ? ErrorView : component} />;
  return <Route path={path} component={component} />;
};

const DiscordCodePopupPropagator = () => {
  const query = useQuery();
  useEffect(() => {
    window.opener.postMessage({ discordCode: query.get('code') });
    window.close();
  });

  return null;
};

const routes = [
  {
    path: RootLocation,
    exact: true,
    component: HomeView,
  },
  {
    path: OnboardingLocationTemplate,
    component: OnboardingView,
  },
  { path: OpportunityLocationTemplate, component: OpportunityView },
  {
    path: OpportunitiesLocation,
    component: OpportunitiesView,
  },
  { path: CommunityLocationTemplate, component: CommunityView },
  { path: CommunitiesLocation, component: CommunitiesView },
  { path: DashboardLocation, component: DashboardView },
  { path: SquadsLocation, component: SquadsView },
  { path: ProfileLocationTemplate, component: ProfileView },
  { path: DiscordAuthLocation, component: DiscordCodePopupPropagator },
  { path: TasksLocation, component: TasksView },
  { path: ManageLocation, component: ManageView },
  { path: ContributorsLocation, component: ContributorsView },
  { path: SettingsLocation, component: SettingsView },
  { path: DiscoverLocation, component: DiscoverView },
  { path: SignUpLocation, component: SignUpView },
  { path: CreateOpportunityLocation, component: CreateOpportunityView },
];

export default function Routes(): JSX.Element {
  const history = useHistory();

  return (
    <>
      <ScrollToTop />
      <Switch>
        {routes.map(props => (
          <CustomRoute
            key={props.path || 'default'}
            {...props}
            history={history}
          />
        ))}
      </Switch>
    </>
  );
}
