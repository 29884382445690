import { useWallet } from 'contexts/WalletContext';
import { Spinner } from 'design-system';
import { ContractTransaction } from 'ethers';
import { createUseStyles } from 'react-jss';
import { EXPLORER_URLS } from 'web3/constants';

import Modal from './Modal';

const useStyles = createUseStyles({
  container: { maxWidth: '1034px', display: 'flex' },
  content: {
    padding: '48px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  close: { width: '24px', cursor: 'pointer' },
});

export default function TransactionModal({
  open,
  transaction,
}: {
  open: boolean;
  transaction: ContractTransaction | null;
}): JSX.Element {
  const styles = useStyles();
  const { chainId } = useWallet();

  return (
    <Modal open={open} onClose={() => null} style={{ borderRadius: '8px' }}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div>
            <div style={{ marginBottom: '24px' }}>Transaction pending...</div>
            <Spinner />
            {chainId && transaction && (
              <a
                href={`${EXPLORER_URLS[chainId]}/tx/${transaction.hash}`}
                style={{ marginTop: '24px', display: 'block' }}
                target="_blank"
                rel="noreferrer"
              >
                View your transaction status and details here
              </a>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}
