import { uploadFiles as apiUploadFiles } from 'api/uploadApi';

export type IPFSMetadata = {
  ipfsHash: string;
  ipfsUri: string;
};

export type IPFSFile = {
  path: string;
  size: number;
  ipfsUri: string;
};

export const uploadFiles = async (
  files: FileList | File[],
): Promise<IPFSFile[]> => {
  const result = await apiUploadFiles(files);
  return result as IPFSFile[];
};
