import cx from 'classnames';
import { branding, gray } from 'design-system/colors';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  default: {
    cursor: 'pointer',
    borderRadius: '100px',
    transition: 'all 0.2s',
    display: 'inline-flex',
    alignItems: 'center',
  },
  standard: {
    color: branding.darkPurple,
    backgroundColor: gray['0'],
    border: `1px solid ${gray['0']}`,
    '&:hover': {
      border: '1px solid black',
    },
    '&:active': {
      backgroundColor: gray['100'],
    },
  },
  outlined: {
    border: `1px solid ${gray['0']}`,
    backgroundColor: 'unset',
    color: gray['0'],
    '&:active': {
      border: `1px solid ${gray['100']}`,
      color: gray['100'],
    },
  },
  large: {
    gap: '12px',
    fontSize: '16px',
    padding: '18px 24px 16px',
  },
  medium: {
    gap: '12px',
    fontSize: '16px',
    padding: '12px 16px 10px',
  },
  small: {
    gap: '7px',
    fontSize: '12px',
    padding: '10px 10px 8px',
  },
  disabled: {
    cursor: 'not-allowed',
    border: `1px solid ${gray['300']} !important`,
    backgroundColor: `${gray['300']} !important`,
    color: `${gray['600']} !important`,
  },
  noSelect: {
    '-webkit-touch-callout': 'none' /* iOS Safari */,
    '-webkit-user-select': 'none' /* Safari */,
    '-khtml-user-select': 'none' /* Konqueror HTML */,
    '-moz-user-select': 'none' /* Old versions of Firefox */,
    '-ms-user-select': 'none' /* Internet Explorer/Edge */,
    'user-select': 'none' /* Chrome, Edge, Opera and Firefox */,
  },
});

function Button({
  label,
  outlined,
  size,
  disabled,
  onClick,
  children,
  className,
}: {
  children?: React.ReactNode;
  label: string;
  disabled?: boolean;
  outlined?: boolean;
  size: 'small' | 'medium' | 'large';
  onClick?: React.MouseEventHandler;
  className?: string;
}): JSX.Element {
  const styles = useStyles();

  return (
    <div
      className={cx(className, styles.default, styles.noSelect, styles[size], {
        [styles.standard]: !outlined,
        [styles.outlined]: outlined,
        [styles.disabled]: disabled,
      })}
      onClick={e => !disabled && onClick && onClick(e)}
    >
      {children && children}
      <span>{label}</span>
    </div>
  );
}

export default Button;
