import { CSSProperties, ReactNode } from 'react';
import ReactModal from 'react-modal';

export const MODAL_ZINDEX = 100001;
export const OVERLAY_ZINDEX = 100000;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#202837',
    color: '#fff',
    border: 'none',
    borderRadius: '0px',
    zIndex: MODAL_ZINDEX,
    padding: 0,
  },
  overlay: {
    backgroundColor: 'rgb(0,0,0,0.4)',
    border: 'none',
    zIndex: OVERLAY_ZINDEX,
  },
};

ReactModal.setAppElement('#root');

type IModal = {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  style?: CSSProperties;
};

export default function Modal({
  open,
  onClose,
  children,
  style,
}: IModal): JSX.Element {
  const modalStyles = {
    content: { ...customStyles.content, ...style },
    overlay: { ...customStyles.overlay },
  };
  return (
    <ReactModal
      isOpen={open}
      onRequestClose={onClose}
      style={modalStyles}
      contentLabel="modal"
    >
      <div style={{ position: 'relative' }}>{children}</div>
    </ReactModal>
  );
}
