import { getDAOs, IDAO } from 'api/communitiesApi';
import DAOCard from 'components/DAOCard';
import MainLayout from 'layouts/MainLayout';
import { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: { display: 'flex', flexWrap: 'wrap' },
});

export default function DiscoverView(): JSX.Element {
  const styles = useStyles();

  const [daos, setDaos] = useState<null | IDAO[]>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const result = await getDAOs();
        const data = await result.json();
        setDaos(data);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <MainLayout title={'Discover'} loading={loading}>
      <div className={styles.container}>
        {daos?.map((dao, i) => (
          <DAOCard
            key={`${dao.id}-${i}`}
            dao={dao}
            style={{ width: '45%', marginRight: '24px', marginBottom: '24px' }}
          />
        ))}
      </div>
    </MainLayout>
  );
}
