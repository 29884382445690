import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  title: { fontSize: '20px', marginBottom: '8px' },
  description: { maxWidth: '490px', color: '#D1D3D5', fontSize: '16px' },
  inputTitle: { fontSize: '14px', marginBottom: '8px' },
  input: {
    background: '#202837',
    border: '1px solid #303B5B',
    borderRadius: '8px',
    padding: '18px 16px',
    fontSize: '16px',
    color: '#f9f9f9',
    width: '100%',
  },
});

export default function OnboardingSocialLinks({
  discord,
  setDiscord,
  discordAuthed,
  twitter,
  setTwitter,
  github,
  setGithub,
}: {
  discord: string;
  setDiscord(discord: string): void;
  discordAuthed: boolean;
  twitter: string;
  setTwitter(twitter: string): void;
  github: string;
  setGithub(github: string): void;
}): JSX.Element {
  const styles = useStyles();
  return (
    <div>
      <div className={styles.title}>Link your accounts</div>
      <div className={styles.description}>
        Boost your profile by sharing your social accounts
      </div>
      <div className={styles.inputTitle} style={{ marginTop: '48px' }}>
        Discord
      </div>
      <input
        disabled={discordAuthed}
        placeholder={'username#1234'}
        className={styles.input}
        value={discord}
        onChange={e => setDiscord(e.target.value)}
      />
      <div className={styles.inputTitle} style={{ marginTop: '24px' }}>
        Twitter <span style={{ color: '#BBBCC0' }}>(Optional)</span>
      </div>
      <input
        className={styles.input}
        value={twitter}
        onChange={e => setTwitter(e.target.value)}
      />
      <div className={styles.inputTitle} style={{ marginTop: '24px' }}>
        Github <span style={{ color: '#BBBCC0' }}>(Optional)</span>
      </div>
      <input
        className={styles.input}
        value={github}
        onChange={e => setGithub(e.target.value)}
      />
    </div>
  );
}
