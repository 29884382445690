import { useWallet } from 'contexts/WalletContext';
import { useMemo } from 'react';
import { ShareEscrow, ShareEscrow__factory } from 'web3/types';

export const useShareContract = (): ShareEscrow | null => {
  const { provider, shareConfig } = useWallet();

  return useMemo(
    () =>
      provider && shareConfig
        ? ShareEscrow__factory.connect(
            shareConfig.escrowAddress,
            provider.getSigner(),
          )
        : null,
    [provider, shareConfig],
  );
};
