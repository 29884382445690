import closeIcon from 'assets/close.svg';
import { CSSProperties } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    cursor: 'pointer',
    backgroundColor: '#1F2939',
    color: '#fff',
    padding: '9px 12px',
    borderRadius: '100px',
    border: '1px solid #303B5B',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    userSelect: 'none',
  },
});

export default function FilterPill({
  style,
  text,
  onChange,
  active,
  activeColor,
}: {
  style?: CSSProperties;
  text: string;
  onChange(val: string): void;
  active: boolean;
  activeColor?: string;
}): JSX.Element {
  const styles = useStyles();
  return (
    <div
      style={{
        ...style,
        backgroundColor: active
          ? activeColor
            ? activeColor
            : '#303B5B'
          : '#1F2939',
      }}
      className={styles.container}
      onClick={() => onChange(text)}
    >
      {text}{' '}
      {active && (
        <img
          src={closeIcon}
          alt="remove"
          style={{
            marginLeft: '4px',
          }}
        />
      )}
    </div>
  );
}
