import { getDAOs, IDAO } from 'api/communitiesApi';
import CardHeader from 'components/CardHeader';
import MainLayout from 'layouts/MainLayout';
import { CommunityLocation } from 'Locations';
import { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';

const useStyles = createUseStyles({
  container: {
    border: '1px solid #E5E5E5',
    backgroundColor: '#fff',
    borderRadius: '20px',
    padding: '46px 58px',
  },
  title: {
    fontSize: '24px',
    lineHeight: '29px',
    marginBottom: '30px',
  },
  daoCardsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    columnGap: '20px',
  },
  card: {
    border: '1px solid #E5E5E5',
    borderRadius: '20px',
    color: '#000',
    padding: '19px 22px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
  },
  cardCategory: {
    marginTop: '10px',
    padding: '5px 8px',
    fontSize: '10px',
    lineHeight: '12px',
    textTransform: 'uppercase',
    borderRadius: '50px',
    display: 'inline-block',
  },
  cardSubheader: {
    marginTop: '24px',
    marginBottom: '8px',
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 700,
  },
  cardDescription: {
    fontWeight: 400,
    fontSize: '14px',
    wordBreak: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'box',
    boxOrient: 'vertical',
    lineHeight: '2.5ex',
    height: '10ex',
    lineClamp: 4,
  },
});

export default function CommunitiesView(): JSX.Element {
  const styles = useStyles();
  const [daos, setDaos] = useState<null | IDAO[]>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const result = await getDAOs();
        const data = await result.json();
        setDaos(data);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <MainLayout>
      <div className={styles.container}>
        <div className={styles.title}>Communities</div>{' '}
        {loading ? (
          'Loading...'
        ) : (
          <div className={styles.daoCardsContainer}>
            {daos?.map((dao, i) => (
              <Link
                to={CommunityLocation(dao.id)}
                key={`${dao.id}-${i}`}
                className={styles.card}
              >
                <CardHeader imgSrc={dao.logo} title={dao.name} />
                <div className={styles.cardSubheader}>Overview</div>
                <div className={styles.cardDescription}>{dao.description}</div>
                <div className={styles.cardSubheader}>Token</div>
                <div>{dao.token}</div>
              </Link>
            ))}
          </div>
        )}
      </div>
    </MainLayout>
  );
}
