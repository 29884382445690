import { IOnboardingDao } from 'api/onboardingApi';
import teamDivider from 'assets/teamDivider.svg';
import { createUseStyles } from 'react-jss';
import { Element } from 'react-scroll';

import OnboardingTeamCard from './OnboardingTeamCard';

const useStyles = createUseStyles({
  container: {
    marginTop: '118px',
    display: 'flex',
    justifyContent: 'center',
  },
  content: { maxWidth: '1154px' },
  center: { display: 'flex', justifyContent: 'center' },
  titleCard: {
    position: 'relative',
    textAlign: 'center',
    background: 'rgba(249, 249, 249, 0.1)',
    backdropFilter: 'blur(15px)',
    padding: '39px 48px 24px 48px',
    color: '#f9f9f9',
    fontSize: '16px',
    borderRadius: '8px',
    display: 'inline-block',
  },
  logo: {
    position: 'absolute',
    width: '48px',
    height: '48px',
    borderRadius: '48px',
    left: 'calc(50% - 24px)',
    top: '-24px',
  },
  divider: { width: '100%', margin: '8px 0px 47px 0px' },
  membersContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginRight: '-16px',
  },
});

export default function OnboardingTeam({
  dao,
}: {
  dao: IOnboardingDao | null;
}): JSX.Element {
  const styles = useStyles();
  return (
    <Element name={'team'}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.center}>
            <div className={styles.titleCard}>
              <img src={dao?.logo} alt="logo" className={styles.logo} />
              {dao?.name}
            </div>
          </div>
          <img src={teamDivider} alt="divider" className={styles.divider} />
          <div className={styles.membersContainer}>
            {dao?.keyPeople?.map((teamMember, i) => (
              <OnboardingTeamCard
                key={`${teamMember.name}-${i}`}
                teamMember={teamMember}
              />
            ))}
          </div>
        </div>
      </div>
    </Element>
  );
}
