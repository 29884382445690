import { IUserData } from 'api/usersApi';
import { CSSProperties } from 'react';
import { createUseStyles } from 'react-jss';

import ApplicantHeader from './ApplicantHeader';
import ApplicantSocials from './ApplicantSocials';

export interface ApplicantProps {
  createdAt: string;
  daoId: string;
  discordChannelId: string;
  discordMessageId: string;
  id: number;
  opportunityId: number;
  status: string;
  updatedAt: string;
  userId: number;
  user: IUserData;
  appliedMessage?: string;
}

const useStyles = createUseStyles({
  container: {
    width: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-center',
  },
  divider: {
    background: '#303B5B',
    width: '100%',
    height: '1px',
    marginTop: '32px',
  },
});

export default function Applicant({
  applicant,
  style,
}: {
  applicant: ApplicantProps;
  style?: CSSProperties;
}): JSX.Element {
  const styles = useStyles();

  return (
    <div style={{ ...style }} className={styles.container}>
      <div className={styles.header}>
        <ApplicantHeader applicant={applicant} />
        <ApplicantSocials applicant={applicant} />
      </div>
    </div>
  );
}
