import { IDAO } from 'api/communitiesApi';
import { createUseStyles } from 'react-jss';

import Contributor from './Contributor';

const useStyles = createUseStyles({
  container: {
    marginTop: '40px',
  },
});

export default function CommunityContributors({
  dao,
}: {
  dao: IDAO;
}): JSX.Element {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      {dao.contributors.map((contributor, i) => (
        <Contributor
          contributor={contributor}
          key={`${contributor.id}-${i}`}
          style={{ marginBottom: '24px' }}
        />
      ))}
    </div>
  );
}
