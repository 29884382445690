import discordLight from 'assets/discordLight.svg';
import githubLight from 'assets/githubLight.svg';
import twitterLight from 'assets/twitterLight.svg';
import { createUseStyles } from 'react-jss';
import { taskFilters } from 'views/Opportunities';

import { IOnboardingMember } from '../api/onboardingApi';

const useStyles = createUseStyles({
  orgCard: {
    position: 'relative',
    border: '1px solid #303B5B',
    backgroundColor: '#202837',
    borderRadius: '8px',
    padding: '46px 40px 16px 40px',
    width: '240px',
  },
  image: {
    width: '48px',
    height: '48px',
    borderRadius: '48px',
    position: 'absolute',
    left: 'calc(50% - 24px)',
    top: '-24px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  name: {
    fontSize: '16px',
    color: '#f9f9f9',
    textAlign: 'center',
    marginBottom: '8px',
  },
  role: { fontSize: '14px', textAlign: 'center', color: '#A4A6AB' },
  socialsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '16px',
  },
  social: { width: '20px', marginRight: '16px' },
});

export default function OrgCard({
  member,
}: {
  member: IOnboardingMember;
}): JSX.Element {
  const styles = useStyles();
  return (
    <div
      className={styles.orgCard}
      style={{
        borderTop: `2px solid ${
          taskFilters.find(filter => filter.name === member.team)?.color ||
          '#673969'
        }`,
      }}
    >
      <div
        className={styles.image}
        style={{ backgroundImage: `url(${member.avatar})` }}
      />
      <div className={styles.name}>{member.name}</div>
      <div className={styles.role}>{member.title}</div>
      <div className={styles.socialsContainer}>
        {member.discord && (
          <a
            href={`https://discordapp.com/users/${member.discord}`}
            target="_blank"
            rel="noreferrer"
          >
            <img src={discordLight} alt="discord" className={styles.social} />
          </a>
        )}
        {member.twitter && (
          <a
            href={`https://twitter.com/${member.twitter}`}
            target="_blank"
            rel="noreferrer"
          >
            <img src={twitterLight} alt="twitter" className={styles.social} />
          </a>
        )}
        {member.github && (
          <a
            href={`https://github.com/${member.github}`}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={githubLight}
              alt="github"
              className={styles.social}
              style={{ marginRight: 0 }}
            />
          </a>
        )}
      </div>
    </div>
  );
}
