import { Skill } from 'views/SignUp';

import { IDAO } from './communitiesApi';
import { RequestTypes, serverRequest } from './serverRequest';

export const connectUser = async (daoId?: number): Promise<Response> => {
  return serverRequest('users/connect', RequestTypes.Post, { daoId });
};

export type IUserData = {
  username?: string;
  email?: string;
  twitter?: string | null;
  discord?: string | null;
  discordId?: string;
  discordAvatar?: string;
  github?: string | null;
  bio?: string;
  dao?: IDAO;
  contributions?: IDAO[];
  skills?: Skill[];
  experience?: string;
  availability?: string;
  roles?: string[];
  wallet?: string;
  updatedAt?: string;
  daoId?: number;
  id?: number;
  yat?: string;
  createdAt?: string;
  none?: number;
  tags?: string[];
  opportunityApplicants?: {
    id: number;
    opportunityId: number;
    daoId: number;
    userId: number;
    status: string;
    appliedMessage?: string;
  }[];
};

export const updateUser = async ({
  id,
  data,
}: {
  id: number;
  data: IUserData;
}): Promise<Response> => {
  return serverRequest(`users/${id}`, RequestTypes.Patch, data);
};

export const getUser = async (wallet: string): Promise<Response> => {
  return serverRequest(`users/${wallet}`);
};

export const getContributors = async (daoId: number): Promise<Response> => {
  return serverRequest(`users?daoId=${daoId}`);
};

export const authDiscord = async (code: string): Promise<Response> => {
  return serverRequest('users/auth/discord', RequestTypes.Post, { code });
};

export const addToMailingList = async (email: string): Promise<Response> => {
  return serverRequest('users/mailing-list', RequestTypes.Post, { email });
};
