import { IOpportunity } from 'api/opportunitiesApi';
//import dropdownIcon from 'assets/dropdown.svg';
import makeBlockie from 'ethereum-blockies-base64';
import { OpportunityLocation } from 'Locations';
import { CSSProperties } from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import { truncate, ZERO_ADDRESS } from 'utils/utils';
import { OpportunityStatuses } from 'views/Opportunity';

const useStyles = createUseStyles({
  container: {
    padding: '16px',
    width: '100%',
    borderRadius: '8px',
    display: 'block',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: '16px',
    lineHeight: '21.6px',
    fontWeight: 400,
    color: '#F9F9F9',
    maxWidth: '304px',
  },
  dropdown: {
    cursor: 'pointer',
    borderRadius: '4px',
  },
  description: {
    marginTop: '10px',
    color: '#BBBCC0',
    fontSize: '14px',
    fontWeight: 300,
    lineHeight: '135%',
  },
  footer: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  currency: { color: '#F9F9F9', fontSize: '14px' },
  applicantsContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  moreApplicants: {
    marginLeft: '8px',
    fontSize: '14px',
    color: '#F9F9F9',
    display: 'flex',
    alignItems: 'center',
    width: '40px',
  },
  zeroApplicants: { color: '#767A81', fontSize: '12px' },
  avatarContainer: { position: 'relative' },
  avatar: {
    width: '24px',
    height: '24px',
    borderRadius: '24px',
    border: '1px solid #697487',
    position: 'absolute',
    top: '-12px',
  },
  subMenu: {
    position: 'absolute',
    bottom: '-52px',
    right: 0,
    backgroundColor: '#202837',
    border: '1px solid #303B5B',
    borderRadius: '8px',
    padding: '24px 16px',
  },
  applicantHeader: {
    color: '#A4A6AB',
    fontSize: '12px',
    marginBottom: '8px',
  },
  applicantContainer: { display: 'flex', alignItems: 'center' },
  applicantName: { marginLeft: '8px', fontSize: '14px', color: '#f9f9f9' },
});

export default function ColorOpportunityCard({
  opportunity,
  style,
}: {
  opportunity: IOpportunity;
  style?: CSSProperties;
}): JSX.Element {
  const styles = useStyles();
  //const menuRef = useRef(null);
  //const [menu, setMenu] = useState(false);

  //useOutsideAlerter(menuRef, () => setMenu(false));

  return (
    <Link
      to={OpportunityLocation(opportunity.id)}
      style={{
        ...style,
        backgroundColor:
          OpportunityStatuses.find(opp => opp.key === opportunity.status)
            ?.secondaryColor || '#2A2639',
      }}
      className={styles.container}
    >
      <div className={styles.header}>
        <div className={styles.title}>{truncate(opportunity?.title, 67)}</div>
        {/*<div
          style={{ position: 'relative' }}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            e.nativeEvent.stopImmediatePropagation();
            setMenu(true);
          }}
        >
          <img src={dropdownIcon} alt="dropdown" className={styles.dropdown} />
          {menu && <div className={styles.subMenu}></div>}
        </div>*/}
      </div>
      <div className={styles.description}>
        {truncate(opportunity.description, 74)}
      </div>
      <div
        className={styles.footer}
        style={{
          alignItems:
            opportunity.status === 'InReview' ||
            opportunity.status === 'InProgress'
              ? 'flex-end'
              : 'center',
        }}
      >
        <div className={styles.applicantsContainer}>
          {opportunity.status === 'InReview' ||
          opportunity.status === 'InProgress' ? (
            <div>
              <div className={styles.applicantHeader}>
                {opportunity.status === 'InReview' ? 'Reviewer' : 'Contributor'}
              </div>
              <div className={styles.applicantContainer}>
                <img
                  src={makeBlockie(
                    opportunity.status === 'InReview'
                      ? opportunity.reviewer?.wallet || ZERO_ADDRESS
                      : opportunity.user?.wallet || ZERO_ADDRESS,
                  )}
                  style={{
                    width: '24px',
                    height: '24px',
                    borderRadius: '24px',
                  }}
                  alt="avatar"
                />

                <div className={styles.applicantName}>
                  {opportunity.status === 'InReview'
                    ? opportunity.reviewer?.username
                    : opportunity.user?.username}
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className={styles.avatarContainer}>
                {opportunity.opportunityApplicants
                  ?.slice(0, 3)
                  .map((applicant, i) => (
                    <img
                      key={`${applicant.userId}-${i}`}
                      src={makeBlockie(applicant.user.wallet || '')}
                      alt="blockie"
                      className={styles.avatar}
                      style={{ left: `${i * 16}px` }}
                    />
                  ))}
              </div>
              {opportunity.opportunityApplicants?.length === 0 ? (
                <div className={styles.zeroApplicants}>Zero applicants</div>
              ) : (
                opportunity.opportunityApplicants?.length > 3 && (
                  <div
                    className={styles.moreApplicants}
                    style={{
                      position: 'absolute',
                      top: '-7px',
                      left: opportunity.opportunityApplicants
                        ? `${
                            opportunity?.opportunityApplicants?.length * 16 - 8
                          }px`
                        : 0,
                    }}
                  >
                    + {opportunity.opportunityApplicants?.length - 3}
                  </div>
                )
              )}
            </>
          )}
        </div>

        <div
          className={styles.currency}
          style={{
            marginBottom:
              opportunity.status === 'InReview' ||
              opportunity.status === 'InProgress'
                ? '4px'
                : 0,
          }}
        >
          {opportunity.fee} {opportunity.coin}
        </div>
      </div>
    </Link>
  );
}
