import { authDiscord } from 'api/usersApi';
import discordIcon from 'assets/signUpDiscord.svg';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { createUseStyles } from 'react-jss';
import useGlobalState from 'state';
import { getHostURL } from 'utils/utils';

const useStyles = createUseStyles({
  title: { fontSize: '20px', marginBottom: '8px' },
  subtitle: {
    fontSize: '16px',
    lineHeight: '135%',
    color: '#D1D3D5',
    maxWidth: '490px',
  },
  CTAContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '48px',
    border: '1px solid #303B5B',
    backgroundColor: '#202837',
    padding: '16px',
    borderRadius: '8px',
    cursor: 'pointer',
  },
  connectCTA: {
    padding: '10px',
    borderRadius: '100px',
    border: '0.5px solid #f9f9f9',
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
  },
  walletText: {
    marginLeft: '16px',
    fontSize: '16px',
  },
  iconsContainer: { display: 'flex', alignItems: 'center' },
  orContainer: { margin: '32px 0', display: 'flex', alignItems: 'center' },
  orDivider: { height: '0.5px', width: '100%', backgroundColor: '#A4A6AB' },
  orText: {
    margin: '0 24px',
    fontSize: '16px',
    color: '#D1D3D5',
    flexShrink: 0,
  },
  inputTitle: { fontSize: '14px', color: '#f9f9f9', marginBottom: '8px' },
  input: {
    background: '#202837',
    border: '1px solid #303B5B',
    borderRadius: '8px',
    padding: '18px 16px',
    fontSize: '16px',
    color: '#f9f9f9',
    width: '100%',
  },
  dot: {
    width: '5px',
    height: '5px',
    borderRadius: '12px',
    backgroundColor: '#2B8C34',
    marginRight: '6px',
    marginBottom: '1px',
  },
});

export default function OnboardingCommunicationPreference({
  discord,
  setDiscord,
  email,
  setEmail,
}: {
  discord: string | null;
  setDiscord(discord: string): void;
  email: string;
  setEmail(email: string): void;
}): JSX.Element {
  const styles = useStyles();
  const { loggedInUser, setLoggedInUser } = useGlobalState();
  const hostURL = getHostURL();

  useEffect(() => {
    const cb = async (event: MessageEvent) => {
      if (event.origin !== hostURL) {
        return;
      }

      const { discordCode } = event.data;
      if (discordCode) {
        try {
          const response = await authDiscord(discordCode);
          const data = await response.json();
          const { username, discriminator, avatar, id } = data;
          setLoggedInUser({
            ...loggedInUser,
            discordAvatar: avatar,
            discordId: id,
          });
          if (username && discriminator) {
            setDiscord(`${username}#${discriminator}`);
          } else {
            throw new Error('invalid discord data');
          }
        } catch (e) {
          toast.error('Oops! Something went wrong. Please try again.');
        }
      }
    };

    window.addEventListener('message', cb, false);
    return () => {
      window.removeEventListener('message', cb);
    };
  });

  return (
    <div>
      <div className={styles.title}>How would you like to stay up to date?</div>
      <div className={styles.subtitle}>
        We recommend connecting with Discord for best experience. We&apos;ll
        only message you for the important stuff.
      </div>
      <div
        className={styles.CTAContainer}
        onClick={() => {
          if (!discord) {
            window.open(
              `https://discord.com/api/oauth2/authorize?client_id=${process.env.REACT_APP_DISCORD_CLIENT_ID}&redirect_uri=${hostURL}/auth/discord&response_type=code&scope=identify`,
              'popup',
              'width=600,height=800',
            );
          }
        }}
        style={{ cursor: discord ? 'default' : 'pointer' }}
      >
        <div className={styles.iconsContainer}>
          <img src={discordIcon} alt="discord" />
          <div className={styles.walletText}>Discord</div>
        </div>
        <div className={styles.connectCTA}>
          {discord && <div className={styles.dot} />}
          {discord ? discord : 'Connect'}
        </div>
      </div>
      <div className={styles.orContainer}>
        <div className={styles.orDivider} />
        <div className={styles.orText}>or</div>
        <div className={styles.orDivider} />
      </div>
      <div className={styles.inputTitle}>Enter your email</div>
      <input
        style={{
          backgroundColor: discord !== null ? '#767A81' : '#202837',
          border: discord !== null ? '1px solid #BBBCC0' : '1px solid #303B5B',
          cursor: discord !== null ? 'not-allowed' : 'auto',
        }}
        disabled={discord !== null}
        className={styles.input}
        value={email}
        onChange={e => setEmail(e.target.value)}
      />
    </div>
  );
}
