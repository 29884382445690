import coinbaseIcon from 'assets/coinbase.svg';
import metamaskIcon from 'assets/metamask.svg';
import walletConnectIcon from 'assets/walletConnect.svg';
import { useWallet } from 'contexts/WalletContext';
import { createUseStyles } from 'react-jss';
import { formatAddress } from 'utils/utils';

const useStyles = createUseStyles({
  title: { fontSize: '20px', marginBottom: '8px' },
  subtitle: {
    fontSize: '16px',
    lineHeight: '135%',
    color: '#D1D3D5',
    maxWidth: '490px',
  },
  CTAContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '64px',
    border: '1px solid #303B5B',
    backgroundColor: '#202837',
    padding: '16px',
    borderRadius: '8px',
    cursor: 'pointer',
  },
  iconsContainer: { display: 'flex', alignItems: 'center' },
  walletText: {
    marginLeft: '16px',
    fontSize: '16px',
  },
  connectCTA: {
    padding: '10px',
    borderRadius: '100px',
    border: '0.5px solid #f9f9f9',
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    userSelect: 'none',
  },
  subtext: {
    maxWidth: '490px',
    color: '#A4A6AB',
    fontSize: '14px',
    lineHeight: '135%',
    marginTop: '32px',
  },
  dot: {
    width: '5px',
    height: '5px',
    borderRadius: '12px',
    backgroundColor: '#2B8C34',
    marginRight: '6px',
    marginBottom: '1px',
  },
  metamask: { color: 'inherit', textDecoration: 'underline' },
});

export default function OnboardingConnectWallet({
  daoId,
}: {
  daoId: string | null;
}): JSX.Element {
  const styles = useStyles();
  const { address, isConnected, isConnecting, connectWallet, disconnect } =
    useWallet();

  return (
    <div>
      <div className={styles.title}>Your web3 identity</div>
      <div className={styles.subtitle}>
        Connect your wallet with one of our supported providers
      </div>
      <div
        className={styles.CTAContainer}
        onClick={() =>
          !isConnecting && isConnected
            ? disconnect()
            : connectWallet(daoId ? parseFloat(daoId) : undefined)
        }
      >
        <div className={styles.iconsContainer}>
          <img src={metamaskIcon} alt="metamask" />
          <img
            src={coinbaseIcon}
            style={{ marginLeft: '16px' }}
            alt="coinbase"
          />
          <img
            src={walletConnectIcon}
            style={{ marginLeft: '16px' }}
            alt="walletConnect"
          />
          <div className={styles.walletText}>Wallet</div>
        </div>
        <div className={styles.connectCTA}>
          {isConnected && <div className={styles.dot} />}
          {isConnecting
            ? 'Connecting...'
            : isConnected
            ? formatAddress(address, null)
            : 'Connect'}
        </div>
      </div>
      <div className={styles.subtext}>
        We recommend using{' '}
        <a
          href="https://metamask.io/download/"
          target="_blank"
          rel="noreferrer"
          className={styles.metamask}
        >
          Metamask
        </a>{' '}
        if you don&apos;t have a wallet, it only takes a few minutes to set up.
      </div>
    </div>
  );
}
