import { IOpportunity } from 'api/opportunitiesApi';
import emptyTasks from 'assets/emptyTasks.png';
import emptyTasksCompleted from 'assets/emptyTasksCompleted.png';
import { OpportunityCard } from 'components/OpportunityCard';
import { useWallet } from 'contexts/WalletContext';
import { getContributorTasks } from 'graphql/tasks';
import MainLayout from 'layouts/MainLayout';
import { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import useGlobalState from 'state';
import { buildTask } from 'utils/tasks';

const tabs = [
  { name: 'In Progress', value: 'inProgress', color: '#D6AA3C' },
  { name: 'In Review', value: 'inReview', color: '#9D6027' },
  { name: 'Completed', value: 'completed', color: '#2B8C34' },
];

const useStyles = createUseStyles({
  container: {
    maxWidth: '100%',
  },
  tabsContainer: { display: 'flex', borderBottom: '1px solid #303B5B' },
  tab: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '32px',
    fontSize: '16px',
    cursor: 'pointer',
    paddingBottom: '24px',
  },
  dot: { width: '6px', height: '6px', marginRight: '8px', borderRadius: '6px' },
  opportunitiesContainer: { marginTop: '40px' },
  emptyContainer: { display: 'flex', justifyContent: 'center' },
  emptyDescription: {
    textAlign: 'center',
    color: '#BBBCC0',
    marginTop: '16px',
    fontSize: '14px',
    maxWidth: '310px',
    lineHeight: '135%',
  },
  emptyTitle: { textAlign: 'center', color: '#f9f9f9', fontSize: '20px' },
});

export default function TasksView(): JSX.Element {
  const styles = useStyles();
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [tasks, setTasks] = useState<{
    applied: IOpportunity[];
    explore: IOpportunity[];
    inProgress: IOpportunity[];
    inReview: IOpportunity[];
    completed: IOpportunity[];
  } | null>(null);
  const [loading, setLoading] = useState(false);
  const { loggedInUser } = useGlobalState();
  const { address } = useWallet();

  const fetchTasks = async () => {
    if (address) {
      const rawTasks = await getContributorTasks(address);
      const tasks = rawTasks.map(rawTask => buildTask(rawTask));
      const finalResult: {
        applied: IOpportunity[];
        explore: IOpportunity[];
        inProgress: IOpportunity[];
        inReview: IOpportunity[];
        completed: IOpportunity[];
        bookmarked: IOpportunity[];
      } = {
        applied: [],
        explore: [],
        inProgress: [],
        inReview: [],
        completed: [],
        bookmarked: [],
      };
      tasks.forEach(task => {
        if (task.status === 'Available') {
          finalResult.explore.push(task);
        } else if (task.status === 'InProgress') {
          finalResult.inProgress.push(task);
        } else if (task.status === 'Completed') {
          finalResult.completed.push(task);
        } else if (task.status === 'InReview') {
          finalResult.inReview.push(task);
        }
      });
      setTasks(finalResult);
    }
  };
  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        await fetchTasks();
      } finally {
        setLoading(false);
      }
    })();
    //eslint-disable-next-line
  }, [loggedInUser, address]);

  const currentOpportunities =
    selectedTab.name === 'In Progress'
      ? tasks?.inProgress
      : selectedTab.name === 'In Review'
      ? tasks?.inReview
      : tasks?.completed;

  return (
    <MainLayout title={'My tasks'} loading={loading}>
      <div className={styles.container}>
        <div className={styles.tabsContainer}>
          {tabs.map((tab, i) => (
            <div
              key={`${tab}-${i}`}
              className={styles.tab}
              style={{
                borderBottom:
                  selectedTab.name === tab.name ? '1px solid #fff' : 'none',
                color: selectedTab.name === tab.name ? '#fff' : '#A4A6AB',
              }}
              onClick={() => setSelectedTab(tab)}
            >
              <div
                className={styles.dot}
                style={{ backgroundColor: tab.color }}
              />
              {tab.name}
              {/* @ts-ignore */}
              {tasks && tasks[tab.value].length > 0 && (
                <span style={{ color: '#A4A6AB', marginLeft: '6px' }}>
                  {/* @ts-ignore */}
                  {`(${tasks && tasks[tab.value].length})`}
                </span>
              )}
            </div>
          ))}
        </div>
        <div className={styles.opportunitiesContainer}>
          {currentOpportunities && currentOpportunities.length > 0 ? (
            currentOpportunities?.map((opp, i) => (
              <OpportunityCard
                opportunity={opp}
                key={`${opp.id}-${i}`}
                task
                style={{ marginBottom: '16px', maxWidth: '777px' }}
              />
            ))
          ) : (
            <div className={styles.emptyContainer}>
              <div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img
                    src={
                      selectedTab.name === 'Completed'
                        ? emptyTasksCompleted
                        : emptyTasks
                    }
                    alt="empty"
                    style={{ width: '193px' }}
                  />
                </div>
                <div className={styles.emptyTitle}>
                  {selectedTab.name === 'In Progress'
                    ? 'No tasks in progress'
                    : selectedTab.name === 'In Review'
                    ? 'No tasks in review'
                    : 'No completed tasks'}
                </div>
                <div className={styles.emptyDescription}>
                  {selectedTab.name === 'In Progress'
                    ? 'Once the DAO assigns you the task, it will be shown here'
                    : selectedTab.name === 'In Review'
                    ? 'Once you submit the task to review, it will be shown here'
                    : 'Once you complete the task, it will be shown here'}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </MainLayout>
  );
}
