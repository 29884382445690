import { client } from './client';
import {
  GetGlobalInfoDocument,
  GetGlobalInfoQuery,
  GetGlobalInfoQueryVariables,
  GlobalInfoFragment,
} from './types';

export const getGlobalInfo = async (): Promise<GlobalInfoFragment | null> => {
  const { data, error } = await client
    .query<GetGlobalInfoQuery, GetGlobalInfoQueryVariables>(
      GetGlobalInfoDocument,
      {},
    )
    .toPromise();
  if (!data || !data.globals.length) {
    if (error) {
      throw error;
    }
    return null;
  }

  return data.globals[0];
};
