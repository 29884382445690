import { getOnboarding, IOnboardingDao } from 'api/onboardingApi';
import background2 from 'assets/onboardingBackground2.png';
import shareLogo from 'assets/onboardingLogo.png';
import shareBackground from 'assets/shareBackground.png';
import cx from 'classnames';
import OnboardingGettingStarted from 'components/Onboarding/OnboardingGettingStarted';
import OnboardingIntroduction from 'components/Onboarding/OnboardingIntroduction';
import OnboardingTeam from 'components/Onboarding/OnboardingTeam';
import OnboardingValues from 'components/Onboarding/OnboardingValues';
import { useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { RouteComponentProps } from 'react-router';
import { scroller } from 'react-scroll';
import { useScrollPosition } from 'utils/utils';

const useStyles = createUseStyles({
  backgroundImage: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100vh',
    width: '100vw',
    position: 'fixed',
  },
  backgroundImage2: {
    position: 'absolute',
    height: '70vh',
    top: '20vh',
    right: '-50px',
  },
  container: {
    height: '100vh',
    position: 'relative',
    width: '100vw',
  },
  shareBackground: {
    position: 'absolute',
    right: '-40px',
    top: '10vh',
    height: '80vh',
  },
  logoContainer: {
    padding: '40px 40px 0px 40px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logo: { width: '128px', flexShrink: 0, cursor: 'pointer' },
  titleContainer: { marginTop: '116px', paddingLeft: '100px' },
  daoLogo: { height: '122px', borderRadius: '122px' },
  title: {
    marginTop: '40px',
    fontSize: '70px',
    lineHeight: '70px',
    fontWeight: 100,
    fontFamily: 'Eiko',
    color: '#f9f9f9',
  },
  divider: {
    width: '624px',
    margin: '24px 0 64px 0',
    height: '0.5px',
    backgroundColor: 'rgba(249, 249, 249, 0.5)',
  },
  CTA: {
    backgroundColor: '#f9f9f9',
    borderRadius: '100px',
    padding: '18px 24px',
    fontSize: '16px',
    color: '#1B212D',
    display: 'inline-block',
    cursor: 'pointer',
  },
  subtitle: { marginTop: '24px', color: '#fff', fontSize: '24px' },
  stepperContainer: {
    width: '100vw',
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    zIndex: 1,
    opacity: 17.052,
    height: '122px',
    overflow: 'hidden',
  },
  stepperSubContainer: {
    backgroundSize: 'cover',
    width: '100vw',
    height: '100vh',
    backgroundPosition: 'center',
  },
  stepperContentWrapper: {
    display: 'flex',
    flexGrow: '1',
    marginRight: '274px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  stepperContent: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    padding: '24px 0px',
    userSelect: 'none',
    marginTop: '18px',
    minWidth: '733px',
    flexBasis: '891px',
  },
  stepperShareLogo: {
    margin: '40px 106px 0px 40px',
    alignSelf: 'flex-start',
  },
  tab: {
    cursor: 'pointer',
    color: '#f9f9f9',
    fontSize: '20px',
    lineHeight: '20px',
    textAlign: 'center',
  },
  dotContainer: {
    marginBottom: '12px',
    display: 'flex',
    justifyContent: 'center',
  },
  outerDot: {
    width: '24px',
    height: '24px',
    border: '2px solid #fff',
    borderRadius: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dot: {
    width: '16px',
    height: '16px',
    backgroundColor: '#f9f9f9',
    borderRadius: '20px',
  },
  stepperLineContainer: {
    position: 'absolute',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    top: '36px',
  },
  stepperLine: {
    width: 'calc(100% - 133px)',
    height: '1px',
    backgroundColor: '#f9f9f9',
    opacity: 0.5,
  },
});

export default function OnboardingView({
  match,
}: RouteComponentProps<{ dao: string }>): JSX.Element {
  const styles = useStyles();
  const [dao, setDao] = useState<null | IOnboardingDao>(null);
  const [step, setStep] = useState(0);

  const introductionRef = useRef(null);
  const teamRef = useRef(null);
  const valuesRef = useRef(null);
  const gettingStartedRef = useRef(null);

  //@ts-ignore
  const introductionRect = introductionRef?.current?.getBoundingClientRect();
  //@ts-ignore
  const teamRect = teamRef?.current?.getBoundingClientRect();
  //@ts-ignore
  const valuesRect = valuesRef?.current?.getBoundingClientRect();
  const gettingStartedRect =
    //@ts-ignore
    gettingStartedRef?.current?.getBoundingClientRect();

  const tabs: {
    name: string;
    to: string;
    component: JSX.Element;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rect: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ref: any;
  }[] = [
    {
      name: 'Introduction',
      to: 'introduction',
      component: <OnboardingIntroduction dao={dao} />,
      rect: introductionRect,
      ref: introductionRef,
    },
    {
      name: 'Team Structure',
      to: 'team',
      component: <OnboardingTeam dao={dao} />,
      rect: teamRect,
      ref: teamRef,
    },
    {
      name: 'Goals',
      to: 'values',
      component: <OnboardingValues dao={dao} />,
      rect: valuesRect,
      ref: valuesRef,
    },
    {
      name: 'Get Started',
      to: 'getting-started',
      component: <OnboardingGettingStarted dao={dao} />,
      rect: gettingStartedRect,
      ref: gettingStartedRef,
    },
  ];

  useEffect(() => {
    getOnboarding(match.params.dao)
      .then(data => data.json())
      .then(result => setDao(result));
  }, [match]);

  const scrollMe = (name: string) => {
    scroller.scrollTo(name, {
      smooth: true,
      duration: 300,
      offset: -180,
    });
  };

  const scrollPosition = useScrollPosition();

  return (
    <div>
      <div
        className={styles.backgroundImage}
        style={{ backgroundImage: `url(${background2})` }}
      />
      <div className={styles.container}>
        <img
          className={styles.backgroundImage2}
          src={shareBackground}
          alt="share"
        />
        <div
          className={styles.stepperContainer}
          style={{ opacity: (scrollPosition - 400) / 100 }}
        >
          <div
            className={styles.stepperSubContainer}
            style={{ backgroundImage: `url(${background2})` }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={shareLogo}
                alt="share"
                className={cx(styles.logo, styles.stepperShareLogo)}
                onClick={() => setStep(0)}
              />
              <div className={styles.stepperContentWrapper}>
                <div className={styles.stepperContent}>
                  <div className={styles.stepperLineContainer}>
                    <div className={styles.stepperLine} />
                  </div>
                  {tabs.map((tab, i) => (
                    <div
                      key={`${tab}-${i}`}
                      className={styles.tab}
                      onClick={() => scrollMe(tab.to)}
                      style={{ opacity: tab.rect?.bottom < 800 ? 1 : 0.5 }}
                    >
                      <div className={styles.dotContainer}>
                        <div
                          className={styles.outerDot}
                          style={{
                            border: step === i + 1 ? '2px solid #fff' : 'none',
                          }}
                        >
                          <div className={styles.dot} />
                        </div>
                      </div>
                      <div>{tab.name}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.logoContainer}>
          <img
            src={shareLogo}
            alt="share"
            className={styles.logo}
            style={{ marginRight: '64px' }}
            onClick={() => setStep(0)}
          />

          <div className={styles.logo} style={{ marginLeft: '64px' }} />
        </div>
        {dao && (
          <>
            <div className={styles.titleContainer}>
              <img src={dao?.logo} alt="dao" className={styles.daoLogo} />
              <div className={styles.title}>Welcome to {dao?.name}</div>
              <div className={styles.subtitle}>
                New contributor onboarding with Share
              </div>
              <div className={styles.divider} />
              <div
                className={styles.CTA}
                onClick={() => scrollMe('introduction')}
              >
                Let&apos;s Go
              </div>
            </div>

            <>
              {tabs.map((tab, i) => (
                <div key={`${tab.name}-${i}`} ref={tab.ref}>
                  {tab.component}
                </div>
              ))}
            </>
          </>
        )}
      </div>
    </div>
  );
}
