import { IOpportunity } from 'api/opportunitiesApi';
import { Fragment } from 'react';
import { createUseStyles } from 'react-jss';

import Applicant from './Applicant';

const useStyles = createUseStyles({
  divider: {
    marginBottom: '36px',
    background: '#303B5B',
    width: '100%',
    height: '1px',
    marginTop: '32px',
  },
});

export default function OpportunityApplicants({
  opportunity,
}: {
  opportunity: IOpportunity | null;
}): JSX.Element {
  const styles = useStyles();

  return (
    <div>
      <div style={{ paddingTop: '48px' }}>
        {opportunity?.opportunityApplicants?.map((applicant, i) => (
          <Fragment key={`${applicant.userId}-${i}`}>
            <Applicant applicant={applicant} />
            <div className={styles.divider} />
          </Fragment>
        ))}
      </div>
    </div>
  );
}
