import { expertiseOptions } from 'api/onboardingApi';
import { getTasksMetadata, IOpportunity } from 'api/opportunitiesApi';
import searchIcon from 'assets/search.svg';
import FilterPill from 'components/FilterPill';
import FilterSelect from 'components/FilterSelect';
import { OpportunityCard } from 'components/OpportunityCard';
import { useWallet } from 'contexts/WalletContext';
import { getAllTasks } from 'graphql/tasks';
import MainLayout from 'layouts/MainLayout';
import { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import useGlobalState from 'state';
import { buildTask } from 'utils/tasks';

export const taskTabs = [
  { key: 'explore', name: 'Explore Opportunities', color: '#BDBDBD' },
  { key: 'applied', name: 'Applied Opportunities', color: '#F2C94C' },
  { key: 'inProgress', name: 'My Tasks', color: '#F2994A' },
  { key: 'completed', name: 'Completed', color: '#27AE60' },
];

export const taskFilters = [
  { name: 'Development', color: '#BB7800' },
  { name: 'Design', color: '#673969' },
  { name: 'Content', color: '#AB3D41' },
  { name: 'Finance', color: '#40438F' },
  { name: 'Operations', color: '#8A244F' },
  { name: 'Marketing', color: '#9D6027' },
  { name: 'Social', color: '#98409C' },
  { name: 'Community', color: '#1E9276' },
  { name: 'Product', color: undefined },
];

export const taskRewards = [
  { label: 'Less than $100', lowerLimit: 0, upperLimit: 100 },
  { label: '$100-$300', lowerLimit: 100, upperLimit: 300 },
  { label: '$300-$500', lowerLimit: 300, upperLimit: 500 },
  { label: '$500-$1000', lowerLimit: 500, upperLimit: 1000 },
  { label: '$1000+', lowerLimit: 1000, upperLimit: 1000000000 },
];

export const taskCoins = ['$ETH', '$FOX', '$USDC', '$GCR', '$OMH'];

export const taskApplicants = [
  { label: 'Up to 2', lowerLimit: 0, upperLimit: 2 },
  { label: '3 to 5', lowerLimit: 3, upperLimit: 5 },
  { label: '6 to 9', lowerLimit: 6, upperLimit: 9 },
  { label: '10+', lowerLimit: 10, upperLimit: 1000000 },
];

export const filterSelectOptions = [
  { value: 'newest', label: 'Newest' },
  { value: 'oldest', label: 'Oldest' },
];

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    color: '#fff',
  },
  searchTabsContainer: { display: 'flex', borderBottom: '1px solid #303B5B' },
  searchTab: {
    color: '#A4A6AB',
    fontSize: '16px',
    marginRight: '32px',
    paddingBottom: '24px',
    cursor: 'pointer',
  },
  searchInputContainer: {
    marginTop: '32px',
    display: 'flex',
  },
  searchInput: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #303B5B',
    backgroundColor: '#202837',
    borderRadius: '8px',
  },
  searchInputIcon: { padding: '14px 16px', flexShrink: 0 },
  searchInputText: {
    color: '#fff',
    fontSize: '16px',
    height: '100%',
    width: '100%',
    background: 'transparent',
    border: 'none',
  },
  amount: { marginTop: '24px', color: '#A4A6AB', fontSize: '14px' },
  opportunitiesContainer: { marginTop: '16px' },
  filtersTitleContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  filterTitle: {
    fontSize: '24px',
  },
  clear: {
    color: '#A4A6AB',
    fontSize: '14px',
    cursor: 'pointer',
  },
  filtersSubtitle: {
    color: '#BBBCC0',
    fontSize: '#BBBCC0',
    marginTop: '16px',
    lineHeight: '135%',
  },
  filterCategoryContainer: {
    marginTop: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  filterCategoryTitle: { fontSize: '16px' },
  filterPillsContainer: {
    marginTop: '24px',
    display: 'flex',
    marginRight: '-8px',
    flexWrap: 'wrap',
  },
  filterSelect: { marginLeft: '24px', flexShrink: 0, width: '180px' },
});

const searchTabs = ['Search', 'Applied', 'Saved'];

export default function OpportunitiesView(): JSX.Element {
  const styles = useStyles();
  const { chainId } = useWallet();
  const [opportunities, setOpportunities] = useState<{
    applied: IOpportunity[];
    explore: IOpportunity[];
    inProgress: IOpportunity[];
    completed: IOpportunity[];
    bookmarked: IOpportunity[];
  } | null>(null);
  const [loading, setLoading] = useState(false);
  const [selectedSearchTab, setSelectedSearchTab] = useState(searchTabs[0]);
  const [categoryFilters, setCategoryFilters] = useState<string[]>([]);
  const [rewardFilters, setRewardFilters] = useState<
    { label: string; lowerLimit: number; upperLimit: number }[]
  >([]);
  const [coinFilters, setCoinFilters] = useState<string[]>([]);
  const [applicantsFilters, setApplicantsFilters] = useState<
    { label: string; lowerLimit: number; upperLimit: number }[]
  >([]);
  const [experienceFilters, setExperienceFilters] = useState<string[]>([]);
  const [timeFilters, setTimeFilters] = useState<string[]>([]);
  const [filterSelect, setFilterSelect] = useState<{
    value: string;
    label: string;
  }>(filterSelectOptions[0]);
  const [searchText, setSearchText] = useState('');

  const { loggedInUser } = useGlobalState();
  const fetchOpportunities = async () => {
    let rawTasks = await getAllTasks();
    const metadataArray = rawTasks.map(result => {
      return {
        subgraphId: result.id,
        contributorAddress: result.contributor?.address,
        reviewerAddress: result.reviewer.address,
      };
    });
    const resultsMetadataResponse = await getTasksMetadata({
      chainId: parseFloat(process.env.REACT_APP_DEFAULT_NETWORK || '5') || 5,
      tasks: metadataArray,
    });
    const resultsMetadata = await resultsMetadataResponse.json();
    rawTasks = rawTasks.map(task => {
      return { ...task, ...resultsMetadata[task.id] };
    });
    const tasks = rawTasks.map(rawTask => buildTask(rawTask));
    const finalResult: {
      applied: IOpportunity[];
      explore: IOpportunity[];
      inProgress: IOpportunity[];
      completed: IOpportunity[];
      bookmarked: IOpportunity[];
    } = {
      applied: [],
      explore: [],
      inProgress: [],
      completed: [],
      bookmarked: [],
    };
    tasks.forEach(task => {
      if (task.status === 'Available') {
        finalResult.explore.push(task);
      } else if (task.status === 'In Progress') {
        finalResult.inProgress.push(task);
      } else if (task.status === 'Completed') {
        finalResult.completed.push(task);
      }
    });

    setOpportunities(finalResult);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        await fetchOpportunities();
      } finally {
        setLoading(false);
      }
    })();
    //eslint-disable-next-line
  }, [loggedInUser, chainId]);

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFilter = (val: any, type: string) => {
    const originalList =
      type === 'category'
        ? categoryFilters
        : type === 'reward'
        ? rewardFilters
        : type === 'coin'
        ? coinFilters
        : type === 'applicants'
        ? applicantsFilters
        : type === 'experience'
        ? experienceFilters
        : timeFilters;
    const setList =
      type === 'category'
        ? setCategoryFilters
        : type === 'reward'
        ? setRewardFilters
        : type === 'coin'
        ? setCoinFilters
        : type === 'applicants'
        ? setApplicantsFilters
        : type === 'experience'
        ? setExperienceFilters
        : setTimeFilters;
    const newFilters = originalList.slice();
    //@ts-ignore
    const searchFilters = val.label ? newFilters.map(f => f.label) : newFilters;
    const existingFilterIndex = searchFilters.indexOf(
      val.label ? val.label : val,
    );
    if (existingFilterIndex !== -1) {
      newFilters.splice(existingFilterIndex, 1);
      //@ts-ignore
      setList(newFilters);
    } else {
      setList([...newFilters, val]);
    }
  };

  const currentOpportunities =
    selectedSearchTab === 'Search'
      ? opportunities?.explore
      : selectedSearchTab === 'Applied'
      ? opportunities?.applied
      : opportunities?.bookmarked;

  const filteredOpportunities = searchText
    ? currentOpportunities?.filter(
        opp => opp.title.toLowerCase().search(searchText.toLowerCase()) !== -1,
      )
    : currentOpportunities;

  const filteredCategoryOpportunities =
    categoryFilters?.length > 0
      ? filteredOpportunities?.filter(opp =>
          categoryFilters.find(category => opp.team === category),
        )
      : filteredOpportunities;

  const filteredRewardOpportunities =
    rewardFilters?.length > 0
      ? filteredCategoryOpportunities?.filter(opp => {
          const reward = rewardFilters.find(
            filter =>
              opp.fee >= filter.lowerLimit && opp.fee <= filter.upperLimit,
          );
          return reward;
        })
      : filteredCategoryOpportunities;

  const filteredCoinOpportunities =
    coinFilters?.length > 0
      ? filteredRewardOpportunities?.filter(opp =>
          coinFilters.find(coin => opp.coin === coin.replace('$', '')),
        )
      : filteredRewardOpportunities;

  const filteredApplicantsOpportunities =
    applicantsFilters?.length > 0
      ? filteredCoinOpportunities?.filter(opp => {
          const applicants = applicantsFilters.find(
            applicant =>
              opp.opportunityApplicants?.length >= applicant.lowerLimit &&
              opp.opportunityApplicants?.length <= applicant.upperLimit,
          );
          return applicants;
        })
      : filteredCoinOpportunities;

  const filteredExperienceOpportunities =
    experienceFilters?.length > 0
      ? filteredApplicantsOpportunities?.filter(opp =>
          experienceFilters.find(
            experience => opp.expectedExperience === experience,
          ),
        )
      : filteredApplicantsOpportunities;

  const finalOpportunities = filteredExperienceOpportunities?.sort((a, b) =>
    filterSelect.value === 'newest'
      ? //@ts-ignore
        new Date(b.createdAt) - new Date(a.createdAt)
      : //@ts-ignore
        new Date(a.createdAt) - new Date(b.createdAt),
  );

  return (
    <MainLayout loading={loading} title={'Opportunities'}>
      <div className={styles.container}>
        <div style={{ width: '100%' }}>
          <div className={styles.searchTabsContainer}>
            {searchTabs.map((tab, i) => (
              <div
                key={`${tab}-${i}`}
                className={styles.searchTab}
                style={{
                  color: tab === selectedSearchTab ? '#fff' : '#A4A6AB',
                  fontWeight: tab === selectedSearchTab ? 400 : 300,
                  borderBottom:
                    tab === selectedSearchTab ? '1px solid #fff' : 'none',
                }}
                onClick={() => setSelectedSearchTab(tab)}
              >
                {tab}
              </div>
            ))}
          </div>
          {selectedSearchTab === 'Search' && (
            <div className={styles.searchInputContainer}>
              <div className={styles.searchInput}>
                <div className={styles.searchInputIcon}>
                  <img src={searchIcon} alt="search" />
                </div>
                <input
                  className={styles.searchInputText}
                  placeholder={'Search'}
                  value={searchText}
                  onChange={e => setSearchText(e.target.value)}
                />
              </div>
              <div className={styles.filterSelect}>
                <FilterSelect
                  options={filterSelectOptions}
                  value={filterSelect}
                  onChange={val => setFilterSelect(val)}
                />
              </div>
            </div>
          )}
          {selectedSearchTab === 'Search' && (
            <div className={styles.amount}>
              {finalOpportunities?.length || 0} opportunit
              {finalOpportunities?.length !== 1 ? 'ies' : 'y'} found
            </div>
          )}
          <div
            className={styles.opportunitiesContainer}
            style={{
              marginTop: selectedSearchTab === 'Search' ? '16px' : '56px',
            }}
          >
            {finalOpportunities?.map((opp, i) => (
              <OpportunityCard
                opportunity={opp}
                key={`${opp.id}-${i}`}
                style={{ marginBottom: '16px' }}
              />
            ))}
          </div>
        </div>
        <div style={{ width: '310px', flexShrink: 0, marginLeft: '40px' }}>
          <div className={styles.filtersTitleContainer}>
            <div className={styles.filterTitle}>
              {selectedSearchTab === 'Search'
                ? 'Filter By'
                : selectedSearchTab === 'Applied'
                ? 'Applied opportunities'
                : 'Saved opportunities'}
            </div>
            <div
              className={styles.clear}
              onClick={() => {
                setCategoryFilters([]);
                setRewardFilters([]);
                setCoinFilters([]);
                setApplicantsFilters([]);
                setExperienceFilters([]);
                setTimeFilters([]);
              }}
            >
              {selectedSearchTab === 'Search' && 'Clear All'}
            </div>
          </div>
          {selectedSearchTab === 'Applied' ? (
            <div className={styles.filtersSubtitle}>
              {opportunities?.applied?.length === 0
                ? `You haven't applied to any opportunities.`
                : `You applied to ${opportunities?.applied?.length} opportunit${
                    opportunities?.applied?.length !== 1 ? 'ies' : 'y'
                  }.`}
            </div>
          ) : selectedSearchTab === 'Saved' ? (
            <div className={styles.filtersSubtitle}>
              {opportunities?.bookmarked?.length === 0
                ? `You haven't saved any opportunities.`
                : `You saved ${opportunities?.bookmarked?.length} opportunit${
                    opportunities?.bookmarked?.length !== 1 ? 'ies' : 'y'
                  }.`}
            </div>
          ) : (
            <div>
              <div>
                <div className={styles.filterCategoryContainer}>
                  <div className={styles.filterCategoryTitle}>Category</div>
                  <div
                    className={styles.clear}
                    onClick={() => setCategoryFilters([])}
                  >
                    Clear
                  </div>
                </div>
                <div className={styles.filterPillsContainer}>
                  {taskFilters.map((filter, i) => (
                    <FilterPill
                      style={{ marginRight: '8px', marginBottom: '16px' }}
                      key={`${filter.name}-${i}`}
                      text={filter.name}
                      onChange={val => onFilter(val, 'category')}
                      active={
                        categoryFilters.find(
                          aFilter => aFilter === filter.name,
                        ) !== undefined
                      }
                      activeColor={filter.color}
                    />
                  ))}
                </div>
              </div>
              <div>
                <div className={styles.filterCategoryContainer}>
                  <div className={styles.filterCategoryTitle}>Reward</div>
                  <div
                    className={styles.clear}
                    onClick={() => setRewardFilters([])}
                  >
                    Clear
                  </div>
                </div>
                <div className={styles.filterPillsContainer}>
                  {taskRewards.map((filter, i) => (
                    <FilterPill
                      style={{ marginRight: '8px', marginBottom: '16px' }}
                      key={`${filter.label}-${i}`}
                      text={filter.label}
                      onChange={val =>
                        onFilter(
                          taskRewards.find(bFilter => val === bFilter.label),
                          'reward',
                        )
                      }
                      active={
                        rewardFilters.find(
                          //@ts-ignore
                          aFilter => aFilter.label === filter.label,
                        ) !== undefined
                      }
                    />
                  ))}
                </div>
              </div>
              <div>
                <div className={styles.filterCategoryContainer}>
                  <div className={styles.filterCategoryTitle}>Coin</div>
                  <div
                    className={styles.clear}
                    onClick={() => setCoinFilters([])}
                  >
                    Clear
                  </div>
                </div>
                <div className={styles.filterPillsContainer}>
                  {taskCoins.map((filter, i) => (
                    <FilterPill
                      style={{ marginRight: '8px', marginBottom: '16px' }}
                      key={`${filter}-${i}`}
                      text={filter}
                      onChange={val => onFilter(val, 'coin')}
                      active={
                        coinFilters.find(aFilter => aFilter === filter) !==
                        undefined
                      }
                    />
                  ))}
                </div>
              </div>
              <div>
                <div className={styles.filterCategoryContainer}>
                  <div className={styles.filterCategoryTitle}>Applicants</div>
                  <div
                    className={styles.clear}
                    onClick={() => setApplicantsFilters([])}
                  >
                    Clear
                  </div>
                </div>
                <div className={styles.filterPillsContainer}>
                  {taskApplicants.map((filter, i) => (
                    <FilterPill
                      style={{ marginRight: '8px', marginBottom: '16px' }}
                      key={`${filter.label}-${i}`}
                      text={filter.label}
                      onChange={val =>
                        onFilter(
                          taskApplicants.find(bFilter => val === bFilter.label),
                          'applicants',
                        )
                      }
                      active={
                        applicantsFilters.find(
                          //@ts-ignore
                          aFilter => aFilter.label === filter.label,
                        ) !== undefined
                      }
                    />
                  ))}
                </div>
              </div>
              <div>
                <div className={styles.filterCategoryContainer}>
                  <div className={styles.filterCategoryTitle}>Experience</div>
                  <div
                    className={styles.clear}
                    onClick={() => setExperienceFilters([])}
                  >
                    Clear
                  </div>
                </div>
                <div className={styles.filterPillsContainer}>
                  {expertiseOptions.map((filter, i) => (
                    <FilterPill
                      style={{ marginRight: '8px', marginBottom: '16px' }}
                      key={`${filter.value}-${i}`}
                      text={filter.value}
                      onChange={val => onFilter(val, 'experience')}
                      active={
                        experienceFilters.find(
                          aFilter => aFilter === filter.value,
                        ) !== undefined
                      }
                    />
                  ))}
                </div>
              </div>
              {/*<div>
                <div className={styles.filterCategoryContainer}>
                  <div className={styles.filterCategoryTitle}>
                    Time Estimation
                  </div>
                  <div className={styles.clear}>Clear</div>
                </div>
                <div className={styles.filterPillsContainer}>
                  {availabilityOptions.map((filter, i) => (
                    <FilterPill
                      style={{ marginRight: '8px', marginBottom: '16px' }}
                      key={`${filter}-${i}`}
                      text={filter.label}
                      onChange={val => onFilter(val, 'time')}
                      active={
                        timeFilters.find(
                          aFilter => aFilter === filter.label,
                        ) !== undefined
                      }
                    />
                  ))}
                </div>
                    </div>*/}
            </div>
          )}
        </div>
      </div>
    </MainLayout>
  );
}
