import { RequestTypes, serverRequest } from './serverRequest';

export const uploadFiles = async (
  files: FileList | File[],
): Promise<unknown> => {
  if (!files.length) return [];

  const formData = new FormData();
  for (let i = 0; i < files.length; ++i) {
    formData.append(files[i].name, files[i]);
  }

  const response = await serverRequest(
    'storage/upload',
    RequestTypes.Post,
    formData,
    {},
    false,
  );

  if (response.status !== 200 && response.status !== 201) {
    throw new Error('Unknown server error while pinning File to IPFS');
  }
  const result = await response.json();
  return result;
};

export const uploadJson = async (
  body: Record<string, unknown>,
): Promise<unknown> => {
  if (!(typeof body === 'object'))
    throw new Error('body is not a valid JSON object');

  const response = await serverRequest('storage/json', RequestTypes.Post, body);
  if (response.status !== 200 && response.status !== 201) {
    throw new Error('Unknown server error while pinning JSON to IPFS');
  }
  const result = await response.json();
  return result;
};
