import { RequestTypes, serverRequest } from './serverRequest';
import { IUserData } from './usersApi';

export type IOpportunityTimeline = {
  content: { message: string };
  createdAt: string;
  daoId: number;
  id: number;
  kind: string;
  title: string;
  updatedAt: string;
  userId: string;
};

export interface IOpportunity {
  id: number;
  activities: IOpportunityTimeline[];
  daoId: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cancellation: any;
  user: IUserData;
  status: string;
  title: string;
  description: string;
  content: string;
  applied: boolean;
  team: string;
  bookmarked: boolean;
  deadline: string;
  expectedExperience: string;
  timeEstimateValue: string;
  timeEstimateUnit: string;
  tags: string[];
  fee: number;
  coin: string;
  createdAt: string;
  updatedAt: string;
  discord: string;
  sourceUrl: string;
  source: string;
  uploadedFiles: string[];
  submitMessage: string;
  feeInUsd: number;
  reviewer: IUserData;
  dao: {
    id: number;
    name: string;
    slug: string;
    logo: string;
    createdAt: string;
    updatedAt: string;
  };
  opportunityApplicants: {
    userId: number;
    wallet: string;
    discord: string;
    discordId: string;
    discordAvatar: string;
    contributions: string[];
    experience: string;
    availability: string;
    username: string;
    status: string;
    user: IUserData;
    createdAt: string;
    daoId: string;
    discordChannelId: string;
    discordMessageId: string;
    id: number;
    opportunityId: number;
    updatedAt: string;
  }[];
}

export interface IActivity {
  title: string;
  content: string;
  image: string;
  wallet: string;
  date: string;
  target: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    author: { id: any; kind: string };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    content: { id: any; kind: string };
  };
}

export interface IAnalytics {
  weekly: AnalyticsObject;
  monthly: AnalyticsObject;
  annually: AnalyticsObject;
  overall: AnalyticsObject;
}

export interface AnalyticsObject {
  newContributors: number;
  tasksInProgress: number;
  tasksCompleted: number;
  feesEarned: number;
  feesPaid: number;
}

export const getOpportunities = async (): Promise<Response> => {
  return serverRequest('contributor-platform/opportunities');
};

export const getTaskMetadata = async ({
  taskSubgraphId,
  chainId,
  reviewerAddress,
  contributorAddress,
}: {
  taskSubgraphId: string;
  chainId: number;
  reviewerAddress: string;
  contributorAddress: string;
}): Promise<Response> => {
  return serverRequest(
    `contributor-platform/opportunities/${taskSubgraphId}/metadata?chainId=${chainId}&reviewerAddress=${reviewerAddress}&contributorAddress=${contributorAddress}`,
  );
};

export const getTasksMetadata = async ({
  chainId,
  tasks,
}: {
  chainId: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tasks: any;
}): Promise<Response> => {
  return serverRequest(
    `contributor-platform/opportunities/metadata?chainId=${chainId}${tasks.map(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (task: any, i: number) => {
        return `&ids[${i}][subgraphId]=${task.subgraphId}&ids[${i}][reviewerAddress]=${task.reviewerAddress}&ids[${i}][contributorAddress]=${task.contributorAddress}`;
      },
    )}`,
  );
};

export const getDAOOpportunities = async (id: number): Promise<Response> => {
  return serverRequest(`dao-platform/opportunities?daoId=${id}`);
};

export const getOpportunity = async (id: string): Promise<Response> => {
  return serverRequest(`contributor-platform/opportunities/${id}`);
};

export const bookmarkOpportunity = async (
  subgraphId: string,
  data: { chainId: number; contractAddress: string },
): Promise<Response> => {
  return serverRequest(
    `contributor-platform/opportunities/${subgraphId}/bookmark`,
    RequestTypes.Post,
    data,
  );
};

export const unbookmarkOpportunity = async (
  subgraphId: string,
  data: { chainId: number; contractAddress: string },
): Promise<Response> => {
  return serverRequest(
    `contributor-platform/opportunities/${subgraphId}/unbookmark`,
    RequestTypes.Post,
    data,
  );
};

export const getDAOOpportunity = async (id: string): Promise<Response> => {
  return serverRequest(`dao-platform/opportunities/${id}`);
};

export const postSyncNotionDAOOpportunity = async (
  id: string,
): Promise<Response> => {
  return serverRequest(
    `dao-platform/opportunities/${id}/sync`,
    RequestTypes.Post,
  );
};

export const postImportNotionDAOOpportunities = async (
  daoId: number,
): Promise<Response> => {
  return serverRequest(`dao-platform/opportunities/import`, RequestTypes.Post, {
    daoId,
  });
};

export const applyOpportunity = async ({
  taskSubgraphId,
  message,
  chainId,
  contractAddress,
  signature,
  encodedData,
}: {
  taskSubgraphId: string;
  message: string;
  chainId: number;
  contractAddress: string;
  signature: string;
  encodedData: string;
}): Promise<Response> => {
  return serverRequest(
    `contributor-platform/opportunities/${taskSubgraphId}/applicants`,
    RequestTypes.Post,
    { message, chainId, contractAddress, signature, encodedData },
  );
};

export const assignOpportunity = async ({
  opportunityId,
  applicantId,
}: {
  opportunityId: number;
  applicantId: number;
}): Promise<Response> => {
  return serverRequest(
    `dao-platform/opportunities/${opportunityId}/applicants/assign`,
    RequestTypes.Post,
    { userId: applicantId },
  );
};

export const unassignOpportunity = async ({
  opportunityId,
  applicantId,
}: {
  opportunityId: number;
  applicantId: number;
}): Promise<Response> => {
  return serverRequest(
    `dao-platform/opportunities/${opportunityId}/applicants/unassign`,
    RequestTypes.Post,
    { userId: applicantId },
  );
};

export const submitForReview = async ({
  taskSubgraphId,
  message,
  uploadedFiles,
  chainId,
  contractAddress,
  signature,
  encodedData,
}: {
  taskSubgraphId: string;
  message?: string;
  uploadedFiles?: string[];
  chainId: number;
  contractAddress: string;
  signature: string;
  encodedData: string;
}): Promise<Response> => {
  return serverRequest(
    `contributor-platform/opportunities/${taskSubgraphId}/submit`,
    RequestTypes.Post,
    {
      message,
      uploadedFiles,
      chainId,
      contractAddress,
      signature,
      encodedData,
    },
  );
};

export const approveOpportunity = async ({
  opportunityId,
}: {
  opportunityId: number;
}): Promise<Response> => {
  return serverRequest(
    `dao-platform/opportunities/${opportunityId}/approve`,
    RequestTypes.Post,
  );
};

export const DAOCancelOpportunity = async ({
  opportunityId,
  contributorWallet,
  reviewerWallet,
  chainId,
  contractAddress,
  subgraphId,
  signature,
  encodedData,
  message,
  initiatedBy,
}: {
  opportunityId: string;
  contributorWallet: string;
  reviewerWallet: string;
  chainId: number;
  contractAddress: string;
  subgraphId: string;
  signature: string;
  encodedData: string;
  message: string;
  initiatedBy: string;
}): Promise<Response> => {
  return serverRequest(
    `dao-platform/opportunities/${opportunityId}/cancel`,
    RequestTypes.Post,
    {
      contributorWallet,
      reviewerWallet,
      chainId,
      contractAddress,
      subgraphId,
      signature,
      encodedData,
      message,
      initiatedBy,
    },
  );
};

export const contributorCancelOpportunity = async ({
  opportunityId,
  contributorWallet,
  reviewerWallet,
  chainId,
  contractAddress,
  subgraphId,
  signature,
  encodedData,
  message,
  initiatedBy,
}: {
  opportunityId: string;
  contributorWallet: string;
  reviewerWallet: string;
  chainId: number;
  contractAddress: string;
  subgraphId: string;
  signature: string;
  encodedData: string;
  message: string;
  initiatedBy: string;
}): Promise<Response> => {
  return serverRequest(
    `contributor-platform/opportunities/${opportunityId}/cancel`,
    RequestTypes.Post,
    {
      contributorWallet,
      reviewerWallet,
      chainId,
      contractAddress,
      subgraphId,
      signature,
      encodedData,
      message,
      initiatedBy,
    },
  );
};

export const contributorCancelOpportunityCombined = async ({
  opportunityId,
  contributorWallet,
  reviewerWallet,
  chainId,
  contractAddress,
  subgraphId,
  signature,
  encodedData,
  message,
}: {
  opportunityId: string;
  contributorWallet: string;
  reviewerWallet: string;
  chainId: number;
  contractAddress: string;
  subgraphId: string;
  signature: string;
  encodedData: string;
  message: string;
}): Promise<Response> => {
  return serverRequest(
    `contributor-platform/opportunities/${subgraphId}/cancel`,
    RequestTypes.Post,
    {
      contributorWallet,
      reviewerWallet,
      chainId,
      contractAddress,
      subgraphId,
      signature,
      encodedData,
      message,
      combinedSignature: true,
    },
  );
};

export const requestEditsOpportunity = async ({
  subgraphTaskId,
  message,
}: {
  subgraphTaskId: string;
  message: string;
}): Promise<Response> => {
  return serverRequest(
    `dao-platform/opportunities/${subgraphTaskId}/reject`,
    RequestTypes.Post,
    { message },
  );
};

export const archiveOpportunity = async ({
  opportunityId,
}: {
  opportunityId: number;
}): Promise<Response> => {
  return serverRequest(
    `dao-platform/opportunities/${opportunityId}/archive`,
    RequestTypes.Post,
  );
};

export const unArchiveOpportunity = async ({
  opportunityId,
}: {
  opportunityId: number;
}): Promise<Response> => {
  return serverRequest(
    `dao-platform/opportunities/${opportunityId}/unarchive`,
    RequestTypes.Post,
  );
};
