import {
  contributorCancelOpportunity,
  contributorCancelOpportunityCombined,
  DAOCancelOpportunity,
  IOpportunity,
} from 'api/opportunitiesApi';
import { uploadJson } from 'api/uploadApi';
import arrowLeft from 'assets/arrowLeft.svg';
import ApplyModal from 'components/ApplyModal';
import { ApplicantProps } from 'components/DAOApplicant';
import DAOOpportunityApplicants from 'components/DAOOpportunityApplicants';
import OpportunityApplicants from 'components/OpportunityApplicants';
import OpportunityOverview from 'components/OpportunityOverview';
import OpportunityTimeline from 'components/OpportunityTimeline';
import ReviewModal from 'components/ReviewModal';
import SubmitReviewModal from 'components/SubmitReviewModal';
import TransactionModal from 'components/TransactionModal';
import { useWallet } from 'contexts/WalletContext';
import { Spinner } from 'design-system';
import { ContractTransaction } from 'ethers';
import { getGlobalInfo } from 'graphql/globalInfo';
import { getTask } from 'graphql/tasks';
import { useShareContract } from 'hooks/useShareContract';
import MainLayout, { isDAO } from 'layouts/MainLayout';
import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { createUseStyles } from 'react-jss';
import { RouteComponentProps } from 'react-router';
import { useHistory } from 'react-router';
import useGlobalState from 'state';
import { buildTask } from 'utils/tasks';
import {
  capitalizeFirstLetter,
  formatToTenths,
  formatToThousandths,
} from 'utils/utils';
import { waitUntilBlock } from 'web3/graphHealth';
import { encodeData, getUserSignature, stringToBytes } from 'web3/helpers';
import { SignatureFunction } from 'web3/metadata';

import OpportunityOverviewSidebar from '../components/OpportunitySidebar';

const tabs = ['Overview', 'Applicants', 'Timeline'];

export const OpportunityStatuses = [
  {
    key: 'Available',
    name: 'Available',
    color: '#673969',
    secondaryColor: '#2A2639',
  },
  {
    key: 'InProgress',
    name: 'In Progress',
    color: '#BB7800',
    secondaryColor: '#342F2C',
  },
  {
    key: 'InReview',
    name: 'In Review',
    color: '#9D6027',
    secondaryColor: '#302928',
  },
  { key: 'Completed', name: 'Completed', color: '#2B8C34' },
  { key: 'Paid', name: 'Completed', color: '#C1D5EF' },
  { key: 'Archived', name: 'Archived', color: '#BBBCC0' },
  { key: 'Cancelled', name: 'Cancelled', color: '#FF454C' },
];

const useStyles = createUseStyles({
  container: { display: 'flex', alignItems: 'flex-start', color: '#fff' },
  contentContainer: {
    width: '100%',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleContainer: { display: 'flex', alignItems: 'center' },
  title: { fontSize: '24px', marginLeft: '8px' },
  currencyPrimary: { fontSize: '24px', marginRight: '8px' },
  currencySecondary: { fontSize: '14px', color: '#767A81' },
  tabsContainer: {
    display: 'flex',
    borderBottom: '1px solid #303B5B',
    marginTop: '48px',
  },
  tab: { paddingBottom: '24px', marginRight: '32px', cursor: 'pointer' },
  status: {
    marginLeft: '16px',
    padding: '6px 10px',
    borderRadius: '4px',
    color: '#fff',
    fontSize: '14px',
  },
});

export default function OpportunityView({
  match,
}: RouteComponentProps<{ id: string }>): JSX.Element {
  const { loggedInUser } = useGlobalState();
  const DAOuser = isDAO(loggedInUser);
  const history = useHistory();
  const styles = useStyles();
  const { shareConfig, chainId, provider, address } = useWallet();
  const [opportunity, setOpportunity] = useState<IOpportunity | null>(null);
  const [loading, setLoading] = useState(false);
  const [applying, setApplying] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [reviewing, setReviewing] = useState(false);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [assigning, setAssigning] = useState(false);
  const [cancelling, setCancelling] = useState(false);
  const [transaction, setTransaction] = useState<ContractTransaction | null>(
    null,
  );

  const contract = useShareContract();

  /*const onSyncNotion = async () => {
    setSyncingNotion(true);
    await postSyncNotionDAOOpportunity(match.params.id);
    await fetchOpportunity(match.params.id);
    setSyncingNotion(false);
  };*/

  const onApproveCancel = async () => {
    setCancelling(true);
    if (
      opportunity &&
      opportunity.cancellation &&
      chainId &&
      provider &&
      contract
    ) {
      if (address === opportunity.cancellation.reviewerWallet) {
        if (opportunity.cancellation.combinedSignature) {
          try {
            const tx = await contract.cancelTask(
              //@ts-ignore
              opportunity.cancellation.encodedData,
              opportunity.cancellation.signature,
            );
            setTransaction(tx);
            const receipt = await tx.wait(1);
            const success = await waitUntilBlock(receipt.blockNumber);
            if (success) {
              toast.success('Opportunity cancelled!');
              setTransaction(null);
              await initPage();
              setCancelling(false);
              //history.push(ManageLocation);
            } else {
              toast.error('Transaction failed');
              setTransaction(null);
              setCancelling(false);
            }
          } catch (e) {
            toast.error('Error approving cancellation transaction');
            setTransaction(null);
            setCancelling(false);
          }
        } else {
          try {
            const reviewerSignature = await getUserSignature(
              //@ts-ignore
              opportunity.cancellation.encodedData,
              provider,
            );
            const combinedSignature =
              //@ts-ignore
              reviewerSignature + opportunity.cancellation.signature.slice(2);
            try {
              const tx = await contract.cancelTask(
                //@ts-ignore
                opportunity.cancellation.encodedData,
                combinedSignature,
              );
              setTransaction(tx);
              const receipt = await tx.wait(1);
              const success = await waitUntilBlock(receipt.blockNumber);
              if (success) {
                toast.success('Opportunity cancelled!');
                setTransaction(null);
                await initPage();
                setCancelling(false);
                //history.push(ManageLocation);
              } else {
                toast.error('Transaction failed');
                setTransaction(null);
                setCancelling(false);
              }
            } catch (e) {
              toast.error('Error approving cancellation transaction');
              setTransaction(null);
              setCancelling(false);
            }
          } catch (e) {
            toast.error('Error approving cancellation signature');
            setCancelling(false);
          }
        }
      } else if (
        address === opportunity.cancellation.contributorWallet &&
        shareConfig &&
        address &&
        opportunity.reviewer.wallet
      ) {
        try {
          const contributorSignature = await getUserSignature(
            //@ts-ignore
            opportunity.cancellation.encodedData,
            provider,
          );
          const combinedSignature =
            //@ts-ignore
            opportunity.cancellation.signature + contributorSignature.slice(2);
          try {
            const response = await contributorCancelOpportunityCombined({
              opportunityId: `${shareConfig?.escrowAddress.toLowerCase()}-0x${match.params.id.toString()}`,
              chainId,
              subgraphId: `${shareConfig?.escrowAddress.toLowerCase()}-0x${match.params.id.toString()}`,
              message: 'Placeholder Text',
              signature: combinedSignature,
              encodedData: opportunity.cancellation.encodedData,
              contractAddress: shareConfig.escrowAddress,
              reviewerWallet: opportunity.reviewer.wallet,
              contributorWallet: address,
            });
            const data = await response.json();
            if (data.statusCode && data.statusCode === 500) {
              toast.error('Error cancelling opportunity');
              setCancelling(false);
            } else {
              toast.success('Cancellation Approved!');
              await initPage();
              setCancelling(false);
            }
          } catch (e) {
            toast.error('Error cancelling opportunity');
            setCancelling(false);
          }
        } catch (e) {
          toast.error('Error approving cancellation signature');
          setCancelling(false);
        }
      }
    }
  };

  const onCancel = async () => {
    setCancelling(true);
    if (opportunity && chainId && provider && contract) {
      if (opportunity.status === 'Available') {
        const ipfsData = {
          message: 'Placeholder Text',
          timestamp: new Date().getTime() / 1000,
        };

        try {
          //@ts-ignore
          const { ipfsHash } = await uploadJson(ipfsData);

          const offChainBytes = stringToBytes(ipfsHash);

          const data = {
            types: ['uint8', 'address', 'uint256', 'uint256', 'bytes'],
            values: [
              SignatureFunction.CancelTask,
              shareConfig?.escrowAddress,
              chainId,
              opportunity.id,
              offChainBytes,
            ],
          };
          const encodedData = encodeData(data);
          try {
            const reviewerSignature = await getUserSignature(
              encodedData,
              provider,
            );
            try {
              const tx = await contract.cancelTask(
                //@ts-ignore
                encodedData,
                reviewerSignature,
              );
              setTransaction(tx);
              const receipt = await tx.wait(1);
              const success = await waitUntilBlock(receipt.blockNumber);
              if (success) {
                toast.success('Opportunity cancelled!');
                setTransaction(null);
                await initPage();
                setCancelling(false);
                //history.push(ManageLocation);
              } else {
                toast.error('Transaction failed');
                setTransaction(null);
                setCancelling(false);
              }
            } catch (e) {
              toast.error('Transaction failed');
              setTransaction(null);
              setCancelling(false);
            }
          } catch (e) {
            toast.error('Signature rejected');
            setCancelling(false);
          }
        } catch (e) {
          toast.error('Error uploading metadata');
          setCancelling(false);
        }
      } else if (DAOuser && shareConfig && address && opportunity.user.wallet) {
        try {
          const ipfsData = {
            message: 'Placeholder Text',
            timestamp: new Date().getTime() / 1000,
          };
          //@ts-ignore
          const { ipfsHash } = await uploadJson(ipfsData);

          const offChainBytes = stringToBytes(ipfsHash);

          const data = {
            types: ['uint8', 'address', 'uint256', 'uint256', 'bytes'],
            values: [
              SignatureFunction.CancelTask,
              shareConfig?.escrowAddress,
              chainId,
              opportunity.id,
              offChainBytes,
            ],
          };
          const encodedData = encodeData(data);

          const reviewerSignature = await getUserSignature(
            encodedData,
            provider,
          );
          try {
            const response = await DAOCancelOpportunity({
              opportunityId: `${shareConfig?.escrowAddress.toLowerCase()}-0x${match.params.id.toString()}`,
              chainId,
              subgraphId: `${shareConfig?.escrowAddress.toLowerCase()}-0x${match.params.id.toString()}`,
              message: 'Placeholder Text',
              signature: reviewerSignature,
              encodedData: encodedData,
              contractAddress: shareConfig.escrowAddress,
              reviewerWallet: address,
              contributorWallet: opportunity.user.wallet,
              initiatedBy: address,
            });
            const data = await response.json();
            if (data.statusCode && data.statusCode === 500) {
              toast.error('Error cancelling opportunity');
              setCancelling(false);
            } else {
              toast.success('Cancellation requested!');
              await initPage();
              setCancelling(false);
            }
          } catch (e) {
            toast.error('Error cancelling opportunity');
            setCancelling(false);
          }
        } catch (e) {
          toast.error('Error cancelling opportunity');
          setCancelling(false);
        }
      } else if (
        address === opportunity.user.wallet &&
        shareConfig &&
        address &&
        opportunity.reviewer.wallet
      ) {
        try {
          const ipfsData = {
            message: 'Placeholder Text',
            timestamp: new Date().getTime() / 1000,
          };
          //@ts-ignore
          const { ipfsHash } = await uploadJson(ipfsData);

          const offChainBytes = stringToBytes(ipfsHash);

          const data = {
            types: ['uint8', 'address', 'uint256', 'uint256', 'bytes'],
            values: [
              SignatureFunction.CancelTask,
              shareConfig?.escrowAddress,
              chainId,
              match.params.id,
              offChainBytes,
            ],
          };
          const encodedData = encodeData(data);

          const contributorSignature = await getUserSignature(
            encodedData,
            provider,
          );
          try {
            const response = await contributorCancelOpportunity({
              opportunityId: `${shareConfig?.escrowAddress.toLowerCase()}-0x${match.params.id.toString()}`,
              chainId,
              subgraphId: `${shareConfig?.escrowAddress.toLowerCase()}-0x${match.params.id.toString()}`,
              message: 'Placeholder Text',
              signature: contributorSignature,
              encodedData: encodedData,
              contractAddress: shareConfig.escrowAddress,
              reviewerWallet: opportunity.reviewer.wallet,
              contributorWallet: address,
              initiatedBy: address,
            });
            const data = await response.json();
            if (data.statusCode && data.statusCode === 500) {
              toast.error('Error cancelling opportunity');
              setCancelling(false);
            } else {
              toast.success('Cancellation requested!');
              await initPage();
              setCancelling(false);
            }
          } catch (e) {
            toast.error('Error cancelling opportunity');
            setCancelling(false);
          }
        } catch (e) {
          toast.error('Error cancelling opportunity');
          setCancelling(false);
        }
      }
    }
    /*toUnarchive
      ? await unArchiveOpportunity({
          opportunityId: parseFloat(match.params.id),
        })
      : await archiveOpportunity({
          opportunityId: parseFloat(match.params.id),
        });
    await fetchOpportunity(match.params.id);*/
    setCancelling(false);
  };

  const fetchOpportunity = async (id: string) => {
    // const data = DAOuser
    //   ? await getDAOOpportunity(id)
    //   : await getOpportunity(id);
    // const result = await data.json();
    let config = shareConfig;
    if (!config) {
      setLoading(true);
      config = await getGlobalInfo();
    }
    const result =
      config && (await getTask(config.escrowAddress, +id, chainId || 5));
    const task = result && buildTask(result);
    setLoading(false);
    setOpportunity(task);
  };

  const initPage = useCallback(async () => {
    setLoading(true);
    try {
      await fetchOpportunity(match.params.id);
    } finally {
      setLoading(false);
    }
    //eslint-disable-next-line
  }, [match.params.id, loggedInUser]);

  useEffect(() => {
    initPage();
  }, [match, initPage]);

  const toggleAssign = async (applicantData: ApplicantProps) => {
    //const status = applicantData.status;
    if (opportunity && applicantData && provider && contract) {
      setAssigning(true);
      //@ts-ignore
      try {
        const reviewerSignature = await getUserSignature(
          //@ts-ignore
          applicantData.encodedData,
          provider,
        );

        const combinedSignature =
          //@ts-ignore
          reviewerSignature + applicantData.signature.slice(2);

        try {
          const tx = await contract.assignTask(
            //@ts-ignore
            applicantData.encodedData,
            combinedSignature,
          );
          setTransaction(tx);
          const receipt = await tx.wait(1);
          const success = await waitUntilBlock(receipt.blockNumber);
          if (success) {
            toast.success('Task assigned!');
            setAssigning(false);
            setTransaction(null);
            //history.push(ManageLocation);
          } else {
            toast.error('Transaction failed');
            setTransaction(null);
            setAssigning(false);
          }
        } catch (e) {
          toast.error('Transaction failed');
          setTransaction(null);
          setAssigning(false);
        }
      } catch (e) {
        toast.error('Signature Rejected');
        setAssigning(false);
      }
      await initPage();
      setAssigning(false);
    }
  };

  const currentStatus = OpportunityStatuses?.find(
    d => capitalizeFirstLetter(d.key) === opportunity?.status,
  );

  return (
    <MainLayout title={'Opportunities'} loading={loggedInUser && loading}>
      <TransactionModal open={transaction !== null} transaction={transaction} />
      <ApplyModal
        open={applying}
        onClose={reload => {
          if (reload) {
            initPage();
          }
          setApplying(false);
        }}
        opportunityId={opportunity?.id}
      />
      <SubmitReviewModal
        open={submitting}
        onClose={reload => {
          if (reload) {
            initPage();
          }
          setSubmitting(false);
        }}
        opportunityId={opportunity?.id}
      />
      {opportunity && (
        <ReviewModal
          open={reviewing}
          onClose={reload => {
            if (reload) {
              initPage();
            }
            setReviewing(false);
          }}
          opportunity={opportunity}
        />
      )}
      <div className={styles.container}>
        {loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '64px',
              width: '100%',
            }}
          >
            <Spinner />
          </div>
        ) : (
          <>
            <div className={styles.contentContainer}>
              <div className={styles.headerContainer}>
                <div className={styles.titleContainer}>
                  <img
                    src={arrowLeft}
                    alt="back"
                    style={{ cursor: 'pointer' }}
                    onClick={() => history.goBack()}
                  />
                  <div className={styles.title}>{opportunity?.title}</div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexShrink: 0,
                  }}
                >
                  <div
                    className={styles.status}
                    style={{
                      backgroundColor: currentStatus?.color || '#673969',
                      marginRight: '16px',
                      marginBottom: '4px',
                    }}
                  >
                    {currentStatus?.name}
                  </div>
                  <span className={styles.currencyPrimary}>
                    {opportunity && formatToThousandths(opportunity.fee)}{' '}
                    {opportunity?.coin}
                  </span>
                  <span className={styles.currencySecondary}>
                    $
                    {opportunity?.feeInUsd &&
                      formatToTenths(opportunity?.feeInUsd)}
                  </span>
                </div>
              </div>
              <div className={styles.tabsContainer}>
                {tabs.map((tab, i) => (
                  <div
                    key={`${tab}-${i}`}
                    style={{
                      color: selectedTab === tab ? '#F9F9F9' : '#A4A6AB',
                      borderBottom:
                        selectedTab === tab ? '1px solid #F9F9F9' : 'none',
                    }}
                    className={styles.tab}
                    onClick={() => setSelectedTab(tab)}
                  >
                    {tab}
                  </div>
                ))}
              </div>
              {selectedTab === 'Overview' ? (
                <OpportunityOverview opportunity={opportunity} />
              ) : selectedTab === 'Applicants' ? (
                DAOuser ? (
                  <DAOOpportunityApplicants
                    opportunity={opportunity}
                    assigning={assigning}
                    toggleAssign={toggleAssign}
                  />
                ) : (
                  <OpportunityApplicants opportunity={opportunity} />
                )
              ) : (
                <div style={{ marginTop: '40px' }}>
                  {opportunity?.activities && (
                    <OpportunityTimeline activities={opportunity.activities} />
                  )}
                </div>
              )}
            </div>
            <OpportunityOverviewSidebar
              onBookmark={async () => fetchOpportunity(match.params.id)}
              opportunity={opportunity}
              setApplying={setApplying}
              setSubmitting={setSubmitting}
              setReviewing={setReviewing}
              cancelling={cancelling}
              onCancel={onCancel}
              onApproveCancel={onApproveCancel}
            />
          </>
        )}
      </div>
    </MainLayout>
  );
}
