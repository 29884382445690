import loadingIcon from 'assets/loading.svg';
import React, { CSSProperties } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  spinner: {
    animation: '$rotate 1.5s infinite',
  },
  '@keyframes rotate': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
});

function Spinner({ style }: { style?: CSSProperties }): JSX.Element {
  const styles = useStyles();
  return (
    <img
      src={loadingIcon}
      alt="loading"
      className={styles.spinner}
      style={style}
    />
  );
}

export default Spinner;
