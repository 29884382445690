export const hover = {
  comment: '#1D2431',
  card: '#242E42',
  stroke: '#2C3C6A',
};
export const dark = {
  purple: '#2A2639',
  yellow: '#342F2C',
  orange: '#302928',
};
export const gray = {
  0: '#F9F9F9',
  100: '#E8E9EA',
  200: '#D1D3D5',
  300: '#BBBCC0',
  400: '#A4A6AB',
  500: '#8D9096',
  600: '#767A81',
  900: '#202837',
  stroke: '#303B5B',
};
export const branding = {
  darkPurple: '#1B212D',
  deepPurple: '#341D35',
  purple: '#673969',
  red: '#AB3D41',
  orange: '#9D6027',
  yellow: '#D6AA3C',
  blue: '#C1D5EF',
};
export const skills = {
  design: '#5840BA',
  marketing: '#7E2A4C',
  developer: '#BB7800',
  social: '#92446D',
  finance: '#3135A5',
  community: '#9D5716',
  operations: '#652556',
  product: '#7833B9',
};
export const red = {
  100: '#FF454C',
};
export const green = {
  400: '#2B8C34',
};
