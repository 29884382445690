import { IOnboardingMember } from 'api/onboardingApi';
import discordLight from 'assets/discordOnboarding.svg';
import githubLight from 'assets/githubOnboarding.svg';
import twitterLight from 'assets/twitterOnboarding.svg';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    borderRadius: '8px',
    background: 'rgba(249, 249, 249, 0.1)',
    backdropFilter: 'blur(20px)',
    padding: '47px 28px 18px 28px',
    display: 'inline-block',
    marginRight: '16px',
    marginBottom: '56px',
    minWidth: '182px',
    textAlign: 'center',
  },
  avatar: {
    position: 'absolute',
    left: 'calc(50% - 24px)',
    top: '-24px',
    width: '48px',
    height: '48px',
    borderRadius: '48px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  title: { fontSize: '16px', color: '#f9f9f9' },
  subtitle: {
    marginTop: '8px',
    color: 'rgba(249, 249, 249, 0.6)',
    fontSize: '14px',
  },
  socialsContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '16px',
  },
  social: { width: '20px', marginRight: '16px', opacity: 0.5 },
});

export default function OnboardingTeamCard({
  teamMember,
}: {
  teamMember: IOnboardingMember;
}): JSX.Element {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <div
        style={{ backgroundImage: `url(${teamMember.avatar})` }}
        className={styles.avatar}
      />
      <div className={styles.title}>{teamMember.name}</div>
      <div className={styles.subtitle}>{teamMember.title}</div>
      <div className={styles.socialsContainer}>
        {teamMember.discord && (
          <a
            href={`https://discordapp.com/users/${teamMember.discord}`}
            target="_blank"
            rel="noreferrer"
          >
            <img src={discordLight} alt="discord" className={styles.social} />
          </a>
        )}
        {teamMember.twitter && (
          <a
            href={`https://twitter.com/${teamMember.twitter}`}
            target="_blank"
            rel="noreferrer"
          >
            <img src={twitterLight} alt="twitter" className={styles.social} />
          </a>
        )}
        {teamMember.github && (
          <a
            href={`https://github.com/${teamMember.github}`}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={githubLight}
              alt="github"
              className={styles.social}
              style={{ marginRight: 0 }}
            />
          </a>
        )}
      </div>
    </div>
  );
}
