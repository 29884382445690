//import editIcon from 'assets/editFrame.svg';
import { useWallet } from 'contexts/WalletContext';
import makeBlockie from 'ethereum-blockies-base64';
import { createUseStyles } from 'react-jss';
import useGlobalState from 'state';
import { discordAvatarUrl } from 'utils/utils';

const useStyles = createUseStyles({
  title: { fontSize: '20px', marginBottom: '8px' },
  description: {
    maxWidth: '490px',
    color: '#D1D3D5',
    fontSize: '16px',
    lineHeight: '21.6px',
  },
  inputTitle: { fontSize: '14px', marginBottom: '8px' },
  editImage: {
    width: '80px',
    height: '80px',
    position: 'relative',
    //cursor: 'pointer',
  },
  avatar: { borderRadius: '80px', width: '100%', height: '100%' },
  edit: { position: 'absolute', bottom: 0, right: '-8px' },
  input: {
    background: '#202837',
    border: '1px solid #303B5B',
    borderRadius: '8px',
    padding: '18px 16px',
    fontSize: '16px',
    color: '#f9f9f9',
    width: '100%',
  },
});

export default function OnboardingPersonalInformation({
  name,
  setName,
  bio,
  setBio,
}: {
  name: string;
  setName(name: string): void;
  bio: string;
  setBio(bio: string): void;
}): JSX.Element {
  const styles = useStyles();
  const { address } = useWallet();
  const { loggedInUser } = useGlobalState();

  return (
    <div>
      <div className={styles.title}>Personal info</div>
      <div className={styles.description}>
        Share a bit about yourself so DAOs can get to know you better. This is
        also your time to flex your favorite NFT!
      </div>
      <div
        className={styles.inputTitle}
        style={{ marginTop: '48px', marginBottom: '10px' }}
      >
        Profile picture
      </div>
      <div className={styles.editImage}>
        {address && (
          <>
            <img
              src={
                loggedInUser?.discordAvatar && loggedInUser?.discordId
                  ? discordAvatarUrl(
                      loggedInUser.discordId,
                      loggedInUser.discordAvatar,
                    )
                  : makeBlockie(address?.toLowerCase())
              }
              alt="avatar"
              className={styles.avatar}
            />
            {/*<img src={editIcon} alt="edit" className={styles.edit} />*/}
          </>
        )}
      </div>
      <div className={styles.inputTitle} style={{ marginTop: '26px' }}>
        Username
      </div>
      <input
        className={styles.input}
        value={name}
        onChange={e => setName(e.target.value)}
      />
      <div className={styles.inputTitle} style={{ marginTop: '26px' }}>
        Bio
      </div>
      <textarea
        rows={4}
        className={styles.input}
        value={bio}
        onChange={e => setBio(e.target.value)}
      />
    </div>
  );
}
