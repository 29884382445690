import { ethers } from 'ethers';
import { AbiCoder, arrayify, keccak256 } from 'ethers/lib/utils';

import { EXPLORER_URLS, SUPPORTED_NETWORKS } from './constants';

export const getTransactionUrl = (chainId: number, hash: string): string =>
  `${EXPLORER_URLS[chainId]}/tx/${hash}`;

export const isSupportedChain = (chainId: number): boolean =>
  SUPPORTED_NETWORKS.includes(chainId);

export const timeout = (ms: number): Promise<void> => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

const utf8Encode = new TextEncoder();

export const stringToBytes = (str: string): string =>
  `0x${Buffer.from(utf8Encode.encode(str)).toString('hex')}`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const encodeData = (data: { types: any; values: any }): string => {
  const abiCoder = new AbiCoder();
  return abiCoder.encode(data.types, data.values);
};

export const getUserSignature = async (
  encodedData: string,
  provider: ethers.providers.Web3Provider,
): Promise<string> => {
  const signer = provider.getSigner();
  const encodedMsgHash = keccak256(encodedData);
  const encodedMsgBinary = arrayify(encodedMsgHash);
  return signer.signMessage(encodedMsgBinary);
};
