import { gql, request } from 'graphql-request';

import { DEFAULT_SUBGRAPH_NAME as SUBGRAPH_NAME } from './constants';
import { timeout } from './helpers';

export const GRAPH_STATUS_PAGE = 'https://status.thegraph.com/';

const healthQuery = gql`
  query getGraphHealth($subgraph: String!) {
    health: indexingStatusForCurrentVersion(subgraphName: $subgraph) {
      synced
      health
      fatalError {
        message
        block {
          number
          hash
        }
        handler
      }
      chains {
        chainHeadBlock {
          number
        }
        latestBlock {
          number
        }
      }
    }
  }
`;

export type GraphHealth = {
  isReachable: boolean;
  isFailed: boolean;
  isSynced: boolean;
  latestBlockNumber: number;
  chainHeadBlockNumber: number;
};

const GRAPH_SYNC_THRESHOLD_BLOCKS = 10;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const extractStatus = ({ fatalError, synced, chains }: any): GraphHealth => ({
  isReachable: true,
  isFailed:
    !!fatalError ||
    (!synced &&
      Number(chains[0].chainHeadBlock.number) -
        Number(chains[0].latestBlock.number) >
        GRAPH_SYNC_THRESHOLD_BLOCKS),
  isSynced: synced,
  latestBlockNumber: Number(chains[0].latestBlock.number),
  chainHeadBlockNumber: Number(chains[0].chainHeadBlock.number),
});

export const successStatus: GraphHealth = {
  isReachable: true,
  isFailed: false,
  isSynced: true,
  latestBlockNumber: 0,
  chainHeadBlockNumber: 0,
};

export const failedStatus: GraphHealth = {
  isReachable: false,
  isFailed: true,
  isSynced: false,
  latestBlockNumber: 0,
  chainHeadBlockNumber: 0,
};

const GRAPH_HEALTH_ENDPOINT = 'https://api.thegraph.com/index-node/graphql';

export const getGraphHealth = async (): Promise<GraphHealth> => {
  try {
    const data = await request(GRAPH_HEALTH_ENDPOINT, healthQuery, {
      subgraph: SUBGRAPH_NAME,
    });
    const status = extractStatus(data.health);
    return status;
  } catch (graphHealthError) {
    // eslint-disable-next-line no-console
    console.error('Could not fetch graph health', graphHealthError);
  }

  return failedStatus;
};

const GRAPH_HEALTH_STORAGE_KEY = `share-graph-health-${SUBGRAPH_NAME}`;

export const getCachedSubgraphHealth = (): GraphHealth => {
  const value = window.localStorage.getItem(GRAPH_HEALTH_STORAGE_KEY);
  if (value) return JSON.parse(value) as GraphHealth;
  return failedStatus;
};

export const setCachedSubgraphHealth = (health: GraphHealth): void =>
  window.localStorage.setItem(GRAPH_HEALTH_STORAGE_KEY, JSON.stringify(health));

const GRAPH_POLL_INTERVAL = 5000;
const GRAPH_NUM_RETRIES = 20;

export const waitUntilBlock = async (block: number): Promise<boolean> => {
  let { latestBlockNumber } = getCachedSubgraphHealth();
  let tries = 0;
  while (latestBlockNumber < block && tries < GRAPH_NUM_RETRIES) {
    await timeout(GRAPH_POLL_INTERVAL);
    tries += 1;
    ({ latestBlockNumber } = getCachedSubgraphHealth());
  }
  return latestBlockNumber >= block;
};
