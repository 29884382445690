import { availabilityOptions } from 'api/onboardingApi';
import brainGray from 'assets/brainGray.svg';
import timer from 'assets/timer.svg';
import makeBlockie from 'ethereum-blockies-base64';
import { ProfileLocation } from 'Locations';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import { taskFilters } from 'views/Opportunities';

import { ApplicantProps } from './DAOApplicant';
import Pill from './Pill';

const useStyles = createUseStyles({
  blockie: {
    width: '32px',
    height: '32px',
    borderRadius: '32px',
    marginRight: '16px',
  },
  container: { display: 'flex', alignItems: 'flex-start' },
  username: { color: '#fff', fontSize: '16px', marginRight: '8px' },
  usernameContainer: { display: 'flex', alignItems: 'center' },
  userInfoFooter: {
    display: 'flex',
    alignItems: 'center',
    color: '#BBBCC0',
    marginTop: '12px',
    fontSize: '14px',
  },
  dot: {
    width: '3px',
    height: '3px',
    borderRadius: '3px',
    backgroundColor: '#767A81',
    margin: '0px 12px',
  },
});

export default function ApplicantHeader({
  applicant,
}: {
  applicant: ApplicantProps;
}): JSX.Element {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <Link to={ProfileLocation(applicant.user.wallet || '')}>
        <img
          className={styles.blockie}
          src={makeBlockie(applicant.user.wallet || '')}
          alt="profile"
        />
      </Link>
      <div>
        <div className={styles.usernameContainer}>
          <Link
            to={ProfileLocation(applicant.user.wallet || '')}
            className={styles.username}
          >
            {applicant.user.username}
          </Link>
          {applicant.user.skills?.slice(0, 2)?.map((skill, i) => (
            <Pill
              key={`${skill}-${i}`}
              text={skill.kind}
              style={{
                backgroundColor:
                  taskFilters.find(filter => filter.name === skill.kind)
                    ?.color || '#673969',
                color: 'white',
                marginLeft: '8px',
              }}
            />
          ))}
          {applicant.user.skills?.length && applicant.user.skills?.length > 2 && (
            <Pill
              text={`+${applicant.user.skills?.length - 2}`}
              style={{
                backgroundColor: '#202837',
                color: 'white',
                marginLeft: '8px',
                paddingLeft: '4px',
                paddingRight: '9px',
              }}
            />
          )}
        </div>
        <div className={styles.userInfoFooter}>
          <img
            src={brainGray}
            alt="experience"
            style={{ marginRight: '5px' }}
          />
          {applicant.user?.skills && applicant.user.skills[0]?.experience}
          <div className={styles.dot}></div>
          <img src={timer} alt="availability" style={{ marginRight: '5px' }} />
          {
            availabilityOptions.find(
              option => option.value === applicant.user.availability,
            )?.label
          }
        </div>
      </div>
    </div>
  );
}
