import bookmarkIcon from 'assets/bookmark.svg';
import bookmarkActiveIcon from 'assets/bookmarkActive.svg';
import bookmarkActiveHoverIcon from 'assets/bookmarkActiveHover.svg';
import bookmarkHoverIcon from 'assets/bookmarkHover.svg';
import { CSSProperties } from 'react';
import { useState } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  bookmark: {
    cursor: 'pointer',
  },
});

export default function Bookmark({
  isBookmarked,
  onChange,
  style,
}: {
  isBookmarked: boolean;
  style?: CSSProperties;
  onChange: (isBookmarked: boolean) => void;
}): JSX.Element {
  const styles = useStyles();
  const [hovered, setHovered] = useState(false);

  return (
    <img
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
        e.nativeEvent.stopImmediatePropagation();
        onChange(!isBookmarked);
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      src={
        isBookmarked
          ? hovered
            ? bookmarkActiveHoverIcon
            : bookmarkActiveIcon
          : hovered
          ? bookmarkHoverIcon
          : bookmarkIcon
      }
      alt="bookmark"
      className={styles.bookmark}
      style={style}
    />
  );
}
