import { IUserData } from 'api/usersApi';
import { CSSProperties } from 'react';
import { createUseStyles } from 'react-jss';

import ApplicantHeader from './ApplicantHeader';
import ApplicantSocials from './ApplicantSocials';

export interface ApplicantProps {
  createdAt: string;
  daoId: string;
  discordChannelId: string;
  discordMessageId: string;
  id: number;
  opportunityId: number;
  status: string;
  updatedAt: string;
  userId: number;
  user: IUserData;
  appliedMessage?: string;
}

const useStyles = createUseStyles({
  container: {
    width: '100%',
    '&:hover': {
      '& $cta': {
        opacity: '1 !important',
      },
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  cta: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '32px',
    width: '100px',
    padding: '0 12px',
    borderRadius: '100px',
    fontSize: '12px',
    cursor: 'pointer',
    opacity: 0,
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginTop: '16px',
    color: '#E8E9EA',
    fontSize: '16px',
    lineHeight: '21.6px',
  },
  socials: {
    display: 'flex',
    marginLeft: '16px',
    '& img': {
      width: '24px',
    },
  },
});

const ToggleAssignApplicantCTA = ({
  toggleAssign,
  isLoading,
  isAssigned,
}: {
  toggleAssign: () => void;
  isLoading: boolean;
  isAssigned?: boolean;
}) => {
  const styles = useStyles();

  const label = isAssigned ? 'Assigned' : isLoading ? 'Assigning' : 'Assign';

  return (
    <div
      className={styles.cta}
      style={{
        cursor: !isLoading && !isAssigned ? 'pointer' : 'default',
        opacity: isLoading ? 1 : !isAssigned ? 0 : 1,
        backgroundColor: '#F9F9F9',
        color: '#1B212D',
        border: 'none',
      }}
      onClick={() => !isAssigned && !isLoading && toggleAssign()}
    >
      {label}
    </div>
  );
};

export default function DAOApplicant({
  applicant,
  style,
  assigning,
  toggleAssign,
  opportunityStatus: status,
  isAssigned,
}: {
  applicant: ApplicantProps;
  assigning: boolean;
  toggleAssign: (applicantData: ApplicantProps) => void;
  opportunityStatus: string;
  style?: CSSProperties;
  isAssigned?: boolean;
}): JSX.Element {
  const styles = useStyles();

  const canToggle =
    !assigning && (status === 'Available' || status === 'InProgress');

  return (
    <div style={{ ...style }} className={styles.container}>
      <div className={styles.header}>
        <ApplicantHeader applicant={applicant} />
        {(status === 'Available' || status === 'InProgress') && (
          <ToggleAssignApplicantCTA
            isAssigned={isAssigned}
            toggleAssign={() =>
              canToggle && applicant.user.wallet && toggleAssign(applicant)
            }
            isLoading={assigning}
          />
        )}
      </div>
      <div className={styles.contentContainer}>
        <div style={{ maxWidth: '561px', paddingLeft: '48px' }}>
          {applicant.appliedMessage}
        </div>
        <ApplicantSocials applicant={applicant} />
      </div>
    </div>
  );
}
