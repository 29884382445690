import { availabilityOptions } from 'api/onboardingApi';
import { IAnalytics, IOpportunity } from 'api/opportunitiesApi';
import { getUser, IUserData } from 'api/usersApi';
import banner from 'assets/banner.png';
import copyIcon from 'assets/copy.svg';
import discordIcon from 'assets/discord.svg';
import githubIcon from 'assets/github.svg';
import timer from 'assets/timer.svg';
import twitterIcon from 'assets/twitter.svg';
import DAOStreak from 'components/DAOStreak';
import { OpportunityCard } from 'components/OpportunityCard';
import Pill from 'components/Pill';
import { Button } from 'design-system';
import makeBlockie from 'ethereum-blockies-base64';
import MainLayout from 'layouts/MainLayout';
import { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { RouteComponentProps } from 'react-router-dom';
import useGlobalState from 'state';
import { formatAddress } from 'utils/utils';

import { taskFilters } from './Opportunities';

const useStyles = createUseStyles({
  container: { display: 'flex', alignItems: 'flex-start' },
  content: { width: '100%', marginRight: '40px' },
  sidebar: { width: '310px', flexShrink: 0 },
  banner: {
    width: '100%',
    height: '160px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    borderRadius: '8px',
    position: 'relative',
  },
  blockie: {
    position: 'absolute',
    bottom: '-30px',
    left: '24px',
    width: '120px',
    height: '120px',
    borderRadius: '120px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '54px',
  },
  username: {
    fontSize: '40px',
    lineHeight: '40px',
    color: '#F9F9F9',
  },
  skillsContainer: { display: 'flex', marginTop: '24px' },
  availability: {
    marginTop: '24px',
    display: 'flex',
    alignItems: 'center',
    color: '#BBBCC0',
    fontSize: '14px',
  },
  description: {
    marginTop: '24px',
    color: '#BBBCC0',
    fontSize: '16px',
    lineHeight: '21.6px',
    maxWidth: '515px',
  },
  divider: {
    width: '100%',
    margin: '40px 0px',
    height: '1px',
    backgroundColor: '#303B5B',
  },
  statsContainer: { padding: '0px 24px', display: 'flex' },
  stat: { textAlign: 'left', width: '33%' },
  statTitle: {
    color: '#f9f9f9',
    fontSize: '24px',
    lineHeight: '24px',
    marginBottom: '16px',
  },
  statDescription: { color: '#BBBCC0', fontSize: '16px', lineHeight: '16px' },
  tasksTitle: { color: '#f9f9f9', fontSize: '24px', lineHeight: '24px' },
  tabsContainer: {
    display: 'flex',
    borderBottom: '1px solid #303B5B',
    marginTop: '32px',
  },
  tab: {
    marginRight: '32px',
    cursor: 'pointer',
    fontSize: '16px',
    lineHeight: '16px',
    paddingBottom: '24px',
  },
  sidebarTitle: {
    marginBottom: '16px',
    color: '#F9F9F9',
    fontSize: '14px',
    lineHeight: '14px',
  },
  sidebarText: {
    color: '#BBBCC0',
    fontSize: '14px',
    lineHeight: '14px',
  },
  pillContainer: {
    border: '1px solid #303B5B',
    borderRadius: '100px',
    padding: '16px 24px',
    color: '#F9F9F9',
    fontSize: '16px',
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  copyIcon: { marginLeft: '16px', filter: 'invert(1)', width: '16px' },
});

const tabs = ['In Progress', 'Completed'];

export default function ProfileView({
  match,
}: RouteComponentProps<{ wallet: string }>): JSX.Element {
  const styles = useStyles();
  const { loggedInUser } = useGlobalState();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<null | IUserData>(null);
  const [analytics, setAnalytics] = useState<null | IAnalytics>(null);
  const [opportunities, setOpportunities] = useState<{
    inProgress: IOpportunity[];
    completed: IOpportunity[];
  } | null>(null);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const result = await getUser(match.params.wallet.toLowerCase());
        const data = await result.json();
        setUser(data);
        setAnalytics(data.analytics);
        setOpportunities(data.opportunities);
      } finally {
        setLoading(false);
      }
    })();
  }, [match.params.wallet]);

  const myProfile = user?.id === loggedInUser?.id;

  const currentOpportunities =
    selectedTab === 'In Progress'
      ? opportunities?.inProgress
      : opportunities?.completed;

  return (
    <MainLayout loading={loading} title={myProfile ? 'My Profile' : 'Profile'}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div
            className={styles.banner}
            style={{ backgroundImage: `url(${banner})` }}
          >
            {user && (
              <img
                src={makeBlockie(user?.wallet || '')}
                alt="blockie"
                className={styles.blockie}
              />
            )}
          </div>
          <div className={styles.header}>
            <div className={styles.username}>{user?.username}</div>
            {myProfile && <Button disabled size="small" label="Edit Profile" />}
          </div>
          <div className={styles.skillsContainer}>
            {user?.skills?.map((skill, i) => (
              <Pill
                key={`${skill}-${i}`}
                text={skill.kind}
                style={{
                  backgroundColor:
                    taskFilters.find(filter => filter.name === skill.kind)
                      ?.color || '#673969',
                  color: 'white',
                  marginRight: '8px',
                  marginLeft: 0,
                }}
              />
            ))}
          </div>
          <div className={styles.availability}>
            <img
              src={timer}
              alt="availability"
              style={{ marginRight: '6px' }}
            />
            <div>
              {
                availabilityOptions.find(
                  availability => availability.value === user?.availability,
                )?.label
              }
            </div>
          </div>
          <div className={styles.description}>{user?.bio}</div>
          <div className={styles.divider} />
          <div className={styles.statsContainer}>
            <div className={styles.stat}>
              <div className={styles.statTitle}>
                $
                {analytics?.monthly.feesEarned.toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                })}
              </div>
              <div className={styles.statDescription}>Earned</div>
            </div>
            <div className={styles.stat}>
              <div className={styles.statTitle}>
                {analytics?.monthly.tasksInProgress}
              </div>
              <div className={styles.statDescription}>In Progress Tasks</div>
            </div>
            <div className={styles.stat}>
              <div className={styles.statTitle}>
                {analytics?.monthly.tasksCompleted}
              </div>
              <div className={styles.statDescription}>Completed Tasks</div>
            </div>
          </div>
          <div className={styles.divider} />
          <div className={styles.tasksTitle}>Tasks</div>
          <div className={styles.tabsContainer}>
            {tabs.map((tab, i) => (
              <div
                key={`${tab}-${i}`}
                style={{
                  color: selectedTab === tab ? '#F9F9F9' : '#A4A6AB',
                  borderBottom:
                    selectedTab === tab ? '1px solid #f9f9f9' : 'none',
                }}
                onClick={() => setSelectedTab(tab)}
                className={styles.tab}
              >
                {tab}
              </div>
            ))}
          </div>
          <div style={{ marginTop: '25px' }}>
            {currentOpportunities &&
              currentOpportunities.map((opp, i) => (
                <OpportunityCard
                  opportunity={opp}
                  key={`${opp.id}-${i}`}
                  style={{ marginBottom: '16px' }}
                />
              ))}
          </div>
        </div>
        <div className={styles.sidebar}>
          <div>
            <div className={styles.sidebarTitle}>Contributions</div>
            {user?.contributions?.length === 0 ? (
              <div className={styles.sidebarText}>No contributions</div>
            ) : (
              user?.contributions?.map((dao, i) => (
                <DAOStreak dao={dao} key={`${dao.id}-${i}`} />
              ))
            )}
          </div>
          <div>
            <div className={styles.sidebarTitle} style={{ marginTop: '40px' }}>
              Wallet
            </div>
            <div style={{ display: 'inline-block' }}>
              <div className={styles.pillContainer}>
                {formatAddress(user?.wallet, null)}
                <img src={copyIcon} alt="copy" className={styles.copyIcon} />
              </div>
            </div>
          </div>
          <div style={{ marginTop: '40px' }}>
            <div className={styles.sidebarTitle}>Social links</div>
            {user?.discord && (
              <>
                <div style={{ display: 'inline-block' }}>
                  <a
                    href={`https://discordapp.com/users/${user.discordId}`}
                    target="_blank"
                    rel="noreferrer"
                    className={styles.pillContainer}
                    style={{ padding: '16px 18px' }}
                  >
                    <img
                      src={discordIcon}
                      alt="discord"
                      style={{ width: '24px' }}
                    />
                    <div style={{ marginLeft: '8px', fontSize: '14px' }}>
                      Discord
                    </div>
                  </a>
                </div>
                <br />
              </>
            )}
            {user?.twitter && (
              <>
                <div style={{ display: 'inline-block', marginTop: '16px' }}>
                  <a
                    href={`https://twitter.com/${user.twitter}`}
                    target="_blank"
                    rel="noreferrer"
                    className={styles.pillContainer}
                    style={{ padding: '16px 18px' }}
                  >
                    <img
                      src={twitterIcon}
                      alt="twitter"
                      style={{
                        width: '24px',
                        filter: 'brightness(0) invert(1)',
                      }}
                    />
                    <div style={{ marginLeft: '8px', fontSize: '14px' }}>
                      Twitter
                    </div>
                  </a>
                </div>
                <br />
              </>
            )}
            {user?.github && (
              <div style={{ display: 'inline-block', marginTop: '16px' }}>
                <a
                  href={`https://github.com/${user.github}`}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.pillContainer}
                  style={{ padding: '16px 18px' }}
                >
                  <img
                    src={githubIcon}
                    alt="reddit"
                    style={{ width: '24px', filter: 'brightness(0) invert(1)' }}
                  />
                  <div style={{ marginLeft: '8px', fontSize: '14px' }}>
                    Github
                  </div>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
