import { IOnboardingDao } from 'api/onboardingApi';
import { SignUpLocation } from 'Locations';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import { Element } from 'react-scroll';

const useStyles = createUseStyles({
  container: {
    textAlign: 'center',
    marginTop: '150px',
    color: '#f9f9f9',
    paddingBottom: '88px',
  },
  title: { fontSize: '70px', fontFamily: 'eiko', fontWeight: 100 },
  descriptionContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '64px',
  },
  description: { fontSize: '20px', maxWidth: '540px', lineHeight: '135%' },
  CTA: {
    borderRadius: '100px',
    backgroundColor: '#f9f9f9',
    padding: '18px 24px',
    color: '#1B212D',
    cursor: 'pointer',
    fontSize: '16px',
  },
});

export default function OnboardingGettingStarted({
  dao,
}: {
  dao: IOnboardingDao | null;
}): JSX.Element {
  const styles = useStyles();
  return (
    <Element name={'getting-started'}>
      <div className={styles.container}>
        <div className={styles.title}>Let&apos;s get you building</div>
        <div className={styles.descriptionContainer}>
          <div className={styles.description}>
            Now that you learned about {dao?.name}, it&apos;s time we learn a
            little bit about you :) <br />
            <br />
            We&apos;ll ask a few questions and then find the best opportunities
            for you. Are you ready?
          </div>
        </div>
        <div className={styles.descriptionContainer}>
          {dao && (
            <Link to={`${SignUpLocation}?dao=${dao.id}`} className={styles.CTA}>
              Start
            </Link>
          )}
        </div>
      </div>
    </Element>
  );
}
