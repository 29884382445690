// https://www.notion.so/Subgraph-JSON-data-8e788b64a5814958bdb1d2796486da56

export type TaskCreationMetadata = {
  title: string;
  description: string;
  content: string;
  category: string;
  tags: string[];
  experience: string;
  deadline: number; // epoch timestamp in seconds
  timeEstimateValue: number;
  timeEstimateUnit: string;
};

export type TaskApplicationMetadata = {
  message: string;
  address: string;
  timestamp: number; // epoch timestamp in seconds
};

export type TaskAssignmentMetadata = {
  message: string;
  applications: TaskApplicationMetadata[];
};

export type TaskSubmissionMetadata = {
  message: string;
  attachments: string[];
  timestamp: number; // epoch timestamp in seconds
};

export type TaskTimelineMetadata = {
  kind: string;
  message: string;
  timestamp: number; // epoch timestamp in seconds
};

export type TaskCompletionMetadata = {
  comment: string;
  submissions: TaskSubmissionMetadata[];
  timeline: TaskTimelineMetadata[];
};

export type TaskCancellationMetadata = {
  comment: string;
  cancellationReason: string;
  submissions: TaskSubmissionMetadata[];
};

export type DisputeRaiseMetadata = {
  comment: string;
  disputeReason: string;
};

export type DisputeResolutionMetadata = {
  comment: string;
};

export enum SignatureFunction {
  AssignTask,
  CancelTask,
  CompleteTasks,
}
