import {
  bookmarkOpportunity,
  IOpportunity,
  unbookmarkOpportunity,
} from 'api/opportunitiesApi';
import bookmarkIcon from 'assets/bookmark.svg';
import bookmarkActiveIcon from 'assets/bookmarkActive.svg';
import discordIcon from 'assets/discord.svg';
import xIcon from 'assets/x.svg';
import { useWallet } from 'contexts/WalletContext';
import { Spinner } from 'design-system';
import { isDAO } from 'layouts/MainLayout';
import { useState } from 'react';
import { createUseStyles } from 'react-jss';
import useGlobalState from 'state';
import { sleep } from 'utils/utils';

const useStyles = createUseStyles({
  sidebar: {
    flexShrink: 0,
    width: '310px',
    marginLeft: '40px',
  },
  sidebarCard: {
    width: '100%',
    backgroundColor: '#202837',
    borderRadius: '8px',
    padding: '24px',
  },
  cardTitle: { color: '#F9F9F9', fontSize: '16px', marginBottom: '16px' },
  cardText: { color: '#BBBCC0', fontSize: '14px', lineHeight: '135%' },
  button: {
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '12px 16px',
    color: '#fff',
    fontSize: '16px',
    cursor: 'pointer',
    borderRadius: '100px',
    marginTop: '32px',
    border: '0.5px solid #303B5B',
  },
  buttonIcon: { marginRight: '8px', width: '20px' },
});

export default function OpportunitySidebar({
  opportunity,
  setApplying,
  setSubmitting,
  setReviewing,
  onBookmark,
  cancelling,
  onCancel,
  onApproveCancel,
}: {
  opportunity: IOpportunity | null;
  setApplying: (applying: boolean) => void;
  setSubmitting: (submitting: boolean) => void;
  setReviewing: (reviewing: boolean) => void;
  onBookmark: () => Promise<void>;
  cancelling: boolean;
  onCancel: () => Promise<void>;
  onApproveCancel: () => Promise<void>;
}): JSX.Element {
  const styles = useStyles();
  const [loadingBookmark, setLoadingBookmark] = useState(false);
  const { loggedInUser } = useGlobalState();
  const { isConnected, connectWallet, address, shareConfig, chainId } =
    useWallet();

  const handleBookmark = async () => {
    if (opportunity && chainId && shareConfig) {
      try {
        setLoadingBookmark(true);
        const result = opportunity.bookmarked
          ? unbookmarkOpportunity(
              `${shareConfig.escrowAddress.toLowerCase()}-0x${opportunity.id}`,
              {
                chainId,
                contractAddress: shareConfig.escrowAddress,
              },
            )
          : bookmarkOpportunity(
              `${shareConfig.escrowAddress.toLowerCase()}-0x${opportunity.id}`,
              {
                chainId,
                contractAddress: shareConfig.escrowAddress,
              },
            );
        await Promise.all([result, sleep(300)]);
        await onBookmark();
      } finally {
        setLoadingBookmark(false);
      }
    }
  };
  let action: string | null = null;
  let cta: string | null = null;
  let title = '';
  let description = '';

  const applied =
    loggedInUser &&
    opportunity &&
    opportunity.opportunityApplicants.find(
      applicant => applicant.wallet === loggedInUser.wallet,
    ) !== undefined;

  const isAssigned =
    loggedInUser && opportunity && opportunity.user?.id === loggedInUser.id;

  const DAOUser = isDAO(loggedInUser);

  switch (opportunity && opportunity.status) {
    case 'Available':
      if (applied) {
        title = 'Apply to Opportunity';
        description = `Excited to work on this opportunity? Apply now and tell ${opportunity?.dao?.name} why you're the best person for the job.`;
        action = null;
        cta = 'Applied';
      } else {
        title = 'Apply to Opportunity';
        description = `Excited to work on this opportunity? Apply now and tell ${opportunity?.dao?.name} why you're the best person for the job.`;
        action = 'apply';
        cta = 'Apply';
      }
      break;
    case 'InProgress':
      if (isAssigned) {
        title = 'Submit your work';
        description =
          'You have been assigned to this opportunity. Submit your work now to complete the opportunity.';
        action = 'submit';
        cta = 'Submit';
      } else {
        action = null;
        cta = applied ? 'Applied' : 'Apply';
      }
      break;
    case 'InReview':
      if (DAOUser) {
        title = 'Review';
        description =
          'The contributor has submitted their work. Review it now.';
        action = 'review';
        cta = 'Review';
      } else if (isAssigned) {
        title = 'Submit your work';
        description =
          'Your work has been submitted. It is currently being reviewed.';
        action = null;
        cta = 'In review';
      } else {
        action = null;
        cta = applied ? 'Applied' : 'Apply';
      }
      break;
    case 'Completed':
      if (isAssigned) {
        title = 'Completed';
        description = 'This opportunity has been completed 🎉.';
        action = null;
        cta = 'Completed';
      } else {
        action = null;
        cta = applied ? 'Applied' : 'Apply';
      }
      break;
  }

  const hideCTACard =
    (opportunity?.status !== 'Available' && !isAssigned && !DAOUser) ||
    (DAOUser && opportunity?.status !== 'InReview') ||
    opportunity?.status === 'Cancelled';

  const showCancel =
    loggedInUser &&
    !opportunity?.cancellation?.combinedSignature &&
    ((DAOUser &&
      opportunity?.status !== 'Completed' &&
      opportunity?.status !== 'Cancelled') ||
      address === opportunity?.user?.wallet);

  const showCancellationRequested =
    loggedInUser &&
    opportunity?.status !== 'Cancelled' &&
    opportunity?.status !== 'Completed' &&
    opportunity?.cancellation &&
    (address === opportunity.cancellation.reviewerWallet.toLowerCase() ||
      (address === opportunity?.cancellation.contributorWallet.toLowerCase() &&
        !opportunity?.cancellation.combinedSignature));

  const contributorPendingApproval =
    showCancellationRequested &&
    !DAOUser &&
    opportunity?.cancellation.initiatedBy === address;

  return (
    <div className={styles.sidebar}>
      {!hideCTACard && (
        <div className={styles.sidebarCard}>
          <div className={styles.cardTitle}>{title}</div>
          <div className={styles.cardText}>{description}</div>
          <div
            className={styles.button}
            style={{
              cursor: action ? 'pointer' : 'not-allowed',
              color: action ? '#1B212D' : '#1B212D',
              backgroundColor: action ? '#fff' : '#BBBCC0',
              border: 'none',
            }}
            onClick={() => {
              if (action === 'apply') {
                if (!isConnected) {
                  connectWallet();
                } else {
                  setApplying(true);
                }
              } else if (action === 'submit') {
                setSubmitting(true);
              } else if (action === 'review') {
                setReviewing(true);
              }
            }}
          >
            {cta}
          </div>
        </div>
      )}
      {!DAOUser && loggedInUser && (
        <div
          className={styles.sidebarCard}
          style={{ marginTop: !hideCTACard ? '24px' : 0 }}
        >
          <div className={styles.cardTitle}>Save Opportunity</div>
          <div className={styles.cardText}>
            Save your favorite opportunities so you can always come back for
            updates
          </div>
          <div
            className={styles.button}
            onClick={() => !loadingBookmark && handleBookmark()}
          >
            {loadingBookmark ? (
              <Spinner style={{ height: '20px' }} />
            ) : (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={
                    opportunity?.bookmarked ? bookmarkActiveIcon : bookmarkIcon
                  }
                  alt="save"
                  className={styles.buttonIcon}
                />

                {opportunity?.bookmarked ? 'Saved' : 'Save'}
              </div>
            )}
          </div>
        </div>
      )}
      {/*DAOUser && (
        <div
          className={styles.sidebarCard}
          style={{ marginTop: !hideCTACard ? '24px' : 0 }}
        >
          <div className={styles.cardTitle}>Sync with Notion</div>
          <div className={styles.cardText}>
            Retrieve latest information from Notion. This needs to be done
            manually after every update
          </div>
          <div
            className={styles.button}
            onClick={() => !syncingNotion && onSyncNotion()}
          >
            <img
              src={notionIcon}
              alt="Notion"
              style={{ width: '20px', marginRight: '8px' }}
            />
            {syncingNotion ? 'Syncing...' : 'Sync'}
          </div>
        </div>
      )*/}
      <div className={styles.sidebarCard} style={{ marginTop: '24px' }}>
        <div className={styles.cardTitle}>Contact by Discord</div>
        <div className={styles.cardText}>
          Discord servers are organized into topic-based channels where you can
          collaborate
        </div>
        <a href={opportunity?.discord} target={'_blank'} rel="noreferrer">
          <div className={styles.button}>
            <img
              src={discordIcon}
              alt="discord"
              className={styles.buttonIcon}
            />
            Chat
          </div>
        </a>
      </div>
      {showCancellationRequested ? (
        <div className={styles.sidebarCard} style={{ marginTop: '24px' }}>
          <div className={styles.cardTitle}>Cancellation Requested</div>
          <div className={styles.cardText}>
            {address === opportunity.cancellation.reviewerWallet.toLowerCase()
              ? 'The contributor has requested to cancel this task. If you approve, the task will be cancelled'
              : contributorPendingApproval
              ? 'You have requested to cancel this task, your request is pending approval by the reviewer'
              : 'The reviewer has requested to cancel this task. If you approve, the task will be sent back to the reviewer to finalize cancellation'}
          </div>
          {!contributorPendingApproval && (
            <div
              className={styles.button}
              onClick={() => !cancelling && onApproveCancel()}
            >
              <img src={xIcon} alt="cancel" className={styles.buttonIcon} />
              {cancelling ? 'Cancelling...' : 'Approve Cancellation'}
            </div>
          )}
        </div>
      ) : (
        showCancel && (
          <div className={styles.sidebarCard} style={{ marginTop: '24px' }}>
            <div className={styles.cardTitle}>
              {opportunity?.status === 'Available'
                ? 'Cancel Opportunity'
                : 'Request Cancellation'}
            </div>
            <div className={styles.cardText}>
              {opportunity?.status === 'Available'
                ? 'Is this opportunity not relevant anymore? Cancel it now.'
                : `Want to cancel this opportunity? Request cancellation, the ${
                    DAOUser ? 'contributor' : 'reviewer'
                  } will be notified and will be prompted to accept.`}
            </div>
            <div
              className={styles.button}
              onClick={() => !cancelling && onCancel()}
            >
              <img src={xIcon} alt="cancel" className={styles.buttonIcon} />
              {cancelling ? 'Cancelling...' : 'Cancel'}
            </div>
          </div>
        )
      )}
    </div>
  );
}
