import { IOpportunityTimeline } from 'api/opportunitiesApi';
import timelineCheck from 'assets/timelineCheck.svg';
import timelineEdit from 'assets/timelineEdit.svg';
import timelineEye from 'assets/timelineEye.svg';
import timelineOpportunityArchived from 'assets/timelineOpportunityArchived.svg';
import timelineOpportunityUnarchived from 'assets/timelineOpportunityUnarchived.svg';
import timelinePlus from 'assets/timelinePlus.svg';
import timelineUpdate from 'assets/timelineUpdate.svg';
import timelineUserAdded from 'assets/timelineUserAdded.svg';
import timelineUserCheck from 'assets/timelineUserCheck.svg';
import timelineUserRemoved from 'assets/timelineUserRemoved.svg';
import moment from 'moment';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: '32px',
  },
  imageContainer: {
    borderRadius: '100%',
    background: '#202837',
    border: '1.5px solid #303B5B',
    width: '32px',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentContainer: { marginLeft: '16px', width: '437px' },
  title: { fontSize: '14px', color: '#f9f9f9', marginBottom: '8px' },
  message: {
    background: '#202837',
    borderRadius: '8px',
    border: '1px solid #303B5B',
    padding: '16px',
    marginTop: '16px',
  },
  date: { fontSize: '12px', color: '#A4A6AB' },
  imageSection: { width: '32px', flexShrink: 0 },
  image: { width: '20px' },
});

export default function OpportunityTimeline({
  activities,
}: {
  activities: IOpportunityTimeline[];
}): JSX.Element {
  const styles = useStyles();
  return (
    <div style={{ position: 'relative' }}>
      {/*<div
        style={{
          position: 'absolute',
          height: '100%',
          width: '1px',
          backgroundColor: '#303B5B',
          left: '16px',
        }}
    />*/}
      <div style={{ zIndex: 10000 }}>
        {activities.map((activity, i) => (
          <div key={`${activity.id}-${i}`} className={styles.container}>
            <div className={styles.imageSection}>
              <div className={styles.imageContainer}>
                <img
                  src={
                    activity.kind === 'OpportunitySubmitted'
                      ? timelineEye
                      : activity.kind === 'ContributorAssigned'
                      ? timelineUserCheck
                      : activity.kind === 'ContributorUnassigned'
                      ? timelineUserRemoved
                      : activity.kind === 'ContributorApplied'
                      ? timelineUserAdded
                      : activity.kind === 'OpportunityUpdated'
                      ? timelineUpdate
                      : activity.kind === 'OpportunityApproved'
                      ? timelineCheck
                      : activity.kind === 'OpportunityRejected'
                      ? timelineEdit
                      : activity.kind === 'OpportunityArchived'
                      ? timelineOpportunityArchived
                      : activity.kind === 'OpportunityUnarchived'
                      ? timelineOpportunityUnarchived
                      : timelinePlus
                  }
                  alt="timelineIcon"
                  className={styles.image}
                />
              </div>
            </div>
            <div className={styles.contentContainer}>
              <div className={styles.title}>{activity.title}</div>
              <div className={styles.date}>
                {moment(activity.createdAt).format('HH:mm a MMM Do YYYY')}
              </div>
              {activity.content.message && (
                <div className={styles.message}>{activity.content.message}</div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
