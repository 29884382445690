import { IDAO } from 'api/communitiesApi';
import checkIcon from 'assets/check.svg';
import discordLight from 'assets/discordLight.svg';
import githubLight from 'assets/githubLight.svg';
import twitterLight from 'assets/twitterLight.svg';
import { CommunityLocation } from 'Locations';
import { CSSProperties } from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';

const useStyles = createUseStyles({
  container: {
    borderRadius: '8px',
    backgroundColor: '#202837',
    padding: '24px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  logoContainer: { display: 'flex', alignItems: 'center' },
  logo: {
    borderRadius: '50%',
    width: '48px',
    height: '48px',
    marginRight: '16px',
  },
  title: { color: '#F9F9F9', fontSize: '20px' },
  joined: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 10px',
    borderRadius: '4px',
    backgroundColor: '#2B8C34',
    color: '#F9F9F9',
    fontSize: '14px',
  },
  check: { marginRight: '8px', filter: 'brightness(0) invert(1)' },
  divider: {
    margin: '16px 0px',
    width: '100%',
    height: '0.5px',
    backgroundColor: '#303B5B',
  },
  description: { fontSize: '16px', lineHeight: '21.6px', color: '#BBBCC0' },
  footerContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    marginTop: '24px',
  },
  socialsContainer: { display: 'flex' },
  footerDetail: { color: '#BBBCC0', fontSize: '14px' },
});

export default function DAOCard({
  style,
  dao,
}: {
  style?: CSSProperties;
  dao: IDAO;
}): JSX.Element {
  const styles = useStyles();

  return (
    <Link
      to={CommunityLocation(dao.id)}
      className={styles.container}
      style={{ ...style }}
    >
      <div className={styles.header}>
        <div className={styles.logoContainer}>
          <img src={dao.logo} alt="logo" className={styles.logo} />
          <div className={styles.title}>{dao.name}</div>
        </div>
        {dao.joined && (
          <div className={styles.joined}>
            <img src={checkIcon} alt="check" className={styles.check} />
            Joined
          </div>
        )}
      </div>
      <div className={styles.divider} />
      <div className={styles.description}>{dao.description}</div>
      <div className={styles.footerContainer}>
        <div>
          <div className={styles.footerDetail}>
            Opportunities:{' '}
            <span style={{ color: '#F9F9F9' }}>
              {dao.availableOpportunitiesCount}
            </span>
          </div>
          <div className={styles.footerDetail} style={{ marginTop: '8px' }}>
            Token <span style={{ color: '#F9F9F9' }}>{dao.token}</span>
          </div>
        </div>
        <div className={styles.socialsContainer}>
          <a href={dao.discord} target="_blank" rel="noreferrer">
            <img src={discordLight} alt="discord" />
          </a>
          <a
            href={`https://twitter.com/${dao.twitter}`}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={twitterLight}
              alt="twitter"
              style={{ marginLeft: '16px' }}
            />
          </a>
          {dao.github && (
            <a
              href={`https://github.com/${dao.github}`}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={githubLight}
                alt="github"
                style={{ marginLeft: '16px' }}
              />
            </a>
          )}
        </div>
      </div>
    </Link>
  );
}
