import signUpIllustration from 'assets/signUpIllustration.png';
import { useWallet } from 'contexts/WalletContext';
import { SignUpLocation } from 'Locations';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import { formatAddress } from 'utils/utils';

import Modal from './Modal';

const useStyles = createUseStyles({
  container: { maxWidth: '1034px', display: 'flex' },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '24px 40px',
  },
  close: { width: '24px', cursor: 'pointer' },
  illustration: {
    width: '517px',
    minHeight: '511px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    flexShrink: 0,
  },
  content: {
    width: '100%',
    padding: '84px 108px',
    textAlign: 'center',
  },
  title: {
    fontSize: '24px',
    color: '#f9f9f9',
    marginBottom: '32px',
    fontWeight: 400,
  },
  pill: {
    border: '0.5px solid #303B5B',
    padding: '16px',
    borderRadius: '100px',
    color: '#f9f9f9',
    fontSize: '16px',
    display: 'inline-block',
  },
  description: { fontSize: '16px', lineHeight: '21.6px', marginTop: '32px' },
  button: {
    backgroundColor: '#f9f9f9',
    color: '#1B212D',
    fontSize: '16px',
    padding: '18px 24px',
    borderRadius: '100px',
    display: 'inline-block',
    marginTop: '64px',
    cursor: 'pointer',
  },
});

export default function PreOnboardingModal({
  open,
  onClose,
}: {
  open: boolean;
  onClose(reload?: boolean): void;
}): JSX.Element {
  const styles = useStyles();
  const { address } = useWallet();

  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      style={{ borderRadius: '8px' }}
    >
      <div className={styles.container}>
        <div
          style={{ backgroundImage: `url(${signUpIllustration})` }}
          className={styles.illustration}
        />
        <div className={styles.content}>
          <div className={styles.title}>Wallet connected!</div>
          <div className={styles.pill}>{formatAddress(address, null)}</div>
          <div className={styles.description}>
            To apply to opportunities you will need to complete sign up
          </div>
          <Link to={SignUpLocation} className={styles.button}>
            Sign Up
          </Link>
        </div>
      </div>
    </Modal>
  );
}
