import 'rc-slider/assets/index.css';

import { expertiseOptions } from 'api/onboardingApi';
import plusIcon from 'assets/plus.svg';
import x from 'assets/x.svg';
import Select from 'components/Select';
import Slider from 'rc-slider';
import { createUseStyles } from 'react-jss';
//@ts-ignore
import { WithContext as ReactTags } from 'react-tag-input';
import { taskFilters } from 'views/Opportunities';
import { Skill, Tag } from 'views/SignUp';

export const sliderHandleStyle = {
  background: '#D6AA3C',
  border: 'none',
  height: '16px',
  width: '16px',
  opacity: 1,
  transform: 'translateY(-1px) translateX(-50%)',
  boxShadow: 'none',
};

export const sliderDotStyle = {
  backgroundColor: '#D6AA3C',
  height: '12px',
  borderRadius: '3px',
  width: '3px',
  border: 'none',
  transform: 'translateY(1px)',
};

const useStyles = createUseStyles({
  title: { fontSize: '20px', marginBottom: '8px' },
  description: {
    maxWidth: '490px',
    color: '#D1D3D5',
    fontSize: '16px',
    lineHeight: '21.6px',
  },
  skillContainer: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  inputTitle: { marginBottom: '8px', fontSize: '14px' },
  input: {
    background: '#202837',
    border: '1px solid #303B5B',
    borderRadius: '8px',
    padding: '18px 16px',
    fontSize: '16px',
    color: '#f9f9f9',
    display: 'flex',
  },
  add: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '40px',
    cursor: 'pointer',
    fontSize: '16px',
    userSelect: 'none',
  },
  remove: {
    cursor: 'pointer',
    color: '#A4A6AB',
    marginTop: '16px',
    fontSize: '14px',
  },
  inputField: {
    background: 'transparent',
    border: 'none',
    color: '#f9f9f9',
    width: '100%',
  },
  tag: {
    border: '0.5px solid #A4A6AB',
    borderRadius: '100px',
    padding: '9px 12px',
    fontSize: '14px',
    gap: '12px',
    marginRight: '8px',
    alignItems: 'center',
    display: 'inline-flex',
    cursor: 'pointer !important',
    marginBottom: '16px',
  },
  x: {
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },
  tagInput: {
    display: 'inline',
  },
  suggestions: { fontSize: '12px' },
  activeSuggestion: { cursor: 'pointer' },
});

interface OnboardingSkillsProps {
  skills: Skill[];
  setSkills: (skills: Skill[]) => void;
  tags: Tag[];
  setTags: (tags: Tag[]) => void;
}

export const skillOptions = taskFilters.map(({ name }) => {
  return { value: name, label: name };
});

export default function OnboardingSkills({
  skills,
  setSkills,
  tags,
  setTags,
}: OnboardingSkillsProps): JSX.Element {
  const styles = useStyles();

  const KeyCodes = {
    comma: 188,
    enter: 13,
  };

  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  const handleDelete = (i: number) => {
    setTags(tags?.filter((tag, index) => index !== i) || []);
  };

  const handleAddition = (tag: { id: string; text: string }) => {
    setTags([...tags, tag]);
  };

  const handleDrag = (
    tag: { id: string; text: string },
    currPos: number,
    newPos: number,
  ) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClick = (index: number) => {
    handleDelete(index);
  };

  const onAddSkill = () => {
    skills
      ? setSkills([...skills, { kind: 'Development', experience: 'Mid' }])
      : setSkills([{ kind: 'Development', experience: 'Mid' }]);
  };

  const onRemoveSkill = (index: number) => {
    const newSkills = skills.slice();
    newSkills.splice(index, 1);
    setSkills(newSkills);
  };

  const onSelectSkill = (val: { label: string; value: string }, i: number) => {
    if (skills) {
      const newSkills = skills.slice();
      newSkills[i].kind = val.value;
      setSkills(newSkills);
    }
  };

  const onSelectExpertise = (
    val: { label: string; value: string },
    i: number,
  ) => {
    if (skills) {
      const newSkills = skills.slice();
      newSkills[i].experience = val.value;
      setSkills(newSkills);
    }
  };

  return (
    <div style={{ paddingBottom: '40px' }}>
      <div className={styles.title}>List your skills and expertise</div>
      <div className={styles.description}>
        Let DAOs know which skills you&apos;ve mastered or interested in
        learning, and get matched with relevant opportunities.
      </div>
      {skills?.map((skill, i) => (
        <div key={`${skill.kind}-${i}`}>
          <div
            className={styles.skillContainer}
            style={{ marginTop: i === 0 ? '48px' : '40px' }}
          >
            <div style={{ width: '50%', flexShrink: 0 }}>
              <div className={styles.inputTitle}>Skill</div>
              <Select
                options={skillOptions}
                value={{ label: skill.kind, value: skill.kind }}
                onChange={val => onSelectSkill(val, i)}
              />
            </div>
            <div style={{ width: '50%', marginLeft: '30px' }}>
              <div className={styles.inputTitle} style={{ marginLeft: '-8px' }}>
                Level
              </div>
              <div style={{ marginTop: '29px' }}>
                <Slider
                  min={0}
                  max={expertiseOptions.length - 1}
                  handleStyle={sliderHandleStyle}
                  dots
                  dotStyle={sliderDotStyle}
                  marks={expertiseOptions.reduce(
                    (obj, ele, index) => ({
                      ...obj,
                      [index]: {
                        style: {
                          color: '#A4A6AB',
                          marginTop: '8px',
                          fontSize: '14px',
                          display:
                            ele.value === skill.experience ? 'block' : 'none',
                          transform:
                            index === 0
                              ? 'translateX(-10%)'
                              : 'translateX(-50%)',
                        },
                        label: ele.label,
                      },
                    }),
                    {},
                  )}
                  trackStyle={{ backgroundColor: '#D6AA3C', height: '2px' }}
                  railStyle={{ backgroundColor: '#303B5B', height: '2px' }}
                  onChange={value => {
                    onSelectExpertise(expertiseOptions[value as number], i);
                  }}
                  value={expertiseOptions.findIndex(
                    o => o.value === skill.experience,
                  )}
                />
              </div>
            </div>
          </div>

          <div
            className={styles.remove}
            onClick={() => {
              onRemoveSkill(i);
            }}
          >
            Remove skill
          </div>
        </div>
      ))}
      <div className={styles.add} onClick={onAddSkill}>
        <img src={plusIcon} alt="plus" style={{ marginRight: '8px' }} />
        Add new
      </div>
      <div className={styles.inputTitle} style={{ marginTop: '48px' }}>
        Tags
      </div>
      <ReactTags
        inline
        classNames={{
          tags: styles.input,
          tagInput: styles.tagInput,
          tagInputField: styles.inputField,
          tag: styles.tag,
          suggestions: styles.suggestions,
          activeSuggestion: styles.activeSuggestion,
        }}
        placeholder={tags.length === 0 && 'Press enter to add tags'}
        tags={tags}
        //suggestions={tagSuggestions}
        delimiters={delimiters}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        handleDrag={handleDrag}
        handleTagClick={handleTagClick}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        removeComponent={({ onRemove }: any) => (
          <button className={styles.x} onClick={onRemove}>
            <img src={x} alt="x" />
          </button>
        )}
        inputFieldPosition="inline"
        autocomplete
        maxLength={15}
        autoFocus={false}
      />
    </div>
  );
}
