import { IOnboardingDao } from 'api/onboardingApi';
import { createUseStyles } from 'react-jss';
import { Element } from 'react-scroll';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '60px',
  },
  innerContainer: {
    maxWidth: '1200px',
    display: 'flex',
    alignItems: 'flex-start',
  },
  sidebar: { width: '273px', flexShrink: 0, marginRight: '100px' },
  content: { width: '100%', maxWidth: '750px' },
  title: { fontSize: '24px', color: '#f9f9f9' },
  divider: {
    width: '200px',
    height: '0.5px',
    backgroundColor: '#f9f9f9',
    margin: '8px 0 24px 0px',
  },
  description: { fontSize: '16px', color: '#f9f9f9', lineHeight: '135%' },
  valuesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginRight: '-24px',
  },
  value: {
    marginRight: '24px',
    marginBottom: '24px',
    color: '#f9f9f9',
    padding: '24px',
    borderRadius: '8px',
    backdropFilter: 'blur(20px)',
    backgroundColor: 'rgba(249, 249, 249, 0.1)',
    width: '46%',
  },
  cardTitle: { fontSize: '16px', marginBottom: '8px' },
  cardDescription: { fontSize: '16px', color: '#E8E9EA', lineHeight: '135%' },
});

export default function OnboardingValues({
  dao,
}: {
  dao: IOnboardingDao | null;
}): JSX.Element {
  const styles = useStyles();
  return (
    <Element name={'values'}>
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <div className={styles.sidebar}>
            <div className={styles.title}>Vision:</div>
            <div className={styles.divider} />
            <div className={styles.description}>{dao?.vision}</div>
            <div className={styles.title} style={{ marginTop: '119px' }}>
              Mission:
            </div>
            <div className={styles.divider} />
            <div className={styles.description}>{dao?.mission}</div>
          </div>
          <div className={styles.content}>
            <div className={styles.title}>Values:</div>
            <div className={styles.divider} />
            <div className={styles.valuesContainer}>
              {dao?.values?.map((value, i) => (
                <div key={`${value.title}-${i}`} className={styles.value}>
                  <div className={styles.cardTitle}>{value?.title}</div>
                  <div className={styles.cardDescription}>{value?.content}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Element>
  );
}
