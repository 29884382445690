import { IDAO } from 'api/communitiesApi';
import { createUseStyles } from 'react-jss';
import YouTube from 'react-youtube';

const useStyles = createUseStyles({
  container: {
    marginTop: '40px',
  },
  description: { color: '#BBBCC0', fontSize: '16px', lineHeight: '21.6px' },
  videoContainer: {
    width: '445px',
    height: '240px',
    marginTop: '24px',
    borderRadius: '8px',
  },
  youtube: {
    height: '100%',
    width: '100%',
    borderRadius: '8px',
  },
  divider: {
    backgroundColor: '#767A81',
    width: '100%',
    height: '0.5px',
    margin: '40px 0',
  },
  title: { color: '#fff', fontSize: '20px' },
  content: {
    flexShrink: 0,
    width: '468px',
    marginRight: '112px',
  },
  logoContainer: {
    width: '60px',
    height: '60px',
    backgroundColor: '#0A182F',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    padding: '14px',
    marginRight: '18px',
  },

  youtubeContainer: {
    height: '100%',
    width: '100%',
  },

  tag: {
    border: '1px solid #E5E5E5',
    borderRadius: '50px',
    padding: '6px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#707070',
    cursor: 'pointer',
    fontSize: '12px',
    lineHeight: '18px',
    marginRight: '8px',
  },
  tagIcon: {
    marginRight: '6px',
    height: '12px',
    filter:
      'invert(43%) sepia(10%) saturate(18%) hue-rotate(352deg) brightness(97%) contrast(85%)',
  },
  contentTitle: {
    fontSize: '18px',
    lineHeight: '18px',
    fontWeight: 700,
  },
  contentDescription: {
    marginTop: '20px',
    fontSize: '14px',
    lineHeight: '19px',
  },
  opportunitiesTitle: { fontSize: '24px', lineHeight: '29px' },
  viewAll: {
    fontSize: '17px',
    lineHeight: '20px',
    cursor: 'pointer',
    color: '#000',
  },
  opportunitiesContainer: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: '-24px',
    alignItems: 'flex-start',
  },
});

export default function CommunityOverview({ dao }: { dao: IDAO }): JSX.Element {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <div className={styles.description}>{dao.description}</div>
      <div className={styles.videoContainer}>
        {/* @ts-ignore */}
        <YouTube
          videoId={dao?.video?.slice(-11)}
          containerClassName={styles.youtubeContainer}
          className={styles.youtube}
        />
      </div>
    </div>
  );
}
