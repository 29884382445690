import { availabilityOptions, IOnboardingDao } from 'api/onboardingApi';
import { createUseStyles } from 'react-jss';
import { Availability, DaoOption } from 'views/SignUp';

const useStyles = createUseStyles({
  title: { fontSize: '20px', marginBottom: '8px' },
  description: {
    maxWidth: '490px',
    color: '#D1D3D5',
    fontSize: '16px',
    lineHeight: '21.6px',
  },
  inputTitle: { marginBottom: '16px', fontSize: '14px' },
  optionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginRight: '-16px',
  },
  option: {
    width: '47%',
    borderRadius: '8px',
    border: '1px solid #303B5B',
    backgroundColor: '#673969',
    fontSize: '16px',
    textAlign: 'center',
    padding: '16px 32px',
    cursor: 'pointer',
  },
});

interface OnboardingAvailabilityProps {
  dao?: IOnboardingDao | null;
  daoOption: DaoOption | null;
  setDaoOption: (daoOption: DaoOption) => void;
  availability: Availability | null;
  setAvailability: (availability: Availability) => void;
}

export default function OnboardingAvailability({
  daoOption,
  setDaoOption,
  availability,
  setAvailability,
  dao,
}: OnboardingAvailabilityProps): JSX.Element {
  const styles = useStyles();

  const DAOOptions = [
    { label: `Just ${dao?.name || 'one'}`, value: 'one' },
    { label: 'A couple of DAOs', value: 'some' },
    { label: 'Many DAOs', value: 'many' },
  ];

  return (
    <div>
      <div className={styles.title}>Choose your time commitment</div>
      <div className={styles.description}>
        Working with DAOs provides a lot of flexbility. You can work an hour a
        week or full time, there is no wrong answer.
      </div>
      <div className={styles.inputTitle} style={{ marginTop: '48px' }}>
        What is your current availability?
      </div>
      <div className={styles.optionsContainer}>
        {availabilityOptions.map((option, i) => (
          <div
            key={`${option.value}-${i}`}
            className={styles.option}
            style={{
              marginRight: '16px',
              marginBottom: '16px',
              backgroundColor:
                availability?.value === option.value ? '#673969' : '#202837',
            }}
            onClick={() => setAvailability(option)}
          >
            {option.label}
          </div>
        ))}
      </div>

      <div className={styles.inputTitle} style={{ marginTop: '40px' }}>
        How many DAOs do you want to contribute to?
      </div>
      <div className={styles.optionsContainer}>
        {DAOOptions.map((option, i) => (
          <div
            key={`${option.value}-${i}`}
            className={styles.option}
            style={{
              marginRight: '16px',
              marginBottom: '16px',
              backgroundColor:
                daoOption?.value === option.value ? '#673969' : '#202837',
            }}
            onClick={() => setDaoOption(option)}
          >
            {option.label}
          </div>
        ))}
      </div>
    </div>
  );
}
