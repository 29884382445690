import { serverRequest } from './serverRequest';

export type Department =
  | 'Development'
  | 'Engineering'
  | 'Security'
  | 'Product'
  | 'Design'
  | 'Marketing'
  | 'Growth'
  | 'Finance'
  | 'Tokenomics'
  | 'Foxchain'
  | 'Moderation'
  | 'Content'
  | 'Social'
  | 'Operations'
  | 'Community'
  | 'Support'
  | 'Research';

export const departmentColors: Record<Department, string> = {
  Development: '#93E396',
  Engineering: '#93E396',
  Security: '#93E396',
  Product: '#FFA979',
  Design: '#93E3E3',
  Marketing: '#E3DB93',
  Growth: '#E3DB93',
  Finance: '#FF9BCB',
  Tokenomics: '#FF9BCB',
  Foxchain: '#FF9BCB',
  Moderation: '#D99BFF',
  Content: '#D99BFF',
  Social: '#8C79FF',
  Operations: '#FFEA79',
  Community: '#FF7981',
  Support: '#FF7981',
  Research: '#D48AAA',
};

export type IOnboardingMember = {
  name: string;
  avatar: string;
  title: string;
  twitter: string;
  discord: string;
  github: string;
  yat: string;
  team: Department;
};

export interface IOnboardingDao {
  id: number;
  keyPeople: Array<IOnboardingMember>;
  content: string;
  video: string;
  logo: string;
  name: string;
  vision: string;
  mission: string;
  values: { title: string; content: string }[];
}

export const expertiseOptions = [
  { value: 'Entry', label: 'Entry-level' },
  { value: 'Intermediate', label: 'Intermediate' },
  { value: 'Mid', label: 'Mid-level' },
  { value: 'Senior', label: 'Senior' },
  { value: 'Superhuman', label: 'Superhuman' },
];

export const timeEstimateOptions = [
  { value: 'Hours', label: 'Hours' },
  { value: 'Days', label: 'Days' },
  { value: 'Weeks', label: 'Weeks' },
];

export const availabilityOptions = [
  { value: 'TenHours', label: '0 - 10 hrs/wk' },
  { value: 'TwentyHours', label: '10 - 20 hrs/wk' },
  { value: 'ThirtyHours', label: '20 - 30 hrs/wk' },
  { value: 'Fulltime', label: 'Fulltime' },
];

export const getOnboarding = async (dao: string): Promise<Response> => {
  return serverRequest(`daos/${dao}/onboarding`);
};
