import MainLayout from 'layouts/MainLayout';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    border: '1px solid #E5E5E5',
    backgroundColor: '#fff',
    borderRadius: '20px',
    padding: '46px 58px',
  },
});

export default function SquadsView(): JSX.Element {
  const styles = useStyles();
  return (
    <MainLayout>
      <div className={styles.container}>Squads</div>
    </MainLayout>
  );
}
