import { submitForReview } from 'api/opportunitiesApi';
import { uploadJson } from 'api/uploadApi';
import closeIcon from 'assets/close.svg';
import landscapeZoomedGirlIllustration from 'assets/landscapeZoomedGirlIllustration.png';
import uploadIcon from 'assets/upload.svg';
import { useWallet } from 'contexts/WalletContext';
import { useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import toast from 'react-hot-toast';
import { createUseStyles } from 'react-jss';
import useGlobalState from 'state';
import { sleep, truncate } from 'utils/utils';
import { encodeData, getUserSignature, stringToBytes } from 'web3/helpers';
import { IPFSFile, uploadFiles } from 'web3/ipfs';
import { SignatureFunction } from 'web3/metadata';

import Modal from './Modal';

const useStyles = createUseStyles({
  container: { maxWidth: '1034px', display: 'flex' },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '24px 40px',
  },
  close: { width: '24px', cursor: 'pointer' },
  title: {
    fontSize: '24px',
    lineHeight: '24px',
  },
  divider: { width: '100%', height: '1px', backgroundColor: '#303B5B' },
  description: {
    padding: '40px',
    fontSize: '16px',
    lineHeight: '21px',
    color: '#D1D3D5',
  },
  submitContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '24px 40px',
  },
  submit: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F9F9F9',
    color: '#000',
    fontSize: '16px',
    lineHeight: '16px',
    borderRadius: '100px',
    padding: '16px 24px',
  },
  inputTitle: {
    fontSize: '14px',
    color: '#F9F9F9',
    marginBottom: '8px',
  },
  input: {
    border: '1px solid #303B5B',
    padding: '16px',
    borderRadius: '8px',
    fontSize: '16px',
    lineHeight: '21.6px',
    backgroundColor: 'transparent',
    color: '#f9f9f9',
    width: '432px',
  },
  illustration: {
    width: '517px',
    minHeight: '511px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    flexShrink: 0,
  },
  fileInput: {
    width: '100%',
    border: '1px dashed #303B5B',
    padding: '24px',
    borderRadius: '8px',
    cursor: 'pointer',
    color: '#A4A6AB',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    userSelect: 'none',
  },
  upload: {
    textAlign: 'center',
    marginTop: '8px',
    fontSize: '12px',
    color: '#A4A6AB',
  },
  file: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '0.5px solid #303B5B',
    borderRadius: '8px',
    padding: '8px 16px',
    marginTop: '16px',
  },
});

export default function SubmitReviewModal({
  open,
  onClose,
  opportunityId,
}: {
  open: boolean;
  onClose(reload?: boolean): void;
  opportunityId: number | undefined;
}): JSX.Element {
  const styles = useStyles();
  const [loading, setLoading] = useState(false);
  const { shareConfig, chainId, provider } = useWallet();
  const { loggedInUser } = useGlobalState();
  const [oppId, setOppId] = useState(opportunityId);
  const [submitText, setSubmitText] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [files, setFiles] = useState<any[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (newFiles: any) => {
    setFiles([...files, ...newFiles]);
  };

  const onRemove = (index: number) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  useEffect(() => setOppId(opportunityId), [opportunityId]);

  const onSubmit = async () => {
    if (oppId && loggedInUser && provider && chainId) {
      setLoading(true);
      let filesHash;

      if (files.length > 0) {
        const hash = await uploadFiles(files);
        filesHash = hash?.map((file: IPFSFile) => file.ipfsUri);
      }

      const ipfsData = {
        message: submitText,
        attachments: filesHash,
        timestamp: new Date().getTime() / 1000,
      };
      try {
        //@ts-ignore
        const { ipfsHash } = await uploadJson(ipfsData);

        const offChainBytes = stringToBytes(ipfsHash);

        const data = {
          types: ['uint8', 'address', 'uint256', 'uint256[]', 'bytes[]'],
          values: [
            SignatureFunction.CompleteTasks,
            shareConfig?.escrowAddress,
            chainId,
            [oppId],
            [offChainBytes],
          ],
        };
        const encodedData = encodeData(data);
        try {
          const contributorSignature = await getUserSignature(
            encodedData,
            provider,
          );
          const [result] = await Promise.all([
            submitForReview({
              taskSubgraphId: `${shareConfig?.escrowAddress.toLowerCase()}-0x${oppId.toString(
                16,
              )}`,
              message: submitText,
              uploadedFiles: filesHash,
              chainId,
              contractAddress: shareConfig?.escrowAddress,
              signature: contributorSignature,
              encodedData,
            }),
            sleep(300),
          ]);

          if (result.status === 400) {
            setLoading(false);
            toast.error('Error');
          } else {
            setLoading(false);
            toast.success('Submitted!');
            onClose(true);
          }
        } catch (e) {
          setLoading(false);
          toast.error('Signature rejected');
        }
      } catch (e) {
        toast.error('Error uploading metadata');
        setLoading(false);
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      style={{ borderRadius: '8px', maxHeight: '90vh' }}
    >
      <div className={styles.container}>
        <div
          style={{ backgroundImage: `url(${landscapeZoomedGirlIllustration})` }}
          className={styles.illustration}
        />
        <div style={{ width: '100%' }}>
          <div className={styles.header}>
            <div className={styles.title}>Submit for review</div>
            <img
              src={closeIcon}
              alt="close"
              className={styles.close}
              onClick={() => onClose()}
            />
          </div>
          <div className={styles.divider} />
          <div className={styles.description}>
            <div className={styles.inputTitle}>Add a comment</div>
            <textarea
              className={styles.input}
              value={submitText}
              onChange={e => setSubmitText(e.target.value)}
              rows={4}
            />
            <div className={styles.inputTitle} style={{ marginTop: '24px' }}>
              Upload files
            </div>
            <FileUploader
              classes={styles.fileInput}
              handleChange={handleChange}
              label={'Drag & drop files or browse to choose'}
              name="file"
              multiple
            >
              <div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img src={uploadIcon} alt="upload" />
                </div>
                <div className={styles.upload}>
                  Drag & drop files or browse to choose
                </div>
              </div>
            </FileUploader>
            {files.map((file, i) => (
              <div
                key={`${file.name}-${i}`}
                className={styles.file}
                style={{ cursor: 'pointer' }}
                onClick={() => onRemove(i)}
              >
                <div>
                  <div style={{ fontSize: '14px', color: '#f9f9f9' }}>
                    {truncate(
                      // @ts-ignore
                      `${file.name.match(/([\w\d_-]*)\.?[^\\/]*$/i)[1]}${
                        //@ts-ignore
                        file.name.match(/\.[0-9a-z]+$/i)[0]
                      }`,
                      30,
                      true,
                    )}
                  </div>
                  <div
                    style={{
                      fontSize: '12px',
                      color: '#8D9096',
                      marginTop: '6px',
                    }}
                  >
                    {(file.size / 1000000).toFixed(2)} MB
                  </div>
                </div>
                <img src={closeIcon} alt="close" style={{ width: '20px' }} />
              </div>
            ))}
          </div>

          <div className={styles.divider} />
          <div className={styles.submitContainer}>
            <div
              className={styles.submit}
              style={{
                background: !submitText ? '#BBBCC0' : '#F9F9F9',
                cursor: !submitText ? 'not-allowed' : 'pointer',
              }}
              onClick={() => submitText && onSubmit()}
            >
              {loading ? 'Submitting...' : 'Submit'}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
