import { IDAO } from 'api/communitiesApi';
import wave from 'assets/wave.svg';
import { createUseStyles } from 'react-jss';

import OrgCard from './OrgCard';

const useStyles = createUseStyles({
  container: { marginTop: '57px' },
  orgCardsContainer: {
    marginTop: '47px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    '& > *': {
      marginRight: '16px',
      marginBottom: '56px',
    },
  },
  logoContainer: {
    padding: '40px 48px 24px 48px',
    border: '1px solid #303B5B',
    borderRadius: '8px',
    backgroundColor: '#202837',
    position: 'relative',
  },
  title: {
    color: '#f9f9f9',
    fontSize: '16px',
  },
  logo: {
    position: 'absolute',
    left: 'calc(50% - 24px)',
    top: '-24px',
    width: '48px',
  },
});

export default function CommunityTeam({ dao }: { dao: IDAO }): JSX.Element {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className={styles.logoContainer}>
          <img className={styles.logo} src={dao?.logo} alt="logo" />
          <div className={styles.title}>{dao?.name}</div>
        </div>
      </div>
      <img src={wave} alt="group" style={{ width: '100%', marginTop: '8px' }} />
      <div className={styles.orgCardsContainer}>
        {dao?.keyPeople.map((member, i) => (
          <OrgCard member={member} key={`${member.name}-${i}`} />
        ))}
      </div>
    </div>
  );
}
