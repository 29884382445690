import { providers } from 'ethers';
import { getGlobalInfo } from 'graphql/globalInfo';
import { GlobalInfoFragment } from 'graphql/types';
import { useGraphHealth } from 'hooks/useGraphHealth';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import toast from 'react-hot-toast';
import { GraphHealth, successStatus } from 'web3/graphHealth';
import Web3Modal from 'web3modal';

import { connectUser } from '../api/usersApi';
import useGlobalState from '../state';
import { authenticateWallet, clearToken, getExistingAuth } from '../web3/auth';
import { DEFAULT_NETWORK } from '../web3/constants';
import { NETWORK_NAMES } from '../web3/constants';
import { isSupportedChain } from '../web3/helpers';
import { switchChainOnMetaMask } from '../web3/metamask';
import { providerOptions } from '../web3/providerOptions';

const web3Modal = new Web3Modal({
  cacheProvider: true,
  providerOptions,
  theme: 'dark',
});

export type WalletContextType = {
  provider: providers.Web3Provider | null | undefined;
  chainId: number | null | undefined;
  address: string | null | undefined;
  ensName: string | null | undefined;
  authToken: string | null | undefined;
  connectWallet: (daoId?: number) => Promise<void>;
  disconnect: () => void;
  isConnecting: boolean;
  isConnected: boolean;
  isMetamask: boolean;
  shareConfig: GlobalInfoFragment | null;
  graphHealth: GraphHealth;
};

export const WalletContext = createContext<WalletContextType>({
  provider: null,
  chainId: null,
  address: null,
  authToken: null,
  ensName: null,
  connectWallet: async (daoId?: number) => undefined,
  disconnect: () => undefined,
  isConnecting: true,
  isConnected: false,
  isMetamask: false,
  shareConfig: null,
  graphHealth: successStatus,
});

const getAuthToken = async (
  ethersProvider: providers.Web3Provider | null,
): Promise<string | null> => {
  if (!ethersProvider) return null;
  let token = await getExistingAuth(ethersProvider);
  if (!token) {
    token = await authenticateWallet(ethersProvider);
  }
  return token;
};

type WalletStateType = {
  provider?: providers.Web3Provider | null;
  chainId?: number | null;
  address?: string | null;
  authToken?: string | null;
  ensName?: string | null;
};

const isMetamaskProvider = (
  provider: providers.Web3Provider | null | undefined,
) => provider?.connection?.url === 'metamask';

export const WalletProvider: React.FC = ({ children }) => {
  const [{ provider, chainId, address, authToken, ensName }, setWalletState] =
    useState<WalletStateType>({});

  const { loggedInUser, setLoggedInUser } = useGlobalState();

  const isConnected: boolean = useMemo(
    () =>
      !!provider && !!address && !!chainId && !!authToken && !!loggedInUser.id,
    [provider, address, chainId, authToken, loggedInUser],
  );

  const [isConnecting, setConnecting] = useState<boolean>(true);
  const isMetamask = useMemo(() => isMetamaskProvider(provider), [provider]);
  const [shareConfig, setShareConfig] = useState<GlobalInfoFragment | null>(
    null,
  );

  const disconnect = useCallback(async () => {
    web3Modal.clearCachedProvider();
    setWalletState({});
    setShareConfig(null);
    setLoggedInUser(null);
    clearToken();
  }, [setLoggedInUser]);

  const setWalletProvider = useCallback(
    async (prov, daoId) => {
      const ethersProvider = new providers.Web3Provider(prov);
      let network = (await ethersProvider.getNetwork()).chainId;

      if (!isSupportedChain(network)) {
        const success =
          isMetamaskProvider(ethersProvider) &&
          (await switchChainOnMetaMask(DEFAULT_NETWORK));
        if (!success) {
          const errorMsg = `Network not supported, please switch to ${NETWORK_NAMES[DEFAULT_NETWORK]}`;
          toast.error(errorMsg);
          throw new Error(errorMsg);
        }
        network = DEFAULT_NETWORK;
        window.location.reload();
      }
      const signerAddress = await ethersProvider.getSigner().getAddress();
      const signerName = await ethersProvider.lookupAddress(signerAddress);
      const signerAuthToken = await getAuthToken(ethersProvider);
      const [userResponse, globalInfo] = await Promise.all([
        connectUser(daoId),
        getGlobalInfo(),
      ]);
      const userData = await userResponse.json();
      setShareConfig(globalInfo);
      setLoggedInUser({ ...userData });
      setWalletState({
        provider: ethersProvider,
        chainId: network,
        address: signerAddress,
        authToken: signerAuthToken,
        ensName: signerName,
      });
    },
    [setLoggedInUser],
  );

  const connectWallet = useCallback(
    async daoId => {
      try {
        setConnecting(true);

        const modalProvider = await web3Modal.connect();

        await setWalletProvider(modalProvider, daoId);

        modalProvider.on('accountsChanged', () => {
          disconnect();
          window.location.reload();
        });
        modalProvider.on('chainChanged', () => {
          disconnect();
          window.location.reload();
        });
      } catch (web3Error) {
        // eslint-disable-next-line no-console
        console.error(web3Error);
        disconnect();
      } finally {
        setConnecting(false);
      }
    },
    [setWalletProvider, disconnect],
  );

  useEffect(() => {
    const load = async () => {
      if (web3Modal.cachedProvider) {
        await connectWallet(null);
      } else {
        setConnecting(false);
      }
    };
    load();
  }, [connectWallet]);

  const graphHealth = useGraphHealth();

  return (
    <WalletContext.Provider
      value={{
        provider,
        address: address?.toLowerCase(),
        chainId,
        authToken,
        connectWallet,
        isConnected,
        isConnecting,
        disconnect,
        isMetamask,
        ensName,
        shareConfig,
        graphHealth,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export const useWallet = (): WalletContextType => useContext(WalletContext);
