import { IOpportunity } from 'api/opportunitiesApi';
import makeBlockie from 'ethereum-blockies-base64';
import { CommunityLocation, ProfileLocation } from 'Locations';
import moment from 'moment';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import { taskFilters } from 'views/Opportunities';

import Chip from './Chip';
import Pill from './Pill';

const useStyles = createUseStyles({
  detailsContainer: {
    backgroundColor: '#202837',
    margin: '40px 0px',
    padding: '24px',
    borderRadius: '8px',
  },
  detailsRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  detailTitle: { fontSize: '14px', marginBottom: '8px' },
  detailText: { fontSize: '16px', color: '#BBBCC0' },
  divider: { width: '100%', height: '0.5px', backgroundColor: '#303B5B' },
  content: {
    color: '#BBBCC0',
    marginTop: '40px',
    lineHeight: '21.6px',
    '& a': {
      color: '#8C9097',
    },
  },
  userContainer: { display: 'flex', alignItems: 'center', fontSize: '16px' },
  userAvatar: {
    width: '24px',
    height: '24px',
    borderRadius: '24px',
    marginRight: '8px',
  },
  tagsContainer: { display: 'flex', flexWrap: 'wrap', marginRight: '-4px' },
});

export default function OpportunityOverview({
  opportunity,
}: {
  opportunity: IOpportunity | null;
}): JSX.Element {
  const styles = useStyles();

  return (
    <div>
      <div className={styles.detailsContainer}>
        <div className={styles.detailsRow}>
          <div style={{ width: '33%' }}>
            <div className={styles.detailTitle}>Experience Level</div>
            <div className={styles.detailText}>
              {opportunity?.expectedExperience}
            </div>
          </div>
          <div style={{ width: '33%' }}>
            <div className={styles.detailTitle}>Deadline</div>
            <div className={styles.detailText}>
              {moment(opportunity?.deadline).format('MMM DD, YYYY')}
            </div>
          </div>
          <div style={{ width: '33%' }}>
            <div className={styles.detailTitle}>Task Estimation</div>
            <div className={styles.detailText}>
              {opportunity?.timeEstimateValue}{' '}
              {opportunity?.timeEstimateValue === '1'
                ? opportunity?.timeEstimateUnit.replace(/s$/, '')
                : opportunity?.timeEstimateUnit}
            </div>
          </div>
        </div>
        <div className={styles.detailsRow} style={{ marginTop: '32px' }}>
          <div style={{ width: '33%' }}>
            <div className={styles.detailTitle}>Posted</div>
            <div className={styles.detailText}>
              {moment(opportunity?.createdAt).format('MMM DD, YYYY hh:mm a')}
            </div>
          </div>
          <div style={{ width: '33%' }}>
            <div className={styles.detailTitle}>Category</div>
            <div className={styles.detailText}>
              <div style={{ width: 'auto', display: 'inline-block' }}>
                <Pill
                  text={opportunity?.team || ''}
                  style={{
                    marginLeft: 0,
                    color: '#f9f9f9',
                    backgroundColor:
                      taskFilters.find(
                        filter => filter.name === opportunity?.team,
                      )?.color || '#673969',
                  }}
                />
              </div>
            </div>
          </div>
          <div style={{ width: '33%' }}>
            <div className={styles.detailTitle}>Tags</div>
            <div className={styles.detailText}>
              <div className={styles.tagsContainer}>
                {opportunity && opportunity?.tags.length > 0
                  ? opportunity?.tags.map((tag, i) => (
                      <Chip
                        key={`${tag}-${i}`}
                        text={tag}
                        style={{ marginRight: '4px' }}
                      />
                    ))
                  : '--'}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.detailsRow} style={{ marginTop: '32px' }}>
          <div style={{ width: '33%' }}>
            <div className={styles.detailTitle}>Community</div>
            <div className={styles.detailText}>
              {opportunity?.dao && (
                <div className={styles.userContainer}>
                  <Link to={CommunityLocation(opportunity.daoId)}>
                    <img
                      src={opportunity.dao.logo}
                      alt="avatar"
                      className={styles.userAvatar}
                    />
                  </Link>
                  <Link to={CommunityLocation(opportunity.daoId)}>
                    <div className={styles.detailText}>
                      {opportunity.dao.name}
                    </div>
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div style={{ width: '33%' }}>
            <div className={styles.detailTitle}>Contributor</div>
            <div className={styles.detailText}>
              {opportunity?.user?.wallet ? (
                <div className={styles.userContainer}>
                  <Link to={ProfileLocation(opportunity.user.wallet || '')}>
                    <img
                      src={makeBlockie(opportunity.user.wallet || '')}
                      alt="avatar"
                      className={styles.userAvatar}
                    />
                  </Link>
                  <Link to={ProfileLocation(opportunity.user.wallet || '')}>
                    <div className={styles.detailText}>
                      {opportunity.user.username}
                    </div>
                  </Link>
                </div>
              ) : (
                '--'
              )}
            </div>
          </div>
          <div style={{ width: '33%' }}>
            <div className={styles.detailTitle}>Reviewer</div>
            <div className={styles.detailText}>
              {opportunity?.reviewer ? (
                <div className={styles.userContainer}>
                  <Link to={ProfileLocation(opportunity.reviewer.wallet || '')}>
                    <img
                      src={makeBlockie(opportunity.reviewer.wallet || '')}
                      alt="avatar"
                      className={styles.userAvatar}
                    />
                  </Link>
                  <Link to={ProfileLocation(opportunity.reviewer.wallet || '')}>
                    <div className={styles.detailText}>
                      {opportunity.reviewer.username}
                    </div>
                  </Link>
                </div>
              ) : (
                '--'
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.divider} />
      <div
        className={styles.content}
        dangerouslySetInnerHTML={{ __html: opportunity?.content || '' }}
      />
    </div>
  );
}
