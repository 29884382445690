import { createUseStyles } from 'react-jss';

import arrowRight from '../assets/arrowRight.svg';

const useStyles = createUseStyles({
  cardTitle: {
    fontSize: '18px',
    fontWeight: 700,
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cardHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  cardLogoContainer: {
    width: '35px',
    height: '35px',
    backgroundColor: '#0A182F',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    marginRight: '12px',
  },
});

interface CardHeaderProps {
  imgSrc: string;
  title: string;
}

const CardHeader = ({ imgSrc, title }: CardHeaderProps): JSX.Element => {
  const styles = useStyles();

  return (
    <div className={styles.cardHeader}>
      <div className={styles.cardHeaderContainer}>
        <div className={styles.cardLogoContainer}>
          <img src={imgSrc} style={{ height: '100% ' }} alt="logo" />
        </div>
        <div className={styles.cardTitle}>{title}</div>
      </div>
      <img src={arrowRight} alt="go" style={{ marginLeft: '8px' }} />
    </div>
  );
};

export default CardHeader;
