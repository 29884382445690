import { branding } from 'design-system/colors';
import { createUseStyles } from 'react-jss';
import ReactTooltip from 'react-tooltip';

const useStyles = createUseStyles({
  tooltip: {
    opacity: '1 !important',
    padding: '8px 10px !important',
    fontSize: '14px !important',
  },
});

function Tooltip(): JSX.Element {
  const styles = useStyles();

  return (
    <ReactTooltip
      delayShow={100}
      delayUpdate={100}
      delayHide={200}
      backgroundColor={branding.purple}
      effect="solid"
      class={styles.tooltip}
    />
  );
}

export default Tooltip;
