import { IOnboardingDao } from 'api/onboardingApi';
import { createUseStyles } from 'react-jss';
import { Element } from 'react-scroll';

const useStyles = createUseStyles({
  container: {
    marginTop: '240px',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '88px',
  },
  content: {
    position: 'relative',
    maxWidth: '743px',
    textAlign: 'center',
    background: 'rgba(249, 249, 249, 0.1)',
    backdropFilter: 'blur(15px)',
    padding: '84px 80px 98px 80px',
    borderRadius: '8px',
  },
  title: {
    color: '#f9f9f9',
    fontSize: '32px',
    lineHeight: '32px',
  },
  dividerContainer: { display: 'flex', justifyContent: 'center' },
  divider: {
    margin: '32px 0px',
    backgroundColor: '#f9f9f9',
    height: '0.5px',
    width: '250px',
  },
  description: {
    color: '#f9f9f9',
    fontSize: '16px',
    lineHeight: '21.6px',
    textAlign: 'left',
  },
  logo: {
    position: 'absolute',
    width: '122px',
    height: '122px',
    borderRadius: '122px',
    left: 'calc(50% - 61px)',
    top: '-61px',
  },
});

export default function OnboardingIntroduction({
  dao,
}: {
  dao: IOnboardingDao | null;
}): JSX.Element {
  const styles = useStyles();
  return (
    <Element name={'introduction'}>
      <div className={styles.container}>
        <div className={styles.content}>
          <img src={dao?.logo} alt="logo" className={styles.logo} />
          <div className={styles.title}>{dao?.name}</div>
          <div className={styles.dividerContainer}>
            <div className={styles.divider} />
          </div>
          <div className={styles.description}>{dao?.content}</div>
        </div>
      </div>
    </Element>
  );
}
