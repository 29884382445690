import ReactSelect, { CSSObjectWithLabel } from 'react-select';

const selectStyles = {
  container: (base: CSSObjectWithLabel) => ({
    ...base,
    border: '1px solid #303B5B',
    backgroundColor: '#202837',
    color: '#202837',
    borderRadius: '8px',
    width: '100%',
    fontSize: '16px',
    //lineHeight: '19px',
  }),
  control: (base: CSSObjectWithLabel) => ({
    ...base,
    color: '#fff !important',
    border: 'none',
    backgroundColor: 'none',
    padding: '16px 16px',
    boxShadow: 'none',
    minHeight: 0,
  }),
  clearIndicator: (base: CSSObjectWithLabel) => ({
    ...base,
    //padding: 0,
    //paddingLeft: '8px',
    display: 'none',
  }),
  dropdownIndicator: (base: CSSObjectWithLabel) => ({
    ...base,
    padding: 0,
    paddingLeft: '8px',
  }),
  valueContainer: (base: CSSObjectWithLabel) => ({
    ...base,
    padding: 0,
  }),
  menuPortal: (base: CSSObjectWithLabel) => ({
    ...base,
    //zIndex: MODAL_ZINDEX + 1,
  }),
  input: (base: CSSObjectWithLabel) => ({
    ...base,
    color: '#fff !important',
  }),
  placeholder: (base: CSSObjectWithLabel) => ({
    ...base,
    color: '#fff !important',
  }),
  singleValue: (base: CSSObjectWithLabel) => ({
    ...base,
    color: '#fff !important',
  }),
};

export default function FilterSelect({
  options,
  onChange,
  value,
}: {
  options: { value: string; label: string }[];
  onChange(val: { value: string; label: string }): void;
  value: { value: string; label: string };
}): JSX.Element {
  return (
    <ReactSelect
      options={options}
      onChange={option => option && onChange(option)}
      value={options.find(c => c.value === value.value)}
      styles={selectStyles}
    />
  );
}
