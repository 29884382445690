import discordLight from 'assets/discordLight.svg';
import githubLight from 'assets/githubLight.svg';
import twitterLight from 'assets/twitterLight.svg';
import { createUseStyles } from 'react-jss';

import { ApplicantProps } from './DAOApplicant';

const useStyles = createUseStyles({
  socials: {
    display: 'flex',
    marginLeft: '16px',
    '& img': {
      width: '24px',
    },
  },
});

export default function ApplicantSocials({
  applicant,
}: {
  applicant: ApplicantProps;
}): JSX.Element {
  const styles = useStyles();
  return (
    <div className={styles.socials}>
      {applicant.user.discordId && (
        <a
          href={`https://discordapp.com/users/${applicant.user.discordId}`}
          target="_blank"
          rel="noreferrer"
        >
          <img src={discordLight} alt="discord" />
        </a>
      )}
      {applicant.user.twitter && (
        <a
          href={`https://twitter.com/${applicant.user.twitter}`}
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={twitterLight}
            alt="twitter"
            style={{ marginLeft: '16px' }}
          />
        </a>
      )}
      {applicant.user.github && (
        <a
          href={`https://github.com/${applicant.user.github}`}
          target="_blank"
          rel="noreferrer"
        >
          {' '}
          <img src={githubLight} alt="github" style={{ marginLeft: '16px' }} />
        </a>
      )}
    </div>
  );
}
