import { getDAO, IDAO } from 'api/communitiesApi';
import arrowLeft from 'assets/arrowLeft.svg';
import discordLight from 'assets/discordLight.svg';
import githubLight from 'assets/githubLight.svg';
import twitterLight from 'assets/twitterLight.svg';
import websiteLight from 'assets/websiteLight.svg';
import CommunityContributors from 'components/CommunityContributors';
import CommunityOverview from 'components/CommunityOverview';
import CommunityTeam from 'components/CommunityTeam';
import CommunityToken from 'components/CommunityToken';
import MainLayout from 'layouts/MainLayout';
import { DiscoverLocation } from 'Locations';
import { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
//import useGlobalState from 'state';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  sidebar: { width: '310px', flexShrink: 0 },
  content: { width: '100%', marginRight: '40px' },
  titleContainer: { display: 'flex', alignItems: 'center' },
  title: {
    marginLeft: '8px',
    fontSize: '24px',
    lineHeight: '24px',
    color: '#fff',
  },
  tabsContainer: {
    display: 'flex',
    borderBottom: '1px solid #303B5B',
    marginTop: '40px',
  },
  tab: {
    fontSize: '16px',
    paddingBottom: '24px',
    marginRight: '32px',
    cursor: 'pointer',
  },
  card: {
    width: '100%',
    backgroundColor: '#202837',
    textAlign: 'center',
    borderRadius: '8px',
    padding: '32px',
    color: '#f9f9f9',
  },
  logo: { width: '80px', borderRadius: '80px' },
  CTA: {
    padding: '12px 16px',
    backgroundColor: '#F9F9F9',
    borderRadius: '100px',
    color: '#1B212D',
    cursor: 'pointer',
    fontSize: '16px',
  },
});

export default function CommunityView({
  match,
}: RouteComponentProps<{ id: string }>): JSX.Element {
  const styles = useStyles();
  const [loading, setLoading] = useState(false);
  const [dao, setDao] = useState<null | IDAO>(null);
  //const { loggedInUser } = useGlobalState();

  const tabs = dao && [
    {
      label: 'About',
      component: <CommunityOverview dao={dao} />,
    },
    {
      label: 'Team structure',
      component: <CommunityTeam dao={dao} />,
    },
    {
      label: 'Contributors',
      component: <CommunityContributors dao={dao} />,
    },
    {
      label: 'Token',
      component: <CommunityToken dao={dao} />,
    },
  ];

  const [selectedTab, setSelectedTab] = useState(tabs && tabs[0].label);
  const fetchDao = async (id: string) => {
    const data = await getDAO(id);
    const result = await data.json();
    setDao(result);
  };
  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        await fetchDao(match.params.id);
      } finally {
        setLoading(false);
      }
    })();
  }, [match.params.id]);

  useEffect(() => {
    if (dao && tabs) {
      setSelectedTab(tabs[0].label);
    }
    //eslint-disable-next-line
  }, [dao]);

  //const joined = loggedInUser?.daoId === dao?.id;

  return (
    <MainLayout title={dao?.name || ''} loading={loading}>
      <div className={styles.container}>
        <div className={styles.content}>
          <Link to={DiscoverLocation} className={styles.titleContainer}>
            <img src={arrowLeft} alt="back" />
            <div className={styles.title}>{dao?.name}</div>
          </Link>
          <div className={styles.tabsContainer}>
            {tabs?.map((tab, i) => (
              <div
                key={`${tab.label}-${i}`}
                className={styles.tab}
                onClick={() => setSelectedTab(tab.label)}
                style={{
                  color: selectedTab === tab.label ? '#f9f9f9' : '#A4A6AB',
                  borderBottom:
                    selectedTab === tab.label ? '1px solid #f9f9f9' : 'none',
                }}
              >
                {tab.label}
              </div>
            ))}
          </div>
          {tabs && tabs.find(tab => tab.label === selectedTab)?.component}
        </div>
        <div className={styles.sidebar}>
          <div className={styles.card}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={dao?.logo} alt="dao" className={styles.logo} />
            </div>
            <div style={{ marginTop: '32px', fontSize: '24px' }}>
              {dao?.name}
            </div>
            <div
              style={{
                display: 'flex',
                marginTop: '24px',
                justifyContent: 'center',
              }}
            >
              {dao?.website && (
                <a href={dao?.website || ''} target="_blank" rel="noreferrer">
                  <img
                    src={websiteLight}
                    alt="website"
                    style={{ marginLeft: '16px' }}
                  />
                </a>
              )}
              {dao?.discord && (
                <a
                  href={`https://discord.com/invite/${dao?.discord}`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ marginLeft: '16px' }}
                >
                  <img src={discordLight} alt="discord" />
                </a>
              )}
              {dao?.twitter && (
                <a
                  href={`https://twitter.com/${dao?.twitter}`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ marginLeft: '16px' }}
                >
                  <img src={twitterLight} alt="twitter" />
                </a>
              )}
              {dao?.github && (
                <a
                  href={`https://github.com/${dao?.github}`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ marginLeft: '16px' }}
                >
                  <img src={githubLight} alt="github" />
                </a>
              )}
            </div>
            {/*joined && (
              <div
                style={{
                  display: 'flex',
                  marginTop: '48px',
                  justifyContent: 'center',
                }}
              >
                <div
                  className={styles.CTA}
                  style={{ cursor: joined ? 'not-allowed' : 'pointer' }}
                >
                  {joined ? 'Joined' : 'Joined Community'}
                </div>
              </div>
              )*/}
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
