const {
  REACT_APP_GOERLI_RPC_URL:
    GOERLI_RPC_URL = 'https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
  REACT_APP_GOERLI_SUBGRAPH_URL:
    GOERLI_SUBGRAPH_URL = 'https://api.thegraph.com/subgraphs/name/dan13ram/share-escrow-goerli',
  REACT_APP_DEFAULT_NETWORK,
} = process.env;

type CurrencyInfo = {
  [chainId: number]: {
    name: string;
    symbol: string;
    decimals: number;
  };
};

type CurrencyList = {
  [chainId: number]: {
    name: string;
    symbol: string;
    decimals: number;
    address: string;
  }[];
};

export const NETWORK_CURRENCIES: CurrencyInfo = {
  5: {
    decimals: 18,
    name: 'Ethereum',
    symbol: 'ETH',
  },
};

export const CURRENCY_LIST: CurrencyList = {
  5: [
    {
      name: 'Wrapped Ethereum',
      symbol: 'WETH',
      address: '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6',
      decimals: 18,
    },
    {
      name: 'USD Coin',
      symbol: 'USDC',
      address: '0x7f8F6E42C169B294A384F5667c303fd8Eedb3CF3',
      decimals: 6,
    },
  ],
};

type StringInfo = {
  [chainId: number]: string;
};

export const RPC_URLS: StringInfo = {
  5: GOERLI_RPC_URL,
};

export const NETWORK_NAMES: StringInfo = {
  5: 'Goerli Testnet',
};

export const NETWORK_LABELS: StringInfo = {
  5: 'Goerli',
};

export const EXPLORER_URLS: StringInfo = {
  5: 'https://goerli.etherscan.io',
};

export const SUBGRAPH_URLS: StringInfo = {
  5: GOERLI_SUBGRAPH_URL,
};

export const SUPPORTED_NETWORKS: Array<number> = [5];

export const DEFAULT_NETWORK = Number(REACT_APP_DEFAULT_NETWORK || 5);

export const DEFAULT_SUBGRAPH_URL =
  SUBGRAPH_URLS[DEFAULT_NETWORK] ||
  'https://api.thegraph.com/subgraphs/name/dan13ram/share-escrow-goerli';

export const DEFAULT_SUBGRAPH_NAME = DEFAULT_SUBGRAPH_URL.replace(
  'https://api.thegraph.com/subgraphs/name/',
  '',
);

export const SUBGRAPH_POLL_INTERVAL = 5000;

export const SUBGRAPH_NUM_RETRIES = 20;
