import { useCallback, useEffect, useState } from 'react';
import { singletonHook } from 'react-singleton-hook';
import {
  getGraphHealth,
  GraphHealth,
  setCachedSubgraphHealth,
  successStatus,
} from 'web3/graphHealth';

const GRAPH_HEALTH_UPDATE_INTERVAL = 10000;

const useGraphHealthImpl = (): GraphHealth => {
  const [graphHealth, setGraphHealth] = useState<GraphHealth>(successStatus);

  const updateGraphHealth = useCallback(
    () =>
      getGraphHealth().then(health => {
        setGraphHealth(health);
        setCachedSubgraphHealth(health);
      }),
    [],
  );

  useEffect(() => {
    const interval = setInterval(
      updateGraphHealth,
      GRAPH_HEALTH_UPDATE_INTERVAL,
    );
    return () => clearInterval(interval);
  }, [updateGraphHealth]);

  return graphHealth;
};

export const useGraphHealth = singletonHook(successStatus, useGraphHealthImpl);
