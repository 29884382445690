import { CSSProperties } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  tag: {
    padding: '6px 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100px',
    fontSize: '12px',
    color: '#fff',
    border: '0.5px solid #A4A6AB',
  },
});

export default function Chip({
  text,
  style,
}: {
  text: string;
  style?: CSSProperties;
}): JSX.Element {
  const styles = useStyles();
  return (
    <div style={{ ...style }} className={styles.tag}>
      {text}
    </div>
  );
}
