export const RootLocation = '/';

export const OnboardingLocationTemplate = '/onboarding/:dao';
export const OnboardingLocation = (dao: string): string => `/onboarding/${dao}`;

export const OpportunitiesLocation = '/opportunities';
export const DAOOpportunitiesLocation = '/dao-opportunities';

export const OpportunityLocationTemplate = '/opportunities/:id';
export const OpportunityLocation = (id: number): string =>
  `/opportunities/${id}`;

export const DAOOpportunityLocationTemplate = '/dao-opportunities/:id';
export const DAOOpportunityLocation = (id: number): string =>
  `/dao-opportunities/${id}`;

export const DashboardLocation = '/dashboard';

export const SquadsLocation = '/squads';

export const CommunitiesLocation = '/communities';

export const ProfileLocationTemplate = '/profile/:wallet';
export const ProfileLocation = (wallet: string): string => `/profile/${wallet}`;

export const CommunityLocationTemplate = '/community/:id';
export const CommunityLocation = (id: number): string => `/community/${id}`;

export const CreateOpportunityLocation = '/create-opportunity';

export const SignUpLocation = `/sign-up`;

export const DiscordAuthLocation = '/auth/discord';

export const TasksLocation = '/tasks';

export const ManageLocation = '/manage';

export const ContributorsLocation = '/contributors';

export const SettingsLocation = '/settings';

export const DiscoverLocation = '/discover';
