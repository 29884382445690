import {
  getTasksMetadata,
  // IActivity,
  // IAnalytics,
  IOpportunity,
} from 'api/opportunitiesApi';
import bannerBackground from 'assets/bannerBackground.png';
import bannerIllustration from 'assets/bannerIllustration.png';
import chevron from 'assets/chevron.svg';
import ColorOpportunityCard from 'components/ColorOpportunityCard';
import { OpportunityCard } from 'components/OpportunityCard';
import { useWallet } from 'contexts/WalletContext';
//import makeBlockie from 'ethereum-blockies-base64';
import { getAllTasks, getReviewerTasks } from 'graphql/tasks';
import MainLayout, { isDAO } from 'layouts/MainLayout';
import {
  //CommunityLocation,
  OpportunitiesLocation,
  //OpportunityLocation,
  //ProfileLocation,
} from 'Locations';
//import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import useGlobalState from 'state';
import { buildTask } from 'utils/tasks';
import {
  clearBannerToken,
  getBannerTokenFromStore,
  setBannerTokenInStore,
} from 'web3/auth';

const useStyles = createUseStyles({
  titleContainer: {
    display: 'flex',
    borderRadius: '16px',
    filter: 'drop-shadow(7px 6px 30px rgba(0, 0, 0, 0.25))',
    width: '100%',
    position: 'relative',
  },
  statTitle: { fontSize: '40px', color: '#fff' },
  statSubtitle: { fontSize: '16px', color: '#fff', marginTop: '16px' },
  statDivider: {
    height: '45px',
    width: '1px',
    borderLeft: '1px solid #39485C',
  },
  statsContainer: {
    width: '100%',
    backgroundColor: '#1E2635',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: '40px',
  },
  statsItem: { width: '33%', padding: '24px' },
  activityContainer: { flexShrink: 0, width: '300px', marginLeft: '40px' },
  opportunitiesTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginTop: '40px',
  },
  title: { color: '#fff', fontSize: '24px' },
  viewAll: { color: '#A4A6AB', cursor: 'pointer', fontSize: '14px' },
  opportunity: { width: '100%', padding: '28px 26px' },
  activityItem: {
    marginTop: '32px',
    display: 'flex',
    alignItems: 'flex-start',
    color: '#fff',
  },
  activityTitle: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '18px',
    color: '#fff',
  },
  activityDate: { fontSize: '12px', color: '#767A81', marginTop: '8px' },
  activityContent: { fontSize: '14px', marginTop: '8px', fontWeight: 300 },
  activityAvatar: {
    width: '24px',
    height: '24px',
    borderRadius: '24px',
    marginRight: '16px',
  },
  DAOTasksContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: '56px',
  },
  DAOTasksHeader: {
    display: 'flex',
    alignItems: 'flex-end',
    borderBottom: '1px solid #673969',
    paddingBottom: '16px',
  },
  DAOTasksTitle: { marginRight: '8px', fontSize: '16px', color: '#F9F9F9' },
  DAOTasksHelper: { fontSize: '12px', color: '#767A81' },
  bannerIllustration: {
    width: '413px',
    flexShrink: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderTopRightRadius: '16px',
    borderBottomRightRadius: '16px',
  },
  bannerBackground: {
    width: '100%',
    padding: '42px 32px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderTopLeftRadius: '16px',
    borderBottomLeftRadius: '16px',
  },
  bannerTitle: {
    fontFamily: 'eiko',
    fontSize: '40px',
    lineHeight: '40px',
    color: '#fff',
    fontWeight: 100,
    maxWidth: '386px',
  },
  bannerDescription: {
    marginTop: '24px',
    fontSize: '20px',
    lineHeight: '28px',
    color: '#fff',
    fontWeight: 100,
  },
  bannerCTA: {
    borderRadius: '100px',
    textAlign: 'center',
    color: '#1B212D',
    fontSize: '16px',
    cursor: 'pointer',
    padding: '18px 24px',
    backgroundColor: '#fff',
    marginTop: '40px',
    display: 'inline-block',
  },
  bannerHide: {
    position: 'absolute',
    top: '18px',
    right: '18px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    padding: '8px 16px',
    color: '#fff',
    fontSize: '14px',
    background: 'rgba(0, 0, 0, 0.1)',
    backdropFilter: 'blur(5px)',
    borderRadius: '100px',
    userSelect: 'none',
  },
});

export default function DashboardView(): JSX.Element {
  const styles = useStyles();
  const [opportunities, setOpportunities] = useState<null | IOpportunity[]>(
    null,
  );
  const [DAOOpportunities, setDAOOpportunities] = useState<null | {
    available: IOpportunity[];
    inReview: IOpportunity[];
  }>(null);
  //const [activities, setActivities] = useState<null | IActivity[]>(null);
  //const [analytics, setAnalytics] = useState<null | IAnalytics>(null);
  const [hidden, setHidden] = useState(
    getBannerTokenFromStore() ? true : false,
  );
  const [loading, setLoading] = useState(false);
  const { address, chainId } = useWallet();
  const { loggedInUser } = useGlobalState();

  const fetchDashboard = useCallback(async () => {
    const DAOUser = isDAO(loggedInUser);
    if (DAOUser && address && chainId) {
      let rawTasks = await getReviewerTasks(address);
      const metadataArray = rawTasks.map(result => {
        return {
          subgraphId: result.id,
          contributorAddress: result.contributor?.address,
          reviewerAddress: result.reviewer.address,
        };
      });
      const resultsMetadataResponse = await getTasksMetadata({
        chainId,
        tasks: metadataArray,
      });
      const resultsMetadata = await resultsMetadataResponse.json();
      rawTasks = rawTasks.map(task => {
        return { ...task, ...resultsMetadata[task.id] };
      });
      const tasks = rawTasks.map(buildTask);
      const finalResult: {
        available: IOpportunity[];
        inReview: IOpportunity[];
      } = {
        available: [],
        inReview: [],
      };
      tasks.forEach(task => {
        if (task.status === 'Available') {
          finalResult.available.push(task);
        } else if (task.status === 'InReview') {
          finalResult.inReview.push(task);
        }
      });
      setDAOOpportunities(finalResult);
    } else if (!DAOUser && chainId) {
      let rawTasks = await getAllTasks();
      const metadataArray = rawTasks.map(result => {
        return {
          subgraphId: result.id,
          contributorAddress: result.contributor?.address,
          reviewerAddress: result.reviewer.address,
        };
      });
      const resultsMetadataResponse = await getTasksMetadata({
        chainId,
        tasks: metadataArray,
      });
      const resultsMetadata = await resultsMetadataResponse.json();
      rawTasks = rawTasks.map(task => {
        return { ...task, ...resultsMetadata[task.id] };
      });
      const tasks = rawTasks
        .map(rawTask => buildTask(rawTask))
        .filter(task => task.status === 'Available');
      setOpportunities(tasks);
    } else {
      let rawTasks = await getAllTasks();
      const metadataArray = rawTasks.map(result => {
        return {
          subgraphId: result.id,
          contributorAddress: result.contributor?.address,
          reviewerAddress: result.reviewer.address,
        };
      });
      const resultsMetadataResponse = await getTasksMetadata({
        chainId: parseFloat(process.env.REACT_APP_DEFAULT_NETWORK || '5') || 5,
        tasks: metadataArray,
      });
      const resultsMetadata = await resultsMetadataResponse.json();
      rawTasks = rawTasks.map(task => {
        return { ...task, ...resultsMetadata[task.id] };
      });
      const tasks = rawTasks
        .map(rawTask => buildTask(rawTask))
        .filter(task => task.status === 'Available');
      setOpportunities(tasks);
    }
    //setActivities(data.activities);
    //setAnalytics(data.analytics);
  }, [loggedInUser, address, chainId]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        await fetchDashboard();
      } finally {
        setLoading(false);
      }
    })();
  }, [fetchDashboard, loggedInUser]);

  const DAOuser = isDAO(loggedInUser);

  const toggleHidden = () => {
    if (!hidden) {
      setBannerTokenInStore('true');
    } else {
      clearBannerToken();
    }
    setHidden(!hidden);
  };

  return (
    <MainLayout title={'Dashboard'} loading={loading}>
      <div>
        {!DAOuser && (
          <div className={styles.titleContainer}>
            <div
              className={styles.bannerHide}
              onClick={() => toggleHidden()}
              style={{ top: hidden ? '20px' : '18px' }}
            >
              {hidden ? 'Show' : 'Hide'}
              <img
                src={chevron}
                alt="chevron"
                style={{ transform: hidden ? 'rotate(-180deg)' : 'none' }}
              />
            </div>
            <div
              className={styles.bannerBackground}
              style={{
                backgroundImage: `url(${bannerBackground})`,
                padding: hidden ? '24px' : '42px 32px',
                borderTopRightRadius: hidden ? '16px' : 0,
                borderBottomRightRadius: hidden ? '16px' : 0,
              }}
            >
              {!hidden && (
                <div className={styles.bannerTitle}>
                  Exploring a Brand New World
                </div>
              )}
              <div
                className={styles.bannerDescription}
                style={{
                  marginTop: hidden ? '0' : '24px',
                  fontSize: hidden ? '18px' : '20px',
                  lineHeight: hidden ? '135%' : '28px',
                }}
              >
                {hidden
                  ? 'A Brand New World'
                  : 'Learn about our multiyear journey that led us to build Share'}
              </div>
              {!hidden && (
                <a
                  href="https://mirror.xyz/bigimot.eth/LykankLHeDkgsOkxqa58F5f0GYz7zS7kFrdvs9Gatso"
                  target="_blank"
                  rel="noreferrer"
                  className={styles.bannerCTA}
                >
                  Read More
                </a>
              )}
            </div>
            {!hidden && (
              <div
                className={styles.bannerIllustration}
                style={{ backgroundImage: `url(${bannerIllustration})` }}
              />
            )}
          </div>
        )}
        <div
          className={styles.contentContainer}
          style={{ marginTop: DAOuser ? 0 : '40px' }}
        >
          <div style={{ width: '100%' }}>
            {loggedInUser && (
              <div className={styles.statsContainer}>
                <div className={styles.statsItem}>
                  <div>
                    <div className={styles.statTitle}>
                      $
                      {/*DAOuser
                        ? analytics?.monthly?.feesPaid?.toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 0,
                            },
                          )
                        : analytics?.monthly?.feesEarned?.toLocaleString(
                            undefined,
                            { minimumFractionDigits: 0 },
                        )*/}
                    </div>
                    <div className={styles.statSubtitle}>
                      {DAOuser ? 'Paid' : 'Earned'}
                    </div>
                  </div>
                </div>
                <div className={styles.statDivider} />
                <div className={styles.statsItem}>
                  <div style={{ marginLeft: '8px' }}>
                    <div className={styles.statTitle}>
                      {/*DAOuser
                        ? analytics?.monthly.tasksCompleted
                      : analytics?.monthly.tasksInProgress*/}
                    </div>
                    <div className={styles.statSubtitle}>
                      {DAOuser ? 'Completed Tasks' : 'In Progress Tasks'}
                    </div>
                  </div>
                </div>
                <div className={styles.statDivider} />
                <div className={styles.statsItem}>
                  <div style={{ marginLeft: '8px' }}>
                    <div className={styles.statTitle}>
                      {/*DAOuser
                        ? analytics?.monthly.newContributors
                    : analytics?.monthly.tasksCompleted*/}
                    </div>
                    <div className={styles.statSubtitle}>
                      {DAOuser ? 'New Contributors' : 'Completed Tasks'}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!DAOuser ? (
              <>
                <div
                  className={styles.opportunitiesTitleContainer}
                  style={{ marginTop: loggedInUser ? '40px' : 0 }}
                >
                  <div className={styles.title}>
                    Opportunities you might like
                  </div>
                  <Link to={OpportunitiesLocation} className={styles.viewAll}>
                    View all
                  </Link>
                </div>
                <div>
                  {opportunities?.map((opp, i) => (
                    <OpportunityCard
                      opportunity={opp}
                      key={`${opp.id}-${i}`}
                      style={{ marginTop: '32px' }}
                    />
                  ))}
                </div>
              </>
            ) : (
              <div className={styles.DAOTasksContainer}>
                <div style={{ marginRight: '24px', width: '100%' }}>
                  <div className={styles.DAOTasksHeader}>
                    <div className={styles.DAOTasksTitle}>
                      Awaiting Assignment
                    </div>
                    <div className={styles.DAOTasksHelper}>
                      {DAOOpportunities?.available?.length}
                    </div>
                  </div>
                  {DAOOpportunities?.available?.map((opp, i) => (
                    <ColorOpportunityCard
                      key={`${opp.id}-${i}`}
                      opportunity={opp}
                      style={{ marginTop: '16px' }}
                    />
                  ))}
                </div>
                <div style={{ width: '100%' }}>
                  <div
                    className={styles.DAOTasksHeader}
                    style={{ borderBottom: '1px solid #9D6027' }}
                  >
                    <div className={styles.DAOTasksTitle}>Awaiting Review</div>
                    <div className={styles.DAOTasksHelper}>
                      {DAOOpportunities?.inReview?.length}
                    </div>
                  </div>
                  {DAOOpportunities?.inReview?.map((opp, i) => (
                    <ColorOpportunityCard
                      key={`${opp.id}-${i}`}
                      opportunity={opp}
                      style={{ marginTop: '16px' }}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className={styles.activityContainer}>
            <div className={styles.title} style={{ paddingBottom: '16px' }}>
              Latest Activity
            </div>
            {/*activities?.map((item, i) => (
              <div key={`${item.title}-${i}`} className={styles.activityItem}>
                <Link
                  to={
                    item.target.author.kind === 'User'
                      ? ProfileLocation(item.target.author.id)
                      : item.target.author.kind === 'DAO'
                      ? CommunityLocation(item.target.author.id)
                      : OpportunityLocation(item.target.author.id)
                  }
                >
                  <img
                    className={styles.activityAvatar}
                    src={item.image ? item.image : makeBlockie(item.wallet)}
                    alt="avatar"
                  />
                </Link>
                <Link
                  to={
                    item.target.content.kind === 'User'
                      ? ProfileLocation(item.target.content.id)
                      : item.target.content.kind === 'DAO'
                      ? CommunityLocation(item.target.content.id)
                      : OpportunityLocation(item.target.content.id)
                  }
                >
                  <div>
                    <div className={styles.activityTitle}>{item.content}</div>
                    <div className={styles.activityDate}>
                      {moment(item.date).format('hh:mma DD/MM/YYYY')}
                    </div>
                  </div>
                </Link>
              </div>
                ))*/}
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
