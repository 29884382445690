import { getTasksMetadata, IOpportunity } from 'api/opportunitiesApi';
import createIcon from 'assets/create.svg';
import searchIcon from 'assets/search.svg';
import ColorOpportunityCard from 'components/ColorOpportunityCard';
import FilterSelect from 'components/FilterSelect';
import { OpportunityCard } from 'components/OpportunityCard';
import { useWallet } from 'contexts/WalletContext';
import { getReviewerTasks } from 'graphql/tasks';
import MainLayout, { isDAO } from 'layouts/MainLayout';
import { CreateOpportunityLocation, DashboardLocation } from 'Locations';
import { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Link, useHistory } from 'react-router-dom';
import useGlobalState from 'state';
import { buildTask } from 'utils/tasks';

import { filterSelectOptions } from './Opportunities';

const tabs = ['Active', 'Completed', 'Cancelled'];

const useStyles = createUseStyles({
  tabsContainer: {
    display: 'flex',
    borderBottom: '1px solid #303B5B',
  },
  tab: {
    paddingBottom: '24px',
    marginRight: '32px',
    fontSize: '16px',
    cursor: 'pointer',
  },
  categoriesContainer: { display: 'flex', marginTop: '40px' },
  category: { width: '31%', marginRight: '24px' },
  titleFlex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    paddingBottom: '22px',
  },
  title: { color: '#fff', fontSize: '20px' },
  titleHelper: { color: '#767A81', fontSize: '12px', marginLeft: '8px' },
  import: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '100px',
    border: '0.5px solid #2C3C6A',
    padding: '6px 20px',
    fontSize: '12px',
    color: '#f9f9f9',
    cursor: 'pointer',
    userSelect: 'none',
  },
  sidebar: { flexShrink: 0, marginLeft: '40px', width: '310px' },
  sidebarTitle: { fontSize: '24px', color: '#fff', marginBottom: '16px' },
  sidebarDescription: {
    fontSize: '14px',
    lineHeight: '18.9px',
    color: '#BBBCC0',
  },
  searchInputContainer: {
    marginTop: '32px',
    display: 'flex',
  },
  searchInput: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #303B5B',
    backgroundColor: '#202837',
    borderRadius: '8px',
  },
  searchInputIcon: { padding: '14px 16px', flexShrink: 0 },
  searchInputText: {
    color: '#fff',
    fontSize: '16px',
    height: '100%',
    width: '100%',
    background: 'transparent',
    border: 'none',
  },
  filterSelect: { marginLeft: '24px', flexShrink: 0, width: '180px' },
  amount: { marginTop: '24px', color: '#A4A6AB', fontSize: '14px' },
  emptyCard: {
    marginTop: '24px',
    padding: '44px 57px',
    borderRadius: '8px',
    textAlign: 'center',
    color: '#767A81',
    fontSize: '14px',
  },
});

export default function ManageView(): JSX.Element {
  const styles = useStyles();
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [opportunities, setOpportunities] = useState<null | {
    available: IOpportunity[];
    inProgress: IOpportunity[];
    inReview: IOpportunity[];
    completed: IOpportunity[];
    cancelled: IOpportunity[];
  }>(null);
  const [loading, setLoading] = useState(false);
  const { loggedInUser } = useGlobalState();
  const { address, chainId } = useWallet();
  const [filterSelect, setFilterSelect] = useState<{
    value: string;
    label: string;
  }>(filterSelectOptions[0]);
  const [searchText, setSearchText] = useState('');
  const history = useHistory();

  useEffect(() => {
    (async () => {
      if (loggedInUser && chainId) {
        if (isDAO(loggedInUser)) {
          setLoading(true);
          try {
            if (!address) return;
            let results = await getReviewerTasks(address);
            const metadataArray = results.map(result => {
              return {
                subgraphId: result.id,
                contributorAddress: result.contributor?.address,
                reviewerAddress: result.reviewer.address,
              };
            });
            const resultsMetadataResponse = await getTasksMetadata({
              chainId,
              tasks: metadataArray,
            });
            const resultsMetadata = await resultsMetadataResponse.json();
            results = results.map(task => {
              return { ...task, ...resultsMetadata[task.id] };
            });
            const tasks = results.map(result => buildTask(result));
            const finalResult: {
              available: IOpportunity[];
              inProgress: IOpportunity[];
              inReview: IOpportunity[];
              completed: IOpportunity[];
              cancelled: IOpportunity[];
            } = {
              available: [],
              inProgress: [],
              inReview: [],
              completed: [],
              cancelled: [],
            };
            tasks.forEach(task => {
              if (task.status === 'Available') {
                finalResult.available.push(task);
              } else if (task.status === 'InProgress') {
                finalResult.inProgress.push(task);
              } else if (task.status === 'InReview') {
                finalResult.inReview.push(task);
              } else if (task.status === 'Completed') {
                finalResult.completed.push(task);
              } else if (task.status === 'Cancelled') {
                finalResult.cancelled.push(task);
              }
            });
            setOpportunities(finalResult);
          } finally {
            setLoading(false);
          }
        } else {
          history.push(DashboardLocation);
        }
      }
    })();
    //eslint-disable-next-line
  }, [loggedInUser, address, chainId]);

  const selectedOpportunities =
    selectedTab === 'Completed'
      ? opportunities?.completed
      : selectedTab === 'Cancelled'
      ? opportunities?.cancelled
      : null;

  const filteredOpportunities = searchText
    ? selectedOpportunities?.filter(
        opp => opp.title.toLowerCase().search(searchText.toLowerCase()) !== -1,
      )
    : selectedOpportunities;

  const finalOpportunities = filteredOpportunities?.sort((a, b) =>
    filterSelect.value === 'newest'
      ? //@ts-ignore
        new Date(a.createdAt) - new Date(b.createdAt)
      : //@ts-ignore
        new Date(b.createdAt) - new Date(a.createdAt),
  );

  return (
    <MainLayout title={'Manage'} loading={loading}>
      <div
        style={{
          display: selectedTab !== 'Active' ? 'flex' : 'block',
          alignItems: 'flex-start',
        }}
      >
        <div style={{ width: '100%' }}>
          <div className={styles.tabsContainer}>
            {tabs.map((tab, i) => (
              <div
                key={`${tab}-${i}`}
                style={{
                  borderBottom:
                    selectedTab === tab ? '1px solid #F9F9F9' : 'none',
                  color: selectedTab === tab ? '#F9F9F9' : '#A4A6AB',
                }}
                onClick={() => setSelectedTab(tab)}
                className={styles.tab}
              >
                {tab}
              </div>
            ))}
          </div>
          {selectedTab === 'Active' ? (
            <div className={styles.categoriesContainer}>
              <div className={styles.category}>
                <div
                  className={styles.titleContainer}
                  style={{
                    borderBottom: '1px solid #673969',
                    display: 'block',
                    paddingBottom: '14px',
                  }}
                >
                  <div className={styles.titleFlex}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                      }}
                    >
                      <div className={styles.title}>Opportunities</div>
                      <div className={styles.titleHelper}>
                        {opportunities?.available?.length}
                      </div>
                    </div>
                    <Link to={CreateOpportunityLocation}>
                      <img src={createIcon} alt="plus" />
                    </Link>
                  </div>
                </div>
                {opportunities?.available &&
                opportunities?.available?.length > 0 ? (
                  opportunities?.available.map((opp, i) => (
                    <ColorOpportunityCard
                      key={`${opp.id}-${i}`}
                      opportunity={opp}
                      style={{ marginTop: '24px' }}
                    />
                  ))
                ) : (
                  <div
                    style={{
                      backgroundColor: '#2A2639',
                    }}
                    className={styles.emptyCard}
                  >
                    No opportunities for assignment
                  </div>
                )}
              </div>
              <div className={styles.category}>
                <div
                  className={styles.titleContainer}
                  style={{ borderBottom: '1px solid #D6AA3C' }}
                >
                  <div className={styles.title}>In Progress</div>
                  <div className={styles.titleHelper}>
                    {opportunities?.inProgress?.length}
                  </div>
                </div>
                {opportunities?.inProgress &&
                opportunities?.inProgress.length > 0 ? (
                  opportunities?.inProgress?.map((opp, i) => (
                    <ColorOpportunityCard
                      key={`${opp.id}-${i}`}
                      opportunity={opp}
                      style={{ marginTop: '24px' }}
                    />
                  ))
                ) : (
                  <div
                    style={{
                      backgroundColor: '#342F2C',
                    }}
                    className={styles.emptyCard}
                  >
                    No opportunities in progress
                  </div>
                )}
              </div>
              <div className={styles.category}>
                <div
                  className={styles.titleContainer}
                  style={{ borderBottom: '1px solid #9D6027' }}
                >
                  <div className={styles.title}>In Review</div>
                  <div className={styles.titleHelper}>
                    {opportunities?.inReview?.length}
                  </div>
                </div>
                {opportunities?.inReview &&
                opportunities?.inReview.length > 0 ? (
                  opportunities?.inReview?.map((opp, i) => (
                    <ColorOpportunityCard
                      key={`${opp.id}-${i}`}
                      opportunity={opp}
                      style={{ marginTop: '24px' }}
                    />
                  ))
                ) : (
                  <div
                    style={{
                      backgroundColor: '#302928',
                    }}
                    className={styles.emptyCard}
                  >
                    No opportunities in review
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div style={{ marginTop: '40px' }}>
              <div className={styles.searchInputContainer}>
                <div className={styles.searchInput}>
                  <div className={styles.searchInputIcon}>
                    <img src={searchIcon} alt="search" />
                  </div>
                  <input
                    className={styles.searchInputText}
                    placeholder={'Search'}
                    value={searchText}
                    onChange={e => setSearchText(e.target.value)}
                  />
                </div>
                <div className={styles.filterSelect}>
                  <FilterSelect
                    options={filterSelectOptions}
                    value={filterSelect}
                    onChange={val => setFilterSelect(val)}
                  />
                </div>
              </div>
              <div className={styles.amount}>
                {finalOpportunities?.length || 0} opportunit
                {finalOpportunities?.length !== 1 ? 'ies' : 'y'} found
              </div>
              <div
                style={{
                  marginTop: '40px',
                }}
              >
                {finalOpportunities?.map((opp, i) => (
                  <OpportunityCard
                    opportunity={opp}
                    key={`${opp.id}-${i}`}
                    style={{ marginBottom: '16px' }}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
        {selectedTab !== 'Active' && (
          <div className={styles.sidebar}>
            <div className={styles.sidebarTitle}>
              {selectedTab} Opportunities
            </div>
            <div className={styles.sidebarDescription}>
              {selectedTab === 'Cancelled'
                ? `There are ${
                    opportunities?.cancelled?.length || 'no'
                  } cancelled opportunities.`
                : `There are ${
                    opportunities?.completed?.length || 'no'
                  } completed opportunities.`}
            </div>
          </div>
        )}
      </div>
    </MainLayout>
  );
}
