import { getTaskMetadata } from 'api/opportunitiesApi';
import { ethers } from 'ethers';

import { client } from './client';
import {
  GetContributorTasksDocument,
  GetContributorTasksQuery,
  GetContributorTasksQueryVariables,
  GetReviewerTasksDocument,
  GetReviewerTasksQuery,
  GetReviewerTasksQueryVariables,
  GetTaskDocument,
  GetTaskQuery,
  GetTaskQueryVariables,
  GetTasksDocument,
  GetTasksQuery,
  GetTasksQueryVariables,
  TaskInfoFragment,
} from './types';

export const getAllTasks = async (
  limit = 1000, // max returned in a single call is 1000
): Promise<TaskInfoFragment[]> => {
  const { data, error } = await client
    .query<GetTasksQuery, GetTasksQueryVariables>(GetTasksDocument, {
      limit,
    })
    .toPromise();
  if (!data) {
    if (error) {
      throw error;
    }
    return [];
  }
  return data.tasks;
};

export const getTask = async (
  escrowAddress: string,
  taskIndex: number,
  chainId: number,
): Promise<TaskInfoFragment | null> => {
  const taskId = `${escrowAddress.toLowerCase()}-0x${taskIndex.toString(16)}`;
  const { data, error } = await client
    .query<GetTaskQuery, GetTaskQueryVariables>(GetTaskDocument, {
      taskId,
    })
    .toPromise();
  if (!data) {
    if (error) {
      throw error;
    }
    return null;
  }
  const taskMetadata = await getTaskMetadata({
    taskSubgraphId: `${escrowAddress.toLowerCase()}-0x${taskIndex.toString()}`,
    chainId: chainId,
    reviewerAddress: data.task?.reviewer.address || '',
    contributorAddress: data.task?.contributor?.address || '',
  });
  const taskData = await taskMetadata.json();
  //@ts-ignore
  data.task.applicants = taskData.applicants;
  //@ts-ignore
  data.task.dao = taskData.dao;
  //@ts-ignore
  data.task.reviewer = taskData.reviewer;
  //@ts-ignore
  data.task.contributor = taskData.contributor;
  //@ts-ignore
  data.task.submissions = taskData.submissions;
  //@ts-ignore
  data.task.cancellation = taskData.cancellation;

  return data.task ?? null;
};

export const getReviewerTasks = async (
  userAddress: string,
): Promise<TaskInfoFragment[]> => {
  if (!ethers.utils.isAddress(userAddress)) return [];

  const { data, error } = await client
    .query<GetReviewerTasksQuery, GetReviewerTasksQueryVariables>(
      GetReviewerTasksDocument,
      {
        userAddress: userAddress.toLowerCase(),
        limit: 1000,
      },
    )
    .toPromise();
  if (!data) {
    if (error) {
      throw error;
    }
    return [];
  }
  return data.tasks;
};

export const getContributorTasks = async (
  userAddress: string,
): Promise<TaskInfoFragment[]> => {
  if (!ethers.utils.isAddress(userAddress)) return [];

  const { data, error } = await client
    .query<GetContributorTasksQuery, GetContributorTasksQueryVariables>(
      GetContributorTasksDocument,
      {
        userAddress: userAddress.toLowerCase(),
        limit: 1000,
      },
    )
    .toPromise();
  if (!data) {
    if (error) {
      throw error;
    }
    return [];
  }
  return data.tasks;
};
