import { addToMailingList } from 'api/usersApi';
import hamburgerIcon from 'assets/hamburger.svg';
import joinCloseIcon from 'assets/joinClose.svg';
import landingBackground1 from 'assets/landingBackground1.png';
import landingBackground1Mobile from 'assets/landingBackground1Mobile.png';
import landingBackground2 from 'assets/landingBackground2.png';
import landingBackground2Mobile from 'assets/landingBackground2Mobile.png';
import landingBackground3 from 'assets/landingBackground3.png';
import landingBackground3Mobile from 'assets/landingBackground3Mobile.png';
import landingBackground4 from 'assets/landingBackground4.png';
import landingBackground4Mobile from 'assets/landingBackground4Mobile.png';
import landingBackground5 from 'assets/landingBackground5.png';
import landingBackground5Mobile from 'assets/landingBackground5Mobile.png';
import landingBackground6 from 'assets/landingBackground6.png';
import landingBackground6Mobile from 'assets/landingBackground6Mobile.png';
import landingBackground7 from 'assets/landingBackground7.png';
import landingBackground7Mobile from 'assets/landingBackground7Mobile.png';
import landingInfographic1 from 'assets/landingInfographic1.png';
import landingInfographic2 from 'assets/landingInfographic2.png';
import landingInfographic3 from 'assets/landingInfographic3.png';
import landingInfographic4 from 'assets/landingInfographic4.png';
import landingInfographic5 from 'assets/landingInfographic5.png';
import menuArrowIcon from 'assets/menuArrow.svg';
import menuMirrorIcon from 'assets/menuMirror.svg';
import menuTwitterIcon from 'assets/menuTwitter.svg';
import mirrorLanding from 'assets/mirrorLanding.svg';
import shareLogo from 'assets/onboardingLogo.png';
import twitterLanding from 'assets/twitterLanding.svg';
import JoinModal from 'components/JoinModal';
import { DashboardLocation, SignUpLocation } from 'Locations';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import FadeIn from 'react-fade-in';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import { useInterval } from 'usehooks-ts';
import { Breakpoints, validateEmail } from 'utils/utils';

const useStyles = createUseStyles({
  container: {
    height: '100vh',
  },
  background1: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    width: '100vw',
    height: '100vh',
  },
  socialsContainer: {
    display: 'flex',
    width: '150px',
    position: 'absolute',
    top: '32px',
    left: '40px',
    zIndex: 100,
  },
  social: { width: '40px', cursor: 'pointer' },
  CTA: {
    border: '1px solid #fff',
    borderRadius: '30px',
    width: '150px',
    height: '40px',
    color: '#fff',
    cursor: 'pointer',
    fontSize: '15px',
    fontWeight: 'bold',
    lineHeight: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    userSelect: 'none',
    letterSpacing: '0.02em',
  },
  content: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  CTAContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '50px',
  },
  containerContent: {
    width: '100vw',
    height: '100vh',
    position: 'relative',
  },
  contentBackgroundImage: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  content2: {
    maxWidth: '1300px',
    display: 'flex',
    alignItems: 'center',
  },
  sectionContent: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
  },
  dividerContainer: { padding: '0 40px' },
  divider: {
    width: '100%',
    height: '1px',
    backgroundColor: '#f9f9f9',
    opacity: 0.2,
  },
  sectionContainer: {
    padding: '0px 60px',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  inputContainer: {
    marginTop: '40px',
    display: 'block',
    alignItems: 'center',
    justifyContent: 'center',
  },
  input: {
    '&::placeholder': { color: '#f9f9f9' },
    width: '290px',
    color: '#f9f9f9',
    fontSize: '16px',
    border: 'none',
    borderBottom: '0.5px solid #f9f9f9',
    background: 'transparent',
    padding: '18px 0',
    borderRadius: 0,
  },
  inputTitle: {
    marginTop: '64px',
    textAlign: 'center',
    fontFamily: 'eiko',
    fontWeight: 100,
    fontSize: '30px',
    lineHeight: '38px',
    color: '#fff',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    color: '#fff',
    fontSize: '20px',
    lineHeight: '30px',
  },
  infographicText: { maxWidth: '350px', flexShrink: 0, textAlign: 'center' },
  infographicDescription: {
    marginTop: '24px',
    fontSize: '16px',
    lineHeight: '135%',
    letterSpacing: '0.02em',
    color: '#fff',
  },
  infographicTitle: {
    fontSize: '34px',
    lineHeight: '46px',
    fontFamily: 'eiko',
    fontWeight: 100,
    color: '#fff',
  },
  tokenImage: { maxWidth: '250px' },
  logo: { margin: '0', width: '128px', userSelect: 'none' },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '32px 16px',
  },
  hamburger: {
    width: '32px',
    height: '32px',
    cursor: 'pointer',
    userSelect: 'none',
    zIndex: 100,
  },
  title: {
    textAlign: 'center',
    fontFamily: 'eiko',
    fontWeight: 100,
    fontSize: '40px',
    lineHeight: '48px',
    letterSpacing: '-0.02em',
    color: '#fff',
    textShadow: '0px 5px 25px rgba(0, 0, 0, 0.4)',
    display: 'flex',
    userSelect: 'none',
  },
  menu: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: '#1B212D',
    zIndex: 10000,
    padding: '32px 16px',
  },
  menuHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  menuItemContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '32px',
    cursor: 'pointer',
  },
  menuTitle: {
    marginLeft: '16px',
    fontSize: '32px',
    fontWeight: 400,
    color: '#fff',
  },
  menuFooter: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    padding: '32px 16px',
    color: '#fff',
    width: '100%',
  },
  menuDivider: {
    opacity: 0.2,
    width: '100%',
    height: '1px',
    backgroundColor: '#f9f9f9',
    marginBottom: '56px',
  },
  title2: {
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.2em',
    color: '#fff',
    fontWeight: 'bold',
  },
  sectionFlex: { display: 'block', alignItems: 'center', marginTop: '40px' },
  infographic: { maxWidth: '350px', width: '100%' },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    sectionFlex: { display: 'flex' },
    title2: { fontSize: '20px' },
    logo: { margin: '0 40px', width: '148px' },
    header: { padding: '32px 43px' },
    title: { fontSize: '66px', lineHeight: '82px' },
    infographicText: { textAlign: 'left' },
    infographic: { maxWidth: '400px' },
    inputTitle: { fontSize: '42px', lineHeight: '48px' },
    infographicTitle: { fontSize: '38px' },
    inputContainer: { display: 'flex' },
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    infographic: { maxWidth: '695px' },
    infographicText: { maxWidth: '560px' },
    infographicTitle: { fontSize: '56px', lineHeight: '68px' },
    infographicDescription: { fontSize: '20px', marginTop: '40px' },
    tokenImage: { maxWidth: '326px' },
    inputTitle: { marginTop: '100px', fontSize: '62px', lineHeight: '82px' },
    input: { width: '398px' },
  },
});

const messages = [
  'passion',
  'vision',
  'work',
  'world',
  <>
    Share empowers everyone {!isMobile && <br />} to build {isMobile && <br />}{' '}
    in Web3
  </>,
];

export default function HomeView(): JSX.Element {
  const styles = useStyles();
  const [joinModal, setJoinModal] = useState(false);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const [currentIndex, setCurrentIndex] = useState(-1);

  useInterval(() => {
    if (currentIndex < 4) {
      if (currentIndex < 3) {
        setCurrentIndex(currentIndex + 1);
      } else {
        setCurrentIndex(4);
      }
    }
  }, 1500);

  const onSubmit = async () => {
    if (email && validateEmail(email)) {
      setLoading(true);
      await addToMailingList(email);
      setEmail('');
      setLoading(false);
      setSuccess(true);
    }
  };

  /*

  WARNING! This landing page code is extremely messy and needs to be refactored.
  It's hacky just for the sake of launching quickly.
  Needs some deep love and care <3

  */

  return (
    <div>
      {showMenu && (
        <div className={styles.menu}>
          <div className={styles.menuHeader}>
            <img src={shareLogo} alt="share" className={styles.logo} />
            <img
              src={joinCloseIcon}
              alt="close"
              onClick={() => setShowMenu(false)}
            />
          </div>
          <div
            className={styles.menuItemContainer}
            style={{ marginTop: '104px' }}
            onClick={() => setShowMenu(true)}
          >
            <img src={menuArrowIcon} alt="join" />
            <div
              className={styles.menuTitle}
              onClick={() => setJoinModal(true)}
            >
              Join Share
            </div>
          </div>
          <a
            href="https://mirror.xyz/bigimot.eth/LykankLHeDkgsOkxqa58F5f0GYz7zS7kFrdvs9Gatso"
            target="_blank"
            rel="noreferrer"
          >
            <div className={styles.menuItemContainer}>
              <img src={menuMirrorIcon} alt="mirror" />
              <div className={styles.menuTitle}>Mirror</div>
            </div>
          </a>
          <a
            href="https://twitter.com/joinsharexyz"
            target="_blank"
            rel="noreferrer"
          >
            <div className={styles.menuItemContainer}>
              <img src={menuTwitterIcon} alt="twitter" />
              <div className={styles.menuTitle}>Twitter</div>
            </div>
          </a>
          <div className={styles.menuFooter}>
            <div className={styles.menuDivider} />
            <div>© SHARE, INC.</div>
            <div style={{ marginTop: '12px' }}>2022</div>
          </div>
        </div>
      )}
      <JoinModal open={joinModal} onClose={() => setJoinModal(false)} />
      <div className={styles.container}>
        <div
          className={styles.background1}
          style={{
            backgroundImage: `url(${
              isMobile ? landingBackground1Mobile : landingBackground1
            })`,
          }}
        >
          <div className={styles.header}>
            {!isMobile && (
              <>
                <div style={{ width: '150px' }} />
                <div className={styles.socialsContainer}>
                  <a
                    href="https://mirror.xyz/bigimot.eth/LykankLHeDkgsOkxqa58F5f0GYz7zS7kFrdvs9Gatso"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={mirrorLanding}
                      alt="mirror"
                      className={styles.social}
                      style={{ marginRight: '8px' }}
                    />
                  </a>
                  <a
                    href="https://twitter.com/joinsharexyz"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className={styles.social}
                      src={twitterLanding}
                      alt="twitter"
                    />
                  </a>
                </div>
              </>
            )}
            <img src={shareLogo} alt="share" className={styles.logo} />
            {!isMobile ? (
              <>
                <div style={{ width: '150px' }} />
                <Link
                  to={DashboardLocation}
                  className={styles.CTA}
                  style={{
                    position: 'absolute',
                    top: '32px',
                    right: '43px',
                    zIndex: 100,
                    paddingTop: '3px',
                  }}
                  onClick={() => isMobile && setJoinModal(true)}
                >
                  OPEN APP
                </Link>
              </>
            ) : (
              <img
                onClick={() => setShowMenu(true)}
                src={hamburgerIcon}
                alt="menu"
                className={styles.hamburger}
              />
            )}
          </div>
          <div className={styles.content}>
            <div>
              <div
                className={styles.title}
                style={{
                  marginLeft: currentIndex < 4 && isMobile ? '24px' : 0,
                  width:
                    currentIndex < 4 ? (isMobile ? '350px' : '540px') : 'auto',
                  marginBottom: currentIndex < 4 ? '60px' : '0',
                }}
              >
                {currentIndex < 4 && (
                  <span style={{ marginTop: isMobile ? '-24px' : 0 }}>
                    Share your
                  </span>
                )}
                <span
                  style={{
                    textAlign:
                      currentIndex < 4
                        ? isMobile
                          ? 'center'
                          : 'left'
                        : 'center',
                    marginLeft:
                      currentIndex < 4 ? (isMobile ? '12px' : '20px') : 0,
                    marginTop:
                      currentIndex < 4
                        ? isMobile
                          ? '-24px'
                          : 0
                        : isMobile
                        ? 0
                        : '110px',
                  }}
                  key={`${
                    currentIndex === -1 ? currentIndex + 1 : currentIndex
                  }-message-container`}
                >
                  {currentIndex > 0 ? (
                    <FadeIn>
                      {
                        messages[
                          currentIndex === -1 ? currentIndex + 1 : currentIndex
                        ]
                      }
                    </FadeIn>
                  ) : (
                    <>
                      {
                        messages[
                          currentIndex === -1 ? currentIndex + 1 : currentIndex
                        ]
                      }
                    </>
                  )}
                </span>
              </div>
              {currentIndex === 4 && (
                <FadeIn delay={500}>
                  <div className={styles.CTAContainer}>
                    <Link
                      to={isMobile ? '#' : DashboardLocation}
                      className={styles.CTA}
                      style={{
                        width: '213px',
                        height: '50px',
                        paddingTop: '3px',
                      }}
                      onClick={() => setJoinModal(true)}
                    >
                      {isMobile ? 'JOIN THE WAITLIST' : 'START BUILDING'}
                    </Link>
                  </div>
                </FadeIn>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.containerContent}>
        <img
          src={isMobile ? landingBackground2Mobile : landingBackground2}
          alt="background"
          className={styles.contentBackgroundImage}
        />
        <div className={styles.sectionContent}>
          <div className={styles.content2}>
            <div>
              <div className={styles.title2}>THE SHARE PLATFORM</div>
              <div className={styles.sectionFlex}>
                {!isMobile && (
                  <div
                    style={{
                      display: isMobile ? 'flex' : 'block',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src={landingInfographic1}
                      alt="graphic"
                      className={styles.infographic}
                    />
                  </div>
                )}
                <div
                  className={styles.infographicText}
                  style={{
                    marginLeft: isMobile ? 0 : '64px',
                    marginBottom: isMobile ? '48px' : 0,
                  }}
                >
                  <div className={styles.infographicTitle}>
                    Everything you need to start contributing
                  </div>
                  <div className={styles.infographicDescription}>
                    No more getting lost in Discord. Quickly get up to speed on
                    everything; key team members, active projects, calendars -
                    we got you!
                  </div>
                </div>
                {isMobile && (
                  <div
                    style={{
                      display: isMobile ? 'flex' : 'block',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src={landingInfographic1}
                      alt="graphic"
                      className={styles.infographic}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.containerContent}>
        <img
          src={isMobile ? landingBackground3Mobile : landingBackground3}
          alt="background"
          className={styles.contentBackgroundImage}
        />
        <div className={styles.sectionContent}>
          <div className={styles.content2}>
            <div>
              <div
                style={{
                  display: isMobile ? 'block' : 'flex',
                  alignItems: 'flex-start',
                }}
              >
                <div
                  className={styles.infographicText}
                  style={{
                    marginRight: isMobile ? 0 : '64px',
                    marginTop: isMobile ? 0 : '32px',
                    marginBottom: isMobile ? '48px' : 0,
                  }}
                >
                  <div className={styles.infographicTitle}>
                    Opportunities fit
                    <br /> to your skills
                  </div>
                  <div className={styles.infographicDescription}>
                    With so many options out there, it’s easy to get
                    overwhelmed. Share makes it easy to find the best work for
                    you, from a quick bug fix to a full blown project. Just tell
                    us about yourself and we’ll handle the rest.
                  </div>
                </div>
                <div
                  style={{
                    display: isMobile ? 'flex' : 'block',
                    justifyContent: 'center',
                  }}
                >
                  <img
                    src={landingInfographic2}
                    alt="graphic"
                    className={styles.infographic}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.containerContent}>
        <img
          src={isMobile ? landingBackground4Mobile : landingBackground4}
          alt="background"
          className={styles.contentBackgroundImage}
        />
        <div className={styles.sectionContent}>
          <div className={styles.content2}>
            <div>
              <div
                style={{
                  display: isMobile ? 'block' : 'flex',
                  alignItems: 'center',
                }}
              >
                {!isMobile && (
                  <div
                    style={{
                      display: isMobile ? 'flex' : 'block',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src={landingInfographic3}
                      alt="graphic"
                      className={styles.infographic}
                    />
                  </div>
                )}
                <div
                  className={styles.infographicText}
                  style={{
                    marginLeft: isMobile ? 0 : '64px',
                    marginBottom: isMobile ? '24px' : 0,
                  }}
                >
                  <div className={styles.infographicTitle}>
                    Build your Web3 reputation
                  </div>
                  <div className={styles.infographicDescription}>
                    Never worry about dusting off your CV again. With Share your
                    work is public, verifiable, and always up to date. Easily
                    show off your skills and experience on-chain.
                  </div>
                </div>
                {isMobile && (
                  <div
                    style={{
                      display: isMobile ? 'flex' : 'block',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src={landingInfographic3}
                      alt="graphic"
                      className={styles.infographic}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.containerContent}>
        <img
          src={isMobile ? landingBackground5Mobile : landingBackground5}
          alt="background"
          className={styles.contentBackgroundImage}
        />
        <div className={styles.sectionContent}>
          <div className={styles.content2}>
            <div>
              <div className={styles.title2}>THE SHARE TOKEN</div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '64px',
                }}
              >
                <img
                  src={landingInfographic4}
                  alt="graphic"
                  className={styles.tokenImage}
                />
              </div>

              <div
                className={styles.infographicDescription}
                style={{
                  marginTop: '40px',
                  maxWidth: isMobile ? '100%' : '677px',
                  textAlign: 'center',
                  padding: isMobile ? '0px 24px' : 0,
                }}
              >
                Earn $SHARE by using Share. As a token holder, you’ll get access
                to premium features, NFTs, governance, and revenue generated by
                the protocol.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={styles.containerContent}
        style={{ height: isMobile ? '100vh' : '120vh' }}
      >
        <img
          src={isMobile ? landingBackground6Mobile : landingBackground6}
          alt="background"
          className={styles.contentBackgroundImage}
          style={{ height: isMobile ? '100vh' : '120vh' }}
        />

        <div className={styles.sectionContent}>
          <div className={styles.content2}>
            <div>
              <div className={styles.title2}>EXPLORE SHARE</div>
              <div
                style={{
                  display: isMobile ? 'block' : 'flex',
                  alignItems: 'center',
                  marginTop: '130px',
                }}
              >
                <div
                  className={styles.infographicText}
                  style={{ marginRight: isMobile ? 0 : '64px' }}
                >
                  <div className={styles.infographicTitle}>
                    See how DAOs are using Share. Schedule a demo today.
                  </div>
                  <div
                    style={{
                      display: isMobile ? 'flex' : 'block',
                      justifyContent: 'center',
                      marginBottom: isMobile ? '48px' : 0,
                    }}
                  >
                    <a
                      href="mailto:iwantto@joinshare.xyz"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div
                        className={styles.CTA}
                        style={{
                          width: '180px',
                          height: '52px',
                          marginTop: '40px',
                        }}
                      >
                        REQUEST A DEMO
                      </div>
                    </a>
                  </div>
                </div>
                <div
                  style={{
                    display: isMobile ? 'flex' : 'block',
                    justifyContent: 'center',
                  }}
                >
                  <img
                    src={landingInfographic5}
                    alt="graphic"
                    className={styles.infographic}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={styles.containerContent}
        style={{ height: isMobile ? '100vh' : '140vh' }}
      >
        <img
          src={isMobile ? landingBackground7Mobile : landingBackground7}
          alt="background"
          className={styles.contentBackgroundImage}
          style={{
            height: isMobile ? '100vh' : '140vh',
          }}
        />
        <div className={styles.sectionContent}>
          <div className={styles.content2}>
            <div style={{ marginBottom: isMobile ? '160px' : 0 }}>
              <div className={styles.title2}>JOIN SHARE</div>
              <div
                className={styles.inputTitle}
                style={{ marginTop: isMobile ? '64px' : '130px' }}
              >
                {!success ? (
                  <>
                    Ready to join{' '}
                    <span style={{ fontFamily: 'objectsans', fontWeight: 400 }}>
                      share
                    </span>
                    ?
                    <br />
                    Sign up{' '}
                    {isMobile ? 'for our waitlist' : 'and start building'}.
                  </>
                ) : (
                  <>
                    Success!
                    <br /> You&apos;ve been added
                    <br /> to the waitlist.
                  </>
                )}
              </div>
              {!isMobile ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '64px',
                  }}
                >
                  <Link to={SignUpLocation} className={styles.CTA}>
                    SIGN UP
                  </Link>
                </div>
              ) : (
                <div className={styles.inputContainer}>
                  <div
                    style={{
                      display: isMobile ? 'flex' : 'block',
                      justifyContent: 'center',
                    }}
                  >
                    <input
                      value={email}
                      placeholder={'Enter your email address'}
                      onChange={e => setEmail(e.target.value)}
                      className={styles.input}
                    />
                  </div>
                  <div
                    style={{
                      display: isMobile ? 'flex' : 'block',
                      justifyContent: 'center',
                      marginTop: isMobile ? '40px' : 0,
                    }}
                  >
                    <div className={styles.CTA} onClick={() => onSubmit()}>
                      {loading ? 'SUBMITTING...' : 'SUBMIT'}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          style={{ position: 'absolute', left: 0, bottom: 0, width: '100vw' }}
        >
          <div style={{ padding: '40px 40px' }}>
            <div className={styles.footerContainer}>
              <div>
                <div>© SHARE, INC.</div>
                <div style={{ marginTop: '16px' }}>2022</div>
              </div>
              <div style={{ textAlign: 'right' }}>
                <a
                  href="mailto:iwantto@joinshare.xyz"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div
                    style={{
                      borderBottom: '0.5px solid rgba(255, 255, 255, 0.75)',
                      color: '#f9f9f9',
                    }}
                  >
                    Contact Us
                  </div>
                </a>
                <div
                  style={{
                    display: 'inline-block',
                    marginTop: '16px',
                    borderBottom: '0.5px solid rgba(255, 255, 255, 0.75)',
                  }}
                >
                  Privacy
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
