import { IOpportunity } from 'api/opportunitiesApi';
import searchIcon from 'assets/search.svg';
import { Fragment, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { filterSelectOptions } from 'views/Opportunities';

import DAOApplicant, { ApplicantProps } from './DAOApplicant';
import FilterSelect from './FilterSelect';

const useStyles = createUseStyles({
  searchInputContainer: {
    marginTop: '32px',
    display: 'flex',
  },
  searchInput: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #303B5B',
    backgroundColor: '#202837',
    borderRadius: '8px',
  },
  searchInputIcon: { padding: '14px 16px', flexShrink: 0 },
  searchInputText: {
    color: '#fff',
    fontSize: '16px',
    height: '100%',
    width: '100%',
    background: 'transparent',
    border: 'none',
  },
  filterSelect: { marginLeft: '24px', flexShrink: 0, width: '180px' },
  divider: {
    marginBottom: '36px',
    background: '#303B5B',
    width: '100%',
    height: '1px',
    marginTop: '32px',
  },
});

export default function DAOOpportunityApplicants({
  opportunity,
  assigning,
  toggleAssign,
}: {
  opportunity: IOpportunity | null;
  assigning: boolean;
  toggleAssign: (applicantData: ApplicantProps) => void;
}): JSX.Element {
  const styles = useStyles();
  const [filterSelect, setFilterSelect] = useState<{
    value: string;
    label: string;
  }>(filterSelectOptions[0]);

  return (
    <div>
      <div className={styles.searchInputContainer}>
        <div className={styles.searchInput}>
          <div className={styles.searchInputIcon}>
            <img src={searchIcon} alt="search" />
          </div>
          <input className={styles.searchInputText} placeholder={'Search'} />
        </div>
        <div className={styles.filterSelect}>
          <FilterSelect
            options={filterSelectOptions}
            value={filterSelect}
            onChange={val => setFilterSelect(val)}
          />
        </div>
      </div>
      <div style={{ marginTop: '48px' }}>
        {opportunity?.opportunityApplicants?.map((applicant, i) => (
          <Fragment key={`${applicant.userId}-${i}`}>
            <DAOApplicant
              applicant={applicant}
              assigning={assigning}
              toggleAssign={toggleAssign}
              opportunityStatus={opportunity.status}
              isAssigned={opportunity.user?.wallet === applicant.user.wallet}
            />
            <div className={styles.divider} />
          </Fragment>
        ))}
      </div>
    </div>
  );
}
