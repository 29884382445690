import { utils } from 'ethers';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

// eslint-disable-next-line
export default function useOutsideAlerter(ref: any, callback: any): void {
  /**
   * Alert if clicked on outside of element
   */
  // eslint-disable-next-line
  function handleClickOutside(event: any) {
    if (ref.current && !ref.current.contains(event.target)) {
      callback(false);
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
}

export function truncate(source: string, size: number, file?: boolean): string {
  return source.length > size
    ? source.slice(0, size - 1) + `…${file ? source.match('[^.]+$') : ''}`
    : source;
}

export const truncateAddress = (address: string): string => {
  const account = utils.getAddress(address);
  const len = account.length;
  return `0x${account.substr(2, 4)}...${account.substr(len - 4, len - 1)}`;
};

export function formatAddress(
  address: string | null | undefined,
  ensName: string | null | undefined,
  chars = 4,
): string {
  if (ensName) return ensName;
  else if (address)
    return `${address.substring(0, chars + 2)}...${address.substring(
      42 - chars,
    )}`;
  else return '';
}

export function discordAvatarUrl(
  discordId: string,
  discordAvatar: string,
): string {
  return `https://cdn.discordapp.com/avatars/${discordId}/${discordAvatar}`;
}

export const Breakpoints = {
  xs: 350,
  s: 600,
  sm: 768,
  ms: 1010,
  md: 1400,
  lg: 1600,
};

export function validateEmail(email: string): boolean {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const copyToClipboard = (input: string | number): void => {
  navigator.clipboard.writeText(input.toString());
};

export const useScrollPosition = (): number => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const updatePosition = () => {
      setScrollPosition(window.pageYOffset);
    };
    window.addEventListener('scroll', updatePosition);
    updatePosition();
    return () => window.removeEventListener('scroll', updatePosition);
  }, []);

  return scrollPosition;
};

export const sleep = (ms: number): Promise<void> => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const capitalizeFirstLetter = (val: string): string => {
  return val.charAt(0).toUpperCase() + val.slice(1);
};

export const useQuery = (): URLSearchParams => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export const getHostURL = (includeProtocol = true): string => {
  const protocol = window.location.protocol;
  const domain = window.location.hostname;
  const port = window.location.port;

  return `${includeProtocol ? `${protocol}//` : ''}${domain}${
    port ? `:${port}` : ''
  }`;
};

export const formatToTenths = (val: number): string => {
  if (!val) {
    return '0';
  }
  if (parseFloat(val.toFixed(2)) === 0) {
    return '<0.01';
  } else {
    return val.toFixed(2).replace(/[.,]00$/, '');
  }
};

export const formatToThousandths = (val: number): string => {
  if (!val) {
    return '0';
  }
  if (parseFloat(val.toFixed(4)) === 0) {
    return '<0.0001';
  } else {
    return val.toFixed(4).replace(/[.,]00$/, '');
  }
};

export function uriToHttp(uri: string | null | undefined): string {
  if (!uri) return '';
  const protocol = uri.split(':')[0].toLowerCase();
  switch (protocol) {
    case 'data':
    case 'https':
      return uri;
    case 'http':
      return uri;
    case 'ipfs':
      // eslint-disable-next-line no-case-declarations
      const hash = uri.match(/^ipfs:(\/\/)?(.*)$/i)?.[2];
      return `https://share.mypinata.cloud/ipfs/${hash}/`;
    // `https://cloudflare-ipfs.com/ipfs/${hash}/`,
    // `https://ipfs.io/ipfs/${hash}/`,
    case 'ipns':
      // eslint-disable-next-line no-case-declarations
      const name = uri.match(/^ipns:(\/\/)?(.*)$/i)?.[2];
      // todo uncomment if we start using ipns on private rigor pinata gateway
      //return `https://rigor.mypinata.cloud/ipns/${hash}/`;
      return `https://cloudflare-ipfs.com/ipns/${name}/`;
    default:
      return '';
  }
}

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';
