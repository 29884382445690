import { IOpportunity, requestEditsOpportunity } from 'api/opportunitiesApi';
import closeIcon from 'assets/close.svg';
import fileIcon from 'assets/file.svg';
import landingBackground1 from 'assets/landscapeZoomedGirlIllustration.png';
import { useWallet } from 'contexts/WalletContext';
import makeBlockie from 'ethereum-blockies-base64';
import { useShareContract } from 'hooks/useShareContract';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { createUseStyles } from 'react-jss';
import useGlobalState from 'state';
import { sleep, truncate, uriToHttp } from 'utils/utils';
import { waitUntilBlock } from 'web3/graphHealth';
import { getUserSignature } from 'web3/helpers';

import Modal from './Modal';

const useStyles = createUseStyles({
  container: { maxWidth: '1034px', display: 'flex' },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '24px 40px',
  },
  close: { width: '24px', cursor: 'pointer' },
  title: {
    fontSize: '24px',
    lineHeight: '24px',
  },
  divider: { width: '100%', height: '1px', backgroundColor: '#303B5B' },
  description: {
    padding: '40px',
    fontSize: '16px',
    lineHeight: '21px',
    color: '#D1D3D5',
  },
  submitContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '24px 40px',
  },
  submit: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F9F9F9',
    color: '#000',
    fontSize: '16px',
    lineHeight: '16px',
    borderRadius: '100px',
    padding: '16px 24px',
  },
  inputTitle: {
    fontSize: '14px',
    color: '#F9F9F9',
    marginBottom: '8px',
  },
  input: {
    border: '1px solid #303B5B',
    padding: '16px',
    borderRadius: '8px',
    fontSize: '16px',
    lineHeight: '21.6px',
    backgroundColor: 'transparent',
    color: '#f9f9f9',
    width: '432px',
  },
  userContainer: { display: 'flex', alignItems: 'center' },
  userAvatar: { borderRadius: '24px', width: '24px', height: '24px' },
  username: { marginLeft: '8px', fontSize: '14px', color: '#fff' },
  note: { marginTop: '24px', color: '#A4A6AB', fontSize: '14px' },
  comment: {
    color: '#f9f9f9',
    fontSize: '16px',
    lineHeight: '135%',
    width: '432px',
  },
  illustration: {
    width: '517px',
    minHeight: '511px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    flexShrink: 0,
  },
  file: {
    marginRight: '16px',
    marginBottom: '16px',
    padding: '8px 5px',
    borderRadius: '8px',
    border: '1px solid #303B5B',
    cursor: 'pointer',
    maxWidth: '96px',
  },
  filesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginRight: '-16px',
    marginTop: '16px',
  },
  download: { color: '#A4A6AB', fontSize: '14px', cursor: 'pointer' },
  fileName: {
    textAlign: 'center',
    marginTop: '8px',
    color: '#A4A6AB',
    fontSize: '12px',
    lineHeight: '16.2px',
    overflowWrap: 'break-word',
  },
});

export default function ReviewModal({
  open,
  onClose,
  opportunity,
}: {
  open: boolean;
  onClose(reload?: boolean): void;
  opportunity: IOpportunity;
}): JSX.Element {
  const styles = useStyles();
  const [loading, setLoading] = useState(false);
  const { loggedInUser } = useGlobalState();
  const { shareConfig, provider } = useWallet();
  const [oppId, setOppId] = useState(opportunity.id);
  const [submitText, setSubmitText] = useState('');
  const [editing, setEditing] = useState(false);

  const contract = useShareContract();

  useEffect(() => {
    setOppId(opportunity.id);
  }, [opportunity]);

  useEffect(() => {
    setEditing(false);
    setSubmitText('');
  }, [open]);

  const onApprove = async () => {
    if (oppId && loggedInUser && provider && contract) {
      setLoading(true);
      try {
        const reviewerSignature = await getUserSignature(
          //@ts-ignore
          opportunity.submissions[0].encodedData,
          provider,
        );
        const combinedSignature =
          //@ts-ignore
          reviewerSignature + opportunity.submissions[0].signature.slice(2);
        try {
          const tx = await contract.completeTasks(
            //@ts-ignore
            opportunity.submissions[0].encodedData,
            combinedSignature,
          );
          const receipt = await tx.wait(1);
          const success = await waitUntilBlock(receipt.blockNumber);
          if (success) {
            toast.success('Work approved!');
            setLoading(false);
            onClose(true);
          } else {
            toast.error('Transaction failed');
            setLoading(false);
          }
        } catch (e) {
          toast.error('Transaction failed');
          setLoading(false);
        }
      } catch (e) {
        toast.error('Signature rejected');
        setLoading(false);
      }
      /*const [result] = await Promise.all([
        approveOpportunity({
          opportunityId: oppId,
        }),
        sleep(300),
      ]);

      if (result.status === 400) {
        setLoading(false);
        toast.error('Error');
      } else {
        setLoading(false);
        toast.success('Submitted!');
        onClose(true);
      }*/
    }
  };

  const onRequestEdits = async () => {
    if (oppId && loggedInUser && submitText) {
      const [result] = await Promise.all([
        requestEditsOpportunity({
          subgraphTaskId: `${shareConfig?.escrowAddress.toLowerCase()}-0x${oppId.toString(
            16,
          )}`,
          message: submitText,
        }),
        sleep(300),
      ]);
      if (result.status === 400) {
        setLoading(false);
        toast.error('Error');
      } else {
        setLoading(false);
        toast.success('Submitted!');
        onClose(true);
      }
    }
  };

  //@ts-ignore
  const submission = opportunity?.submissions && opportunity.submissions[0];

  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      style={{ borderRadius: '8px' }}
    >
      {submission && (
        <div className={styles.container}>
          <div
            style={{
              backgroundImage: `url(${landingBackground1})`,
            }}
            className={styles.illustration}
          />
          <div style={{ width: '100%' }}>
            <div className={styles.header}>
              <div className={styles.title}>
                {editing ? 'Ask for Edits' : 'Review'}
              </div>
              <img
                src={closeIcon}
                alt="close"
                className={styles.close}
                onClick={() => onClose()}
              />
            </div>
            <div className={styles.divider} />
            <div className={styles.description}>
              <div className={styles.inputTitle}>
                {editing ? 'To:' : 'From:'}
              </div>
              <div className={styles.userContainer}>
                <img
                  className={styles.userAvatar}
                  src={makeBlockie(submission.wallet || '')}
                  alt="user"
                />
                <div className={styles.username}>
                  {submission.user.username}
                </div>
              </div>
              {editing ? (
                <>
                  <div
                    className={styles.inputTitle}
                    style={{ marginTop: '32px' }}
                  >
                    Add a note that will be shared with{' '}
                    {submission.user.username}{' '}
                  </div>
                  <textarea
                    value={submitText}
                    onChange={e => setSubmitText(e.target.value)}
                    rows={4}
                    className={styles.input}
                  />
                  <div className={styles.note}>
                    Status will be changed to In Progress
                    <br />
                    {submission.user.username} will be notified
                  </div>
                </>
              ) : (
                <>
                  {submission.message && (
                    <div
                      className={styles.inputTitle}
                      style={{ marginTop: '32px' }}
                    >
                      Comment:
                    </div>
                  )}
                  <div className={styles.comment}>{submission.message}</div>
                  {opportunity.uploadedFiles && (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginTop: '48px',
                        }}
                      >
                        <div className={styles.inputTitle}>Files:</div>
                      </div>
                      <div className={styles.filesContainer}>
                        {/* @ts-ignore */}
                        {submission.attachments?.map((file, i) => (
                          <a
                            href={uriToHttp(file.uri)}
                            download
                            target="_blank"
                            rel="noreferrer"
                            key={`${file.uri}-${i}`}
                            className={styles.file}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <img src={fileIcon} alt="file" />
                            </div>
                            <div className={styles.fileName}>
                              {truncate(
                                // @ts-ignore
                                `${
                                  file.uri.match(/([\w\d_-]*)\.?[^\\/]*$/i)[1]
                                }${
                                  //@ts-ignore
                                  file.uri.match(/\.[0-9a-z]+$/i)[0]
                                }`,
                                16,
                                true,
                              )}
                            </div>
                          </a>
                        ))}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>

            <div className={styles.divider} />
            <div className={styles.submitContainer}>
              {!editing && (
                <div
                  className={styles.submit}
                  onClick={() => setEditing(true)}
                  style={{
                    marginRight: '24px',
                    background: 'transparent',
                    border: '0.5px solid #F9F9F9',
                    color: '#F9f9f9',
                  }}
                >
                  Ask for edits
                </div>
              )}
              <div
                className={styles.submit}
                style={{
                  color: editing && !submitText ? '#767A81' : '#1B212D',
                  backgroundColor:
                    editing && !submitText ? '#BBBCC0' : '#F9F9F9',
                  cursor: editing && !submitText ? 'not-allowed' : 'pointer',
                }}
                onClick={() => (editing ? onRequestEdits() : onApprove())}
              >
                {loading
                  ? 'Approving...'
                  : editing
                  ? 'Request Edits'
                  : 'Approve'}
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
}
