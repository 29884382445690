import { addToMailingList } from 'api/usersApi';
import joinBackground from 'assets/joinBackground.png';
import joinClose from 'assets/joinClose.svg';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { createUseStyles } from 'react-jss';
import { validateEmail } from 'utils/utils';

import Modal from './Modal';

const useStyles = createUseStyles({
  container: { padding: '80px 60px', maxWidth: '90vw', maxHeight: '90vh' },
  close: {
    cursor: 'pointer',
    position: 'absolute',
    top: '32px',
    right: '32px',
  },
  title: {
    fontFamily: 'eiko',
    fontWeight: 100,
    fontSize: '54px',
    color: '#fff',
    marginBottom: '24px',
    textAlign: 'center',
  },
  titleHelper: { fontWeight: 400, fontFamily: 'objectsans' },
  description: {
    textAlign: 'center',
    width: '475px',
    fontSize: '20px',
    lineHeight: '27px',
  },
  inputContainer: { marginTop: '40px', display: 'flex', alignItems: 'center' },
  input: {
    '&::placeholder': { color: '#f9f9f9' },
    width: '100%',
    color: '#f9f9f9',
    fontSize: '16px',
    border: 'none',
    borderBottom: '0.5px solid #f9f9f9',
    background: 'transparent',
    padding: '18px 0',
  },
  CTA: {
    fontSize: '14px',
    fontWeight: 700,
    padding: '20px 24px',
    border: '1px solid #f9f9f9',
    borderRadius: '100px',
    cursor: 'pointer',
    flexShrink: 0,
    marginLeft: '16px',
    userSelect: 'none',
  },
});

export default function JoinModal({
  open,
  onClose,
}: {
  open: boolean;
  onClose(): void;
}): JSX.Element {
  const styles = useStyles();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setEmail('');
    setSuccess(false);
  }, [open]);

  const onSubmit = async () => {
    if (email && validateEmail(email)) {
      setLoading(true);
      await addToMailingList(email);
      setEmail('');
      setLoading(false);
      setSuccess(true);
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      style={{
        borderRadius: '16px',
        backgroundImage: `url(${joinBackground})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    >
      <img
        onClick={() => onClose()}
        src={joinClose}
        alt="close"
        className={styles.close}
      />
      <div
        className={styles.container}
        style={{ padding: isMobile ? '80px 29.5px' : '80px 60px' }}
      >
        <div
          className={styles.title}
          style={{ fontSize: isMobile ? '35px' : '54px' }}
        >
          {success ? (
            'Success!'
          ) : (
            <>
              Ready to join{' '}
              {isMobile ? (
                'share'
              ) : (
                <span className={styles.titleHelper}>share</span>
              )}
              ?
            </>
          )}
        </div>
        <div
          className={styles.description}
          style={{
            width: isMobile ? '100%' : '475px',
            fontSize: isMobile ? '16px' : '20px',
            lineHeight: isMobile ? '21.6px' : '27px',
          }}
        >
          {success
            ? "You've joined the mailing list"
            : 'Explore the Share marketplace to discover and contribute to DAOs that you care about.'}
        </div>
        {!success && (
          <div
            className={styles.inputContainer}
            style={{ display: isMobile ? 'block' : 'flex' }}
          >
            <input
              value={email}
              placeholder={'Enter your email address'}
              onChange={e => setEmail(e.target.value)}
              className={styles.input}
            />
            <div
              style={{
                display: isMobile ? 'flex' : 'block',
                justifyContent: 'center',
                marginTop: isMobile ? '32px' : 0,
                width: isMobile ? '100%' : 'auto',
              }}
            >
              <div
                className={styles.CTA}
                onClick={() => onSubmit()}
                style={{
                  marginLeft: isMobile ? 0 : '16px',
                  width: isMobile ? '100%' : 'auto',
                  textAlign: 'center',
                }}
              >
                {loading ? 'SUBMITTING...' : 'SUBMIT'}
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}
