import 'react-datepicker/dist/react-datepicker.css';
import 'react-quill/dist/quill.snow.css';

import { expertiseOptions, timeEstimateOptions } from 'api/onboardingApi';
import { uploadJson } from 'api/uploadApi';
import caretIcon from 'assets/caret.svg';
import x from 'assets/x.svg';
import EditorToolbar, { formats, modules } from 'components/EditorToolbar';
import {
  skillOptions,
  sliderDotStyle,
  sliderHandleStyle,
} from 'components/Onboarding/OnboardingSkills';
import Select from 'components/Select';
import TransactionModal from 'components/TransactionModal';
import { useWallet } from 'contexts/WalletContext';
import { ContractTransaction, ethers, utils } from 'ethers';
import { useERC20Contract } from 'hooks/useERC20Contract';
import { useShareContract } from 'hooks/useShareContract';
import MainLayout from 'layouts/MainLayout';
import { ManageLocation } from 'Locations';
import Slider from 'rc-slider';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import toast from 'react-hot-toast';
import { createUseStyles } from 'react-jss';
import ReactQuill from 'react-quill';
import { Link, useHistory } from 'react-router-dom';
//@ts-ignore
import { WithContext as ReactTags } from 'react-tag-input';
import { CURRENCY_LIST } from 'web3/constants';
import { waitUntilBlock } from 'web3/graphHealth';
import { stringToBytes } from 'web3/helpers';
import { TaskCreationMetadata } from 'web3/metadata';

import { Skill, Tag } from './SignUp';

const useStyles = createUseStyles({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '40px',
  },
  back: {
    cursor: 'pointer',
    marginRight: '8px',
    width: '24px',
    transform: 'rotate(180deg)',
    filter: 'brightness(0) invert(1)',
  },
  title: { fontSize: '24px', color: '#fff' },
  sectionContainer: { display: 'flex', alignItems: 'flex-start' },
  sectionDetailsContainer: {
    flexShrink: 0,
    width: '350px',
    paddingRight: '24px',
  },
  sectionContentContainer: {
    width: '100%',
    maxWidth: '600px',
  },
  sectionDetailsTitle: { color: '#f9f9f9', fontSize: '20px' },
  inputTitle: { fontSize: '14px', color: '#f9f9f9', marginBottom: '8px' },
  input: {
    border: '1px solid #303B5B',
    padding: '18px 16px',
    fontSize: '16px',
    backgroundColor: '#202837',
    color: '#f9f9f9',
    borderRadius: '8px',
    width: '100%',
    lineHeight: '21.6px',
    '&:placeholder': {
      color: '#A4A6AB',
    },
  },
  divider: {
    width: '100%',
    height: '1px',
    backgroundColor: '#303B5B',
    margin: '40px 0px',
  },
  sliderContainer: { height: '52px', display: 'flex', alignItems: 'center' },
  tag: {
    border: '0.5px solid #A4A6AB',
    borderRadius: '100px',
    padding: '9px 12px',
    fontSize: '14px',
    gap: '12px',
    marginRight: '8px',
    alignItems: 'center',
    display: 'inline-flex',
    cursor: 'pointer !important',
    marginBottom: '16px',
  },
  x: {
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },
  tagInput: {
    display: 'inline',
  },
  suggestions: { fontSize: '12px' },
  activeSuggestion: { cursor: 'pointer' },
  inputField: {
    background: 'transparent',
    border: 'none',
    color: '#f9f9f9',
    width: '100%',
  },
  content: {
    border: '1px solid #303B5B',
    borderBottomRightRadius: '8px',
    borderBottomLeftRadius: '8px',
    color: 'white',
  },
  footer: { display: 'flex', justifyContent: 'flex-end', marginTop: '40px' },
});

export default function CreateOpportunityView(): JSX.Element {
  const history = useHistory();
  const styles = useStyles();
  const { chainId, address, shareConfig } = useWallet();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [timeValue, setTimeValue] = useState(1);
  const [tags, setTags] = useState<Tag[]>([]);
  const [timeUnit, setTimeUnit] = useState(timeEstimateOptions[0]);
  const [date, setDate] = useState(new Date());
  const [transaction, setTransaction] = useState<ContractTransaction | null>(
    null,
  );
  const [currency, setCurrency] = useState({
    value: CURRENCY_LIST[chainId || 5][0].address,
    label: CURRENCY_LIST[chainId || 5][0].symbol,
  });
  const [amount, setAmount] = useState(1);
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(false);

  const contract = useShareContract();

  const [skill, setSkill] = useState<Skill>({
    kind: 'Development',
    experience: 'Mid',
  });

  const KeyCodes = {
    comma: 188,
    enter: 13,
  };

  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  const handleDelete = (i: number) => {
    setTags(tags?.filter((tag, index) => index !== i) || []);
  };

  const handleAddition = (tag: { id: string; text: string }) => {
    setTags([...tags, tag]);
  };

  const handleDrag = (
    tag: { id: string; text: string },
    currPos: number,
    newPos: number,
  ) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClick = (index: number) => {
    handleDelete(index);
  };

  const curr = CURRENCY_LIST[chainId || 5].find(
    c => c.address === currency.value,
  );

  const erc20Contract = useERC20Contract(
    curr?.address || '0x7f8F6E42C169B294A384F5667c303fd8Eedb3CF3',
  );

  const onSubmit = async () => {
    if (
      !name ||
      !description ||
      !skill ||
      !timeValue ||
      !tags ||
      !date ||
      !currency ||
      !amount ||
      !content
    ) {
      toast.error('Please fill in all fields');
    } else {
      if (contract) {
        const data: TaskCreationMetadata = {
          title: name,
          category: skill.kind,
          experience: skill.experience,
          description,
          timeEstimateValue: timeValue,
          timeEstimateUnit: timeUnit.value,
          tags: tags.map(tag => tag.text),
          deadline: new Date(date).getTime() / 1000,
          content,
        };
        setLoading(true);
        try {
          //@ts-ignore
          const { ipfsHash } = await uploadJson(data);
          if (ipfsHash) {
            const offChainBytes = stringToBytes(ipfsHash);

            if (curr && address && erc20Contract) {
              const userBalance = await erc20Contract.balanceOf(address);

              if (
                userBalance?.eq(0) ||
                userBalance?.lt(
                  utils.parseUnits(amount.toString(), curr.decimals),
                )
              ) {
                toast.error(`Insufficient ${curr.symbol} balance`);
              } else {
                const allowance = await erc20Contract.allowance(
                  address,
                  shareConfig?.escrowAddress,
                );
                if (
                  allowance?.eq(0) ||
                  allowance?.lt(
                    utils.parseUnits(amount.toString(), curr.decimals),
                  )
                ) {
                  try {
                    const result = await erc20Contract.approve(
                      shareConfig?.escrowAddress,
                      ethers.constants.MaxUint256,
                    );
                    const receipt = await result.wait(1);
                    await waitUntilBlock(receipt.blockNumber);
                  } catch (e) {
                    toast.error('Token Allowance Rejected');
                    setLoading(false);
                    return;
                  }
                }
                try {
                  const tx = await contract.createTasks(
                    curr.address,
                    [utils.parseUnits(amount.toString(), curr.decimals)],
                    [offChainBytes],
                  );
                  setTransaction(tx);
                  const receipt = await tx.wait(1);
                  const success = await waitUntilBlock(receipt.blockNumber);
                  if (success) {
                    toast.success('Opportunity created!');
                    setTransaction(null);
                    history.push(ManageLocation);
                  } else {
                    setTransaction(null);
                    toast.error('Transaction failed');
                  }
                } catch (e) {
                  setTransaction(null);
                  toast.error('Transaction failed');
                }
              }
            }
          } else {
            toast.error('Error uploading metadata');
          }
        } catch (e) {
          toast.error('Error uploading metadata');
        }
        setLoading(false);
        //const tx = await contract.createTasks([])
      }
    }
  };

  return (
    <MainLayout title={'Manage'}>
      <TransactionModal open={transaction !== null} transaction={transaction} />
      <div className={styles.titleContainer}>
        <Link to={ManageLocation}>
          <img src={caretIcon} alt="back" className={styles.back} />
        </Link>
        <div className={styles.title}>Create Opportunity</div>
      </div>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionDetailsContainer}>
          <div className={styles.sectionDetailsTitle}>Basic information</div>
        </div>
        <div className={styles.sectionContentContainer}>
          <div className={styles.inputTitle}>Opportunity name</div>
          <input
            className={styles.input}
            placeholder={'e.g. Redesign the Landing Page'}
            value={name}
            onChange={e => setName(e.target.value)}
          />
          <div className={styles.inputTitle} style={{ marginTop: '24px' }}>
            Short description
          </div>
          <textarea
            rows={4}
            className={styles.input}
            placeholder={
              'Our landing page needs a refresh. It needs to highlight the new FOX-ETH liquidity pool...'
            }
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
        </div>
      </div>
      <div className={styles.divider} />
      <div className={styles.sectionContainer}>
        <div className={styles.sectionDetailsContainer}>
          <div className={styles.sectionDetailsTitle}>Opportunity info</div>
        </div>
        <div className={styles.sectionContentContainer}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ width: '260px', flexShrink: 0 }}>
              <div className={styles.inputTitle}>Category</div>
              <Select
                options={skillOptions}
                value={{ label: skill?.kind, value: skill?.kind }}
                onChange={val =>
                  setSkill({
                    kind: val.value,
                    experience: skill?.experience,
                  })
                }
              />
            </div>
            <div style={{ marginLeft: '32px', width: '100%' }}>
              <div className={styles.inputTitle}>Experience</div>
              <div className={styles.sliderContainer}>
                <Slider
                  min={0}
                  max={expertiseOptions.length - 1}
                  handleStyle={sliderHandleStyle}
                  dots
                  dotStyle={sliderDotStyle}
                  marks={expertiseOptions.reduce(
                    (obj, ele, index) => ({
                      ...obj,
                      [index]: {
                        style: {
                          color: '#A4A6AB',
                          marginTop: '8px',
                          display:
                            ele.value === skill?.experience ? 'block' : 'none',
                          transform:
                            index === 0
                              ? 'translateX(-10%)'
                              : 'translateX(-50%)',
                          fontSize: '14px',
                        },
                        label: ele.label,
                      },
                    }),
                    {},
                  )}
                  trackStyle={{ backgroundColor: '#D6AA3C', height: '2px' }}
                  railStyle={{ backgroundColor: '#303B5B', height: '2px' }}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={(value: any) => {
                    setSkill({
                      kind: skill?.kind,
                      experience: expertiseOptions[value].value,
                    });
                  }}
                  value={expertiseOptions.findIndex(
                    o => o.value === skill?.experience,
                  )}
                />
              </div>
            </div>
          </div>
          <div className={styles.inputTitle} style={{ marginTop: '24px' }}>
            Deadline
          </div>
          <DatePicker
            className={styles.input}
            selected={date}
            onChange={(newDate: Date) => setDate(newDate)}
          />
          <div
            style={{ display: 'flex', alignItems: 'center', marginTop: '24px' }}
          >
            <div style={{ width: '260px', flexShrink: 0 }}>
              <div className={styles.inputTitle}>Time estimate</div>
              <input
                className={styles.input}
                value={timeValue}
                onChange={e => setTimeValue(parseFloat(e.target.value))}
                type="number"
              />
            </div>
            <div style={{ width: '100%', marginLeft: '32px' }}>
              <div className={styles.inputTitle} style={{ opacity: 0 }}>
                Time estimate
              </div>
              <Select
                options={timeEstimateOptions}
                value={timeUnit}
                onChange={val => setTimeUnit(val)}
              />
            </div>
          </div>
          <div className={styles.inputTitle} style={{ marginTop: '24px' }}>
            Tags
          </div>
          <ReactTags
            inline
            classNames={{
              tags: styles.input,
              tagInput: styles.tagInput,
              tagInputField: styles.inputField,
              tag: styles.tag,
              suggestions: styles.suggestions,
              activeSuggestion: styles.activeSuggestion,
            }}
            placeholder={tags.length === 0 && 'Press enter to add tags'}
            tags={tags}
            //suggestions={tagSuggestions}
            delimiters={delimiters}
            handleDelete={handleDelete}
            handleAddition={handleAddition}
            handleDrag={handleDrag}
            handleTagClick={handleTagClick}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            removeComponent={({ onRemove }: any) => (
              <button className={styles.x} onClick={onRemove}>
                <img src={x} alt="x" />
              </button>
            )}
            inputFieldPosition="inline"
            autocomplete
            maxLength={15}
            autoFocus={false}
          />
        </div>
      </div>
      <div className={styles.divider} />
      <div className={styles.sectionContainer}>
        <div className={styles.sectionDetailsContainer}>
          <div className={styles.sectionDetailsTitle}>Payment</div>
        </div>
        <div className={styles.sectionContentContainer}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ width: '260px', flexShrink: 0 }}>
              <div className={styles.inputTitle}>Amount</div>
              <input
                className={styles.input}
                value={amount}
                onChange={e => setAmount(parseFloat(e.target.value))}
                placeholder={'0.00'}
                type="number"
              />
            </div>
            <div style={{ width: '100%', marginLeft: '32px' }}>
              <div className={styles.inputTitle}>Select currency</div>
              <Select
                options={CURRENCY_LIST[chainId || 5].map(currency => {
                  return { value: currency.address, label: currency.symbol };
                })}
                value={currency}
                onChange={val => setCurrency(val)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.divider} />
      <div className={styles.sectionContainer}>
        <div>
          <div
            className={styles.sectionDetailsTitle}
            style={{ marginBottom: '32px' }}
          >
            Content
          </div>
          <EditorToolbar />
          <ReactQuill
            theme="snow"
            value={content}
            onChange={setContent}
            placeholder={'Describe the opportunity in detail'}
            modules={modules}
            formats={formats}
            className={styles.content}
          />
          <div className={styles.footer}>
            <Link
              style={{
                padding: '18px 24px',
                color: '#F9F9F9',
                fontSize: '16px',
                border: '0.5px solid #F9F9F9',
                borderRadius: '100px',
              }}
              to={ManageLocation}
            >
              Cancel
            </Link>
            <div
              style={{
                padding: '18px 24px',
                color: '#1B212D',
                fontSize: '16px',
                borderRadius: '100px',
                cursor: 'pointer',
                backgroundColor: '#F9F9F9',
                marginLeft: '16px',
              }}
              onClick={() => !loading && onSubmit()}
            >
              {loading ? 'Creating...' : 'Create'}
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
