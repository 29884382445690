import { availabilityOptions } from 'api/onboardingApi';
import { IUserData } from 'api/usersApi';
import brainGray from 'assets/brainGray.svg';
import timer from 'assets/timer.svg';
import makeBlockie from 'ethereum-blockies-base64';
import { ProfileLocation } from 'Locations';
import { CSSProperties } from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import { taskFilters } from 'views/Opportunities';

import Pill from './Pill';

const useStyles = createUseStyles({
  contributorContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    borderBottom: '1px solid #303B5B',
    paddingBottom: '24px',
    cursor: 'pointer',
  },
  userContainer: { display: 'flex', alignItems: 'flex-start' },
  blockie: { width: '32px', height: '32px', borderRadius: '50%' },
  userInfo: { marginLeft: '8px' },
  userInfoHeader: { display: 'flex', alignItems: 'center' },
  username: { fontSize: '16px', color: '#F9F9F9', marginRight: '8px' },
  userInfoFooter: {
    display: 'flex',
    alignItems: 'center',
    color: '#BBBCC0',
    marginTop: '8px',
    fontSize: '14px',
  },
  dot: {
    width: '3px',
    height: '3px',
    borderRadius: '3px',
    backgroundColor: '#767A81',
    margin: '0px 16px',
  },
  daoImage: { width: '24px', height: '24px', borderRadius: '24px' },
});

export default function Contributor({
  contributor,
  style,
}: {
  contributor: IUserData;
  style?: CSSProperties;
}): JSX.Element {
  const styles = useStyles();

  return (
    <Link
      to={ProfileLocation(contributor.wallet || '')}
      style={{ ...style }}
      className={styles.contributorContainer}
    >
      <div className={styles.userContainer}>
        <img
          src={makeBlockie(contributor.wallet || '')}
          alt="blockie"
          className={styles.blockie}
        />
        <div className={styles.userInfo}>
          <div className={styles.userInfoHeader}>
            <div className={styles.username}>{contributor.username}</div>
            {contributor.skills?.map((skill, i) => (
              <Pill
                key={`${skill}-${i}`}
                text={skill.kind}
                style={{
                  backgroundColor:
                    taskFilters.find(filter => filter.name === skill.kind)
                      ?.color || '#673969',
                  color: 'white',
                  marginLeft: 0,
                  marginRight: '8px',
                }}
              />
            ))}
          </div>
          <div className={styles.userInfoFooter}>
            <img
              src={brainGray}
              alt="experience"
              style={{ marginRight: '5px' }}
            />
            {contributor?.skills && contributor.skills[0]?.experience}
            <div className={styles.dot} />
            <img
              src={timer}
              alt="availability"
              style={{ marginRight: '5px' }}
            />
            {
              availabilityOptions.find(
                option => option.value === contributor.availability,
              )?.label
            }
          </div>
        </div>
      </div>
      <div style={{ position: 'relative' }}>
        {/*<img
          src={makeBlockie(contributor.wallet || '')}
          alt="blockie"
          className={styles.daoImage}
        />
        <img
          style={{ position: 'absolute', right: '12px' }}
          src={makeBlockie(contributor.wallet || '')}
          alt="blockie"
          className={styles.daoImage}
        />
        <img
          style={{ position: 'absolute', right: '24px' }}
          src={makeBlockie(contributor.wallet || '')}
          alt="blockie"
          className={styles.daoImage}
          />*/}
      </div>
    </Link>
  );
}
