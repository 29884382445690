import { availabilityOptions, expertiseOptions } from 'api/onboardingApi';
import { getContributors, IUserData } from 'api/usersApi';
import searchIcon from 'assets/search.svg';
import Contributor from 'components/Contributor';
import FilterPill from 'components/FilterPill';
import FilterSelect from 'components/FilterSelect';
import MainLayout from 'layouts/MainLayout';
import { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import useGlobalState from 'state';
import { filterSelectOptions, taskFilters } from 'views/Opportunities';

const useStyles = createUseStyles({
  container: { display: 'flex', alignItems: 'flex-start' },
  content: { width: '100%' },
  searchInputContainer: {
    display: 'flex',
  },
  searchInput: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #303B5B',
    backgroundColor: '#202837',
    borderRadius: '8px',
  },
  searchInputIcon: { padding: '14px 16px', flexShrink: 0 },
  searchInputText: {
    color: '#fff',
    fontSize: '16px',
    height: '100%',
    width: '100%',
    background: 'transparent',
    border: 'none',
  },
  filterSelect: { marginLeft: '24px', flexShrink: 0, width: '180px' },
  filterSection: { width: '350px', flexShrink: 0, marginLeft: '40px' },
  amount: { marginTop: '24px', color: '#A4A6AB', fontSize: '14px' },
  filterCategoryTitle: { fontSize: '16px', color: '#fff' },
  clear: {
    color: '#A4A6AB',
    fontSize: '14px',
    cursor: 'pointer',
  },
  filterPillsContainer: {
    marginTop: '24px',
    display: 'flex',
    marginRight: '-8px',
    flexWrap: 'wrap',
  },
  filterCategoryContainer: {
    marginTop: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

export default function ContributorsView(): JSX.Element {
  const styles = useStyles();
  const [contributors, setContributors] = useState<null | IUserData[]>(null);
  const [loading, setLoading] = useState(false);
  const [categoryFilters, setCategoryFilters] = useState<string[]>([]);
  const [availabilityFilters, setAvailabilityFilters] = useState<string[]>([]);
  const [experienceFilters, setExperienceFilters] = useState<string[]>([]);
  const [search, setSearch] = useState('');
  const [filterSelect, setFilterSelect] = useState<{
    value: string;
    label: string;
  }>(filterSelectOptions[0]);
  const { loggedInUser } = useGlobalState();

  const onFilter = (val: string, type: string) => {
    const originalList =
      type === 'category'
        ? categoryFilters
        : type === 'availability'
        ? availabilityFilters
        : experienceFilters;
    const setList =
      type === 'category'
        ? setCategoryFilters
        : type === 'availability'
        ? setAvailabilityFilters
        : setExperienceFilters;
    const newFilters = originalList.slice();
    const existingFilterIndex = newFilters.indexOf(val);
    if (existingFilterIndex !== -1) {
      newFilters.splice(existingFilterIndex, 1);
      setList(newFilters);
    } else {
      setList([...newFilters, val]);
    }
  };

  useEffect(() => {
    (async () => {
      if (loggedInUser) {
        setLoading(true);
        const contributorsResponse = await getContributors(loggedInUser.daoId);
        const contributorsData = await contributorsResponse.json();
        setContributors(contributorsData);
        setLoading(false);
      }
    })();
  }, [loggedInUser]);

  const filteredContributors =
    contributors && search
      ? contributors.filter(
          contributor =>
            contributor.username &&
            contributor.username?.toLowerCase().search(search.toLowerCase()) !==
              -1,
        )
      : contributors;

  const filteredCategoryContributors =
    categoryFilters?.length > 0
      ? filteredContributors?.filter(user =>
          categoryFilters.find(category =>
            user.skills?.find(skill => skill.kind === category),
          ),
        )
      : filteredContributors;

  const filteredAvailabilityContributors =
    availabilityFilters?.length > 0
      ? filteredCategoryContributors?.filter(user =>
          availabilityFilters.find(
            availability =>
              availabilityOptions.find(option => option.label === availability)
                ?.value === user.availability,
          ),
        )
      : filteredCategoryContributors;

  const filteredExperienceContributors =
    experienceFilters?.length > 0
      ? filteredAvailabilityContributors?.filter(user =>
          experienceFilters.find(
            //@ts-ignore
            experience => user.skills[0]?.experience === experience,
          ),
        )
      : filteredAvailabilityContributors;

  const finalContributors = filteredExperienceContributors?.sort((a, b) =>
    filterSelect.value === 'newest'
      ? //@ts-ignore
        new Date(a.createdAt) - new Date(b.createdAt)
      : //@ts-ignore
        new Date(b.createdAt) - new Date(a.createdAt),
  );

  return (
    <MainLayout title="Contributors" loading={loading}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.searchInputContainer}>
            <div className={styles.searchInput}>
              <div className={styles.searchInputIcon}>
                <img src={searchIcon} alt="search" />
              </div>
              <input
                value={search}
                onChange={e => setSearch(e.target.value)}
                className={styles.searchInputText}
                placeholder={'Search'}
              />
            </div>
            <div className={styles.filterSelect}>
              <FilterSelect
                options={filterSelectOptions}
                value={filterSelect}
                onChange={val => setFilterSelect(val)}
              />
            </div>
          </div>
          <div className={styles.amount}>
            {finalContributors?.length || 0} contributor
            {finalContributors?.length !== 1 ? 's' : ''} found
          </div>
          {finalContributors?.map((contributor: IUserData, i: number) => (
            <Contributor
              contributor={contributor}
              key={`${contributor.id}-${i}`}
              style={{ marginTop: i === 0 ? '40px' : '24px' }}
            />
          ))}
        </div>
        <div className={styles.filterSection}>
          <div>
            <div>
              <div
                className={styles.filterCategoryContainer}
                style={{ marginTop: 0 }}
              >
                <div className={styles.filterCategoryTitle}>Category</div>
                <div
                  className={styles.clear}
                  onClick={() => setCategoryFilters([])}
                >
                  Clear
                </div>
              </div>
              <div className={styles.filterPillsContainer}>
                {taskFilters.map((filter, i) => (
                  <FilterPill
                    style={{ marginRight: '8px', marginBottom: '16px' }}
                    key={`${filter.name}-${i}`}
                    text={filter.name}
                    onChange={val => onFilter(val, 'category')}
                    active={
                      categoryFilters.find(
                        aFilter => aFilter === filter.name,
                      ) !== undefined
                    }
                    activeColor={filter.color}
                  />
                ))}
              </div>
            </div>
            <div>
              <div className={styles.filterCategoryContainer}>
                <div className={styles.filterCategoryTitle}>Availability</div>
                <div
                  className={styles.clear}
                  onClick={() => setAvailabilityFilters([])}
                >
                  Clear
                </div>
              </div>
              <div className={styles.filterPillsContainer}>
                {availabilityOptions.map((filter, i) => (
                  <FilterPill
                    style={{ marginRight: '8px', marginBottom: '16px' }}
                    key={`${filter}-${i}`}
                    text={filter.label}
                    onChange={val => onFilter(val, 'availability')}
                    active={
                      availabilityFilters.find(
                        aFilter => aFilter === filter.label,
                      ) !== undefined
                    }
                  />
                ))}
              </div>
            </div>
            <div>
              <div className={styles.filterCategoryContainer}>
                <div className={styles.filterCategoryTitle}>Experience</div>
                <div
                  className={styles.clear}
                  onClick={() => setExperienceFilters([])}
                >
                  Clear
                </div>
              </div>
              <div className={styles.filterPillsContainer}>
                {expertiseOptions.map((filter, i) => (
                  <FilterPill
                    style={{ marginRight: '8px', marginBottom: '16px' }}
                    key={`${filter.value}-${i}`}
                    text={filter.value}
                    onChange={val => onFilter(val, 'experience')}
                    active={
                      experienceFilters.find(
                        aFilter => aFilter === filter.value,
                      ) !== undefined
                    }
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
