import { applyOpportunity } from 'api/opportunitiesApi';
import { uploadJson } from 'api/uploadApi';
import closeIcon from 'assets/close.svg';
import communityIllustration from 'assets/communityIllustration.png';
import { useWallet } from 'contexts/WalletContext';
import { Button } from 'design-system';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { createUseStyles } from 'react-jss';
import useGlobalState from 'state';
import { sleep } from 'utils/utils';
import { encodeData, getUserSignature, stringToBytes } from 'web3/helpers';
import { SignatureFunction } from 'web3/metadata';

import Modal from './Modal';

const useStyles = createUseStyles({
  container: { maxWidth: '1034px', display: 'flex' },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '24px 40px',
  },
  close: { width: '24px', cursor: 'pointer' },
  title: {
    fontSize: '24px',
    lineHeight: '24px',
  },
  divider: { width: '100%', height: '1px', backgroundColor: '#303B5B' },
  description: {
    padding: '40px',
    fontSize: '16px',
    lineHeight: '21px',
    color: '#D1D3D5',
  },
  submitContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '24px 40px',
  },
  submit: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F9F9F9',
    color: '#000',
    fontSize: '16px',
    lineHeight: '16px',
    borderRadius: '100px',
    padding: '16px 24px',
  },
  inputTitle: {
    fontSize: '14px',
    color: '#F9F9F9',
    marginBottom: '8px',
    marginTop: '32px',
  },
  input: {
    border: '1px solid #303B5B',
    padding: '16px',
    borderRadius: '8px',
    fontSize: '16px',
    lineHeight: '21.6px',
    backgroundColor: 'transparent',
    color: '#f9f9f9',
    width: '100%',
  },
  illustration: {
    width: '517px',
    minHeight: '511px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    flexShrink: 0,
  },
});

export default function ApplyModal({
  open,
  onClose,
  opportunityId,
}: {
  open: boolean;
  onClose(reload?: boolean): void;
  opportunityId: number | undefined;
}): JSX.Element {
  const styles = useStyles();
  const [loading, setLoading] = useState(false);
  const { loggedInUser } = useGlobalState();
  const [oppId, setOppId] = useState(opportunityId);
  const [note, setNote] = useState('');
  const { chainId, address, shareConfig, provider } = useWallet();

  useEffect(() => setOppId(opportunityId), [opportunityId]);

  const onApply = async () => {
    setLoading(true);
    if (oppId && loggedInUser && note && chainId && shareConfig) {
      try {
        const ipfsData = {
          message: note,
          address,
          timestamp: new Date().getTime() / 1000,
        };
        //@ts-ignore
        const { ipfsHash } = await uploadJson(ipfsData);
        if (ipfsHash && provider) {
          const offChainBytes = stringToBytes(ipfsHash);
          const data = {
            types: [
              'uint8',
              'address',
              'uint256',
              'uint256',
              'address',
              'bytes',
            ],
            values: [
              SignatureFunction.AssignTask,
              shareConfig?.escrowAddress,
              chainId,
              oppId,
              address,
              offChainBytes,
            ],
          };
          const encodedData = encodeData(data);
          try {
            const contributorSignature = await getUserSignature(
              encodedData,
              provider,
            );
            const [result] = await Promise.all([
              applyOpportunity({
                taskSubgraphId: `${shareConfig?.escrowAddress.toLowerCase()}-0x${oppId.toString(
                  16,
                )}`,
                message: note,
                chainId,
                contractAddress: shareConfig?.escrowAddress,
                signature: contributorSignature,
                encodedData,
              }),
              sleep(300),
            ]);
            if (result.status === 400) {
              setLoading(false);
              toast.error('Error');
            } else {
              setLoading(false);
              toast.success('Applied!');
              onClose(true);
            }
          } catch (e) {
            setLoading(false);
            toast.error('Signature rejected');
          }
        } else {
          setLoading(false);
          toast.error('Error uploading metadata');
        }
      } catch (e) {
        setLoading(false);
        toast.error('Error uploading metadata');
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      style={{ borderRadius: '8px' }}
    >
      <div className={styles.container}>
        <div
          style={{ backgroundImage: `url(${communityIllustration})` }}
          className={styles.illustration}
        />
        <div style={{ width: '100%' }}>
          <div className={styles.header}>
            <div className={styles.title}>Confirmation</div>
            <img
              src={closeIcon}
              alt="close"
              className={styles.close}
              onClick={() => onClose()}
            />
          </div>
          <div className={styles.divider} />
          <div className={styles.description}>
            <div>This looks like a great opportunity for you!</div>
            <div className={styles.inputTitle}>Add a message</div>
            <textarea
              className={styles.input}
              value={note}
              onChange={e => setNote(e.target.value)}
              rows={4}
            />
            <div style={{ color: '#D1D3D5', marginTop: '32px' }}>
              Your application will be reviewed soon. Join the Discord channel
              for updates.
            </div>
          </div>

          <div className={styles.divider} />
          <div className={styles.submitContainer}>
            <Button
              disabled={loading || !note}
              label={loading ? 'Applying...' : 'Apply'}
              size={'large'}
              className={styles.submit}
              onClick={onApply}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
