import {
  bookmarkOpportunity,
  IOpportunity,
  unbookmarkOpportunity,
} from 'api/opportunitiesApi';
import brainGrayIcon from 'assets/brainGray.svg';
import calendarGrayIcon from 'assets/calendarGray.svg';
import checkIcon from 'assets/check.svg';
import clockGrayIcon from 'assets/clockGray.svg';
import { useWallet } from 'contexts/WalletContext';
import { Bookmark, Tooltip } from 'design-system';
import makeBlockie from 'ethereum-blockies-base64';
import { isDAO } from 'layouts/MainLayout';
import { OpportunityLocation } from 'Locations';
import moment from 'moment';
import { CSSProperties, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import useGlobalState from 'state';
import { formatToTenths, truncate, ZERO_ADDRESS } from 'utils/utils';
import { taskFilters } from 'views/Opportunities';

import Pill from './Pill';

const useStyles = createUseStyles({
  card: {
    borderRadius: '8px',
    backgroundColor: '#202837',
    padding: '28px 26px',
    display: 'block',
    color: '#fff',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleContainer: { display: 'flex', alignItems: 'center' },
  title: { fontSize: '20px', color: '#fff' },
  detailsContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '16px',
    '& > *': {
      display: 'inline-flex',
      alignItems: 'center',
    },
  },
  detail: {
    marginLeft: '8px',
    fontSize: '14px',
    color: '#A4A6AB',
    whiteSpace: 'nowrap',
  },
  detailDivider: {
    width: '3px',
    height: '3px',
    borderRadius: '3px',
    backgroundColor: '#767A81',
    margin: '0px 12px',
  },
  description: {
    maxWidth: '546px',
    marginTop: '16px',
    fontSize: '14px',
    color: '#D1D3D5',
  },
  tagsContainer: { display: 'flex', alignItems: 'center', marginTop: '16px' },
  tag: {
    border: '0.5px solid #A4A6AB',
    padding: '6px 8px',
    borderRadius: '100px',
    fontSize: '12px',
    marginRight: '6px',
  },
  footerContainer: {
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  applicantsContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  moreApplicants: {
    marginLeft: '8px',
    fontSize: '14px',
    color: '#F9F9F9',
    display: 'flex',
    alignItems: 'center',
    width: '120px',
  },
  avatarContainer: { position: 'relative' },
  avatar: {
    width: '24px',
    height: '24px',
    borderRadius: '24px',
    border: '0.5px solid #697487',
    position: 'absolute',
    top: '-12px',
  },
  currencyContainer: { display: 'flex', alignItems: 'center' },
  daoLogo: {
    width: '32px',
    height: '32px',
    borderRadius: '32px',
    marginLeft: '16px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  primaryCurrency: { fontSize: '16px' },
  secondaryCurrency: { marginTop: '8px', color: '#A4A6AB', fontSize: '12px' },
  assigned: { fontSize: '14px', color: '#A4A6AB' },
  zeroApplicants: { color: '#767A81', fontSize: '12px' },
  applied: {
    borderRadius: '100px',
    border: '1px solid #303B5B',
    padding: '8px 12px 8px 10px',
    fontSize: '14px',
    color: '#f9f9f9',
    display: 'flex',
    alignItems: 'center',
    marginRight: '12px',
  },
  completed: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#2B8C34',
    borderRadius: '4px',
    padding: '6px 10px',
    fontSize: '14px',
    marginRight: '8px',
  },
});

export const OpportunityCard = ({
  opportunity,
  style,
  task,
}: {
  opportunity: IOpportunity;
  style?: CSSProperties;
  task?: boolean;
}): JSX.Element => {
  const styles = useStyles();
  const [isBookmarked, setIsBookmarked] = useState(opportunity?.bookmarked);
  const { loggedInUser } = useGlobalState();
  const DAOuser = isDAO(loggedInUser);
  const { chainId, shareConfig } = useWallet();

  const handleBookmarkChange = async (newIsBookmarked: boolean) => {
    if (chainId && shareConfig)
      try {
        setIsBookmarked(newIsBookmarked);
        const response = newIsBookmarked
          ? await bookmarkOpportunity(
              `${shareConfig.escrowAddress.toLowerCase()}-0x${opportunity.id}`,
              {
                chainId,
                contractAddress: shareConfig.escrowAddress,
              },
            )
          : await unbookmarkOpportunity(
              `${shareConfig.escrowAddress.toLowerCase()}-0x${opportunity.id}`,
              {
                chainId,
                contractAddress: shareConfig.escrowAddress,
              },
            );
        if (response.status !== 200 && response.status !== 201) {
          setIsBookmarked(!newIsBookmarked);
        }
      } catch (error) {
        setIsBookmarked(!newIsBookmarked);
      }
  };

  useEffect(() => {
    setIsBookmarked(opportunity?.bookmarked);
  }, [opportunity?.bookmarked]);

  const isCompleted = opportunity?.status === 'Completed';

  return (
    <Link
      to={OpportunityLocation(opportunity.id)}
      className={styles.card}
      style={style}
    >
      <Tooltip />
      <div className={styles.headerContainer}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>{opportunity.title}</div>
          <Pill
            style={{
              backgroundColor:
                taskFilters.find(filter => filter.name === opportunity.team)
                  ?.color || '#673969',
            }}
            text={opportunity.team}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {isCompleted && (
            <div className={styles.completed}>
              <img
                src={checkIcon}
                alt="check"
                style={{
                  marginRight: '8px',
                  filter: 'brightness(0) invert(1)',
                  width: '16px',
                }}
              />
              Completed
            </div>
          )}
          {!task && !DAOuser && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {opportunity.applied && (
                <div className={styles.applied}>
                  <img
                    src={checkIcon}
                    alt="check"
                    style={{
                      marginRight: '4px',
                      filter: 'brightness(0) invert(1)',
                    }}
                  />
                  Applied
                </div>
              )}
              <Bookmark
                isBookmarked={isBookmarked}
                onChange={handleBookmarkChange}
              />
            </div>
          )}
        </div>
      </div>
      {isCompleted && DAOuser ? (
        <div style={{ marginTop: '16px' }}>
          <span style={{ color: '#A4A6AB', fontSize: '12px' }}>
            Completed on:
          </span>
          <span
            style={{ color: '#f9f9f9', marginLeft: '6px', fontSize: '14px' }}
          >
            {moment(opportunity.updatedAt).format('MMM DD, YYYY')}
          </span>
        </div>
      ) : (
        <div className={styles.detailsContainer}>
          <div data-tip="Experience level">
            <img src={brainGrayIcon} alt="skill" />
            <div className={styles.detail}>
              {opportunity.expectedExperience}
            </div>
          </div>
          <div className={styles.detailDivider} />
          <div data-tip="Deadline">
            <img src={calendarGrayIcon} alt="created" />
            <div className={styles.detail}>
              {moment(opportunity.deadline).format('MMM Do, YYYY')}
            </div>
          </div>
          <div className={styles.detailDivider} />
          <div data-tip="Task Estimation">
            <img src={clockGrayIcon} alt="time" />
            <div className={styles.detail}>
              {opportunity.timeEstimateValue} {opportunity.timeEstimateUnit}
            </div>
          </div>
        </div>
      )}
      <div className={styles.description}>
        {truncate(opportunity.description, 172)}
      </div>
      {!task && opportunity.tags.length > 0 && (
        <div className={styles.tagsContainer}>
          {opportunity.tags.map((tag, i) => (
            <div key={`${tag}-${i}`} className={styles.tag}>
              {tag}
            </div>
          ))}
        </div>
      )}
      <div className={styles.footerContainer}>
        {isCompleted && DAOuser ? (
          <div>
            <div
              style={{
                color: '#A4A6AB',
                fontSize: '12px',
                marginBottom: '8px',
              }}
            >
              Contributor
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                alt="blockie"
                style={{
                  width: '24px',
                  height: '24px',
                  borderRadius: '24px',
                  marginRight: '8px',
                }}
                src={makeBlockie(ZERO_ADDRESS)}
              />
              <div style={{ fontSize: '14px', color: '#fff' }}>{'1'}</div>
            </div>
          </div>
        ) : !task ? (
          <div className={styles.applicantsContainer}>
            <div className={styles.avatarContainer}>
              {opportunity.opportunityApplicants
                ?.slice(0, 3)
                .map((applicant, i) => (
                  <img
                    key={`${applicant.userId}-${i}`}
                    src={makeBlockie(applicant.user.wallet || ZERO_ADDRESS)}
                    alt="blockie"
                    className={styles.avatar}
                    style={{ left: `${i * 16}px` }}
                  />
                ))}
            </div>
            {opportunity.opportunityApplicants?.length === 0 ? (
              <div className={styles.zeroApplicants}>Zero applicants</div>
            ) : (
              opportunity.opportunityApplicants?.length > 3 && (
                <div
                  className={styles.moreApplicants}
                  style={{
                    position: 'absolute',
                    top: '-7px',
                    left: opportunity.opportunityApplicants
                      ? `${
                          opportunity?.opportunityApplicants?.length * 16 - 8
                        }px`
                      : 0,
                  }}
                >
                  + {opportunity.opportunityApplicants?.length - 3} applicants
                </div>
              )
            )}
          </div>
        ) : (
          <div>
            <span className={styles.assigned}>Assigned on: </span>
            <span>{moment(opportunity.createdAt).format('MMM DD, YYYY')}</span>
          </div>
        )}
        <div className={styles.currencyContainer}>
          <div style={{ textAlign: 'right' }}>
            <div className={styles.primaryCurrency}>
              {formatToTenths(opportunity.fee)} {opportunity.coin}
            </div>
            <div className={styles.secondaryCurrency}>
              ${formatToTenths(opportunity.feeInUsd)}
            </div>
          </div>
          <div
            style={{ backgroundImage: `url(${opportunity.dao?.logo})` }}
            className={styles.daoLogo}
          />
        </div>
      </div>
    </Link>
  );
};
