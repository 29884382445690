import { IOnboardingMember } from './onboardingApi';
import { IOpportunity } from './opportunitiesApi';
import { serverRequest } from './serverRequest';
import { IUserData } from './usersApi';

export type IDAO = {
  id: number;
  name: string;
  joined: boolean;
  website: string;
  slug: string;
  logo: string;
  description: string;
  token: string;
  availableOpportunitiesCount: number;
  createdAt: string;
  updatedAt: string;
  discord: string;
  twitter: string;
  github: string;
  snapshot: string;
  brandColor: string;
  video: string;
  DaoOpportunitySource: {
    id: number;
    daoId: number;
    source: string;
    sourceId: string;
  };
  keyPeople: IOnboardingMember[];
  contributors: IUserData[];
  opportunities: IOpportunity[];
};

export const getDAO = async (id: string): Promise<Response> => {
  return serverRequest(`daos/${id}`);
};

export const getDAOs = async (): Promise<Response> => {
  return serverRequest('daos');
};
