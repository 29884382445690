import { useWallet } from 'contexts/WalletContext';
import { constants, utils } from 'ethers';
import { useMemo } from 'react';
import { IERC20, IERC20__factory } from 'web3/types';

export const useERC20Contract = (erc20Address: string): IERC20 | null => {
  const { provider } = useWallet();

  return useMemo(
    () =>
      provider &&
      utils.isAddress(erc20Address) &&
      erc20Address !== constants.AddressZero
        ? IERC20__factory.connect(erc20Address, provider.getSigner())
        : null,
    [provider, erc20Address],
  );
};
