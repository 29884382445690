import { IOpportunity } from 'api/opportunitiesApi';
import { TaskInfoFragment } from 'graphql/types';

/*

contributor: wallet
reviewer: wallet
dao: reviewer wallet -> dao.id
applicants: taskSubgraphId

*/

export function buildTask(subgraphTask: TaskInfoFragment): IOpportunity {
  return {
    activities: [],
    daoId: 0,
    //@ts-ignore
    user: subgraphTask.contributor,
    //@ts-ignore
    reviewer: subgraphTask.reviewer,
    //@ts-ignore
    dao: subgraphTask.dao,
    //@ts-ignore
    submissions: subgraphTask.submissions,
    //@ts-ignore
    cancellation: subgraphTask.cancellation,
    //@ts-ignore
    status: calculateStatus(subgraphTask.status, subgraphTask.submissions),
    id: subgraphTask.taskId,
    title: subgraphTask.creationDetail.title || '',
    description: subgraphTask.creationDetail.description || '',
    content: subgraphTask.creationDetail.content || '',
    team: subgraphTask.creationDetail.category || '',
    expectedExperience: subgraphTask.creationDetail.experience || '',
    timeEstimateUnit: subgraphTask.creationDetail.timeEstimateUnit || '',
    timeEstimateValue: subgraphTask.creationDetail.timeEstimateValue || '',
    deadline: new Date(
      subgraphTask.creationDetail.deadline * 1000,
    ).toISOString(),
    tags: subgraphTask.creationDetail.tags || [],
    createdAt: new Date(subgraphTask.createdAt * 1000).toISOString(),
    updatedAt: new Date(subgraphTask.createdAt * 1000).toISOString(),
    fee: subgraphTask.fee / 100000000000000000,
    feeInUsd: 10,
    coin: subgraphTask.token.symbol,
    discord: '',
    source: '',
    sourceUrl: '',
    uploadedFiles: [],
    submitMessage: '',
    //@ts-ignore
    opportunityApplicants: subgraphTask.applicants || [],
    applied: false,
    //@ts-ignore
    bookmarked: subgraphTask.bookmarked,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function calculateStatus(rawStatus: string, submissions: any): string {
  if (rawStatus === 'inactive') {
    return 'Available';
  } else if (rawStatus === 'active') {
    if (
      !submissions ||
      submissions.length === 0 ||
      (submissions && submissions.length > 0 && submissions[0].changesRequested)
    ) {
      return 'InProgress';
    } else {
      return 'InReview';
    }
  } else if (rawStatus === 'complete') {
    return 'Completed';
  } else if (rawStatus === 'cancel') {
    return 'Cancelled';
  } else {
    return '';
  }
}
